<div id="expired" [ngClass]="isLgScreen? 'flex-display':''">
    <div [ngClass]="isLgScreen? 'lgImg':''">
        <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
            x="0px" y="0px" viewBox="0 0 464 390.4" style="enable-background:new 0 0 464 390.4;" xml:space="preserve">

            <circle class="st0" cx="126" cy="175.4" r="12" />
            <circle class="st0" cx="339" cy="175.4" r="12" />
            <circle class="st1" cx="232.5" cy="170.9" r="106.5" />
            <path class="st2" d="M126,164.4c0,0,4.5-15.4,10.5-19.6c0,0,31,0,65-26.5c0,0,110,85.9,176-30.8c0,0-33,28.6-116-41.4
        c0,0-131-16.2-135.5,106V164.4z" />
            <path class="st2" d="M339,164.4c0,0,6.2-13.3-8.2-32.4l-6.3,3.9C324.5,135.9,333.5,142.9,339,164.4z" />
            <path class="st2" d="M247.8,45.3c0,0,47.7-5.3,76.7,53.7L247.8,45.3z" />
            <circle class="st2" cx="192" cy="175.4" r="9" />
            <circle class="st2" cx="271" cy="175.4" r="9" />
            <path class="st4" d="M101.4,390.1c22.1-106.8,75.7-114.1,137.1-114.1c61.4,0,104,18.8,130.1,114.1
        C368.7,390.6,101.3,390.6,101.4,390.1z" />

            <!-- <circle id="path" class="st3 uhoh" cx="234.5" cy="230.5" r="20" /> -->
            <path class="st3 smile" d="M191,214.4c-1.1-1.5,38.6,49.3,83,0" />
        </svg>
    </div>

    <div id="messageSection" [ngClass]="isLgScreen? 'message':'messageSM'">
        <h1>Thank you</h1>
        <p>Form has been submitted successfully.</p>
        <p>Please contact your counsellor for further process.</p>
    </div>
</div>