import { Component, OnDestroy, OnInit } from '@angular/core';
import { BreakpointObserver } from '@angular/cdk/layout';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { SharedService } from '../../shared/shared.service';
import { SisService } from '../sis.service';

@Component({
  selector: 'app-sis-form',
  templateUrl: './sis-form.component.html',
  styleUrls: ['./sis-form.component.scss']
})
export class SisFormComponent implements OnInit, OnDestroy {
  private bpoSubscription: any = {};
  isCA: boolean;
  constructor(private bpo: BreakpointObserver, private router: Router, private fb: FormBuilder, private sharedService: SharedService, private sisService: SisService) { }

  ngOnInit() {
    if (!this.sharedService.getMasterKey()) {
      this.sharedService.redirectToMainWebsite();
    }
    this.sisService.setDefaultValuesPersonalDetails();
    const styleToCheck = '(min-width: 720px)';

    //Listen to changes in screen width
    this.bpoSubscription = this.bpo.observe([styleToCheck])
      .subscribe(result => {
        if (result.matches) {
          // Redirect to larger view
          this.router.navigate(['/sis-form']);
        }
        else {
          // Redirect to smaller view
          this.router.navigate(['/sis-personal-details-one']);
        }
      });

      this.isCA = this.sharedService.getStudentCountryCheck();
  }

  ngOnDestroy(): void {
    this.bpoSubscription.unsubscribe();
  }

  logout() {
    this.sharedService.showLogoutDialog();
  }
}
