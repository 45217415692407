<div id="sis-form-container" class="container">

    <header class="mb-2">
        <img *ngIf="!isCA" src="https://siuk-india.s3.ap-south-1.amazonaws.com/assets/images/email/mailchimp/SI-Global_logo.svg" class="logo-as-AP-SI">
        <img *ngIf="isCA" src="https://siuk-india.s3.ap-south-1.amazonaws.com/assets/images/email/mailchimp/SI-Canada_logo.svg" class="logo-as-AP">
        <img (click)="logout()" src="../../../../assets/images/logout-icon.png" class="logout-icon">
    </header>

    <section id="sub-header" class="mb-1">
        <h2>SI-UK STUDENT INFORMATION SHEET</h2>
    </section>

    <section id="consent" class="mb-1">
        <p>By completing this form, I permit SI-UK to enter my information into application forms for the courses and
            schools listed under University choice.</p>
    </section>

    <fieldset id="personal-details-common-fieldset" class="text-left">
        <legend>Personal Details</legend>

        <app-sis-personal-details-one></app-sis-personal-details-one>

        <app-sis-personal-details-two></app-sis-personal-details-two>

        <app-sis-personal-details-three></app-sis-personal-details-three>
    </fieldset>

    <app-sis-travel-history></app-sis-travel-history>

    <app-sis-immigration-history></app-sis-immigration-history>

    <app-sis-visa-rejection></app-sis-visa-rejection>

    <app-sis-education-details></app-sis-education-details>

    <app-sis-english-language-exam></app-sis-english-language-exam>

    <app-sis-other-exams></app-sis-other-exams>

    <app-sis-work-experience></app-sis-work-experience>

    <app-sis-reference></app-sis-reference>

    <app-sis-documents></app-sis-documents>

    <app-sis-declaration></app-sis-declaration>

</div>