import { Component } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, FormGroupDirective, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { SharedService } from '../../shared/shared.service';
import { SisService } from '../sis.service';
import { Observable, debounceTime, Subject } from 'rxjs';
import { MatTableDataSource } from '@angular/material/table';
import { ErrorComponent } from '../../shared/error/error.component';
import { MatDialog } from '@angular/material/dialog';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-sis-travel-history',
  templateUrl: './sis-travel-history.component.html',
  styleUrls: ['./sis-travel-history.component.scss']
})
export class SisTravelHistoryComponent {
  travelHistoryData: any = {};
  sisFormTravelHistory: FormGroup;
  countriesList: any = [];
  visaTypesList: any = [];
  isLgScreen: boolean = false;
  dataSource = new MatTableDataSource<any>();
  formsList: Observable<any>;
  travelTblForm: any;
  addTravelForm: FormGroup;
  travelHistoryList: Array<any> = [];
  travelHistoryExist: any = null;
  addMoreTH: boolean = false;
  studentId: number;
  today: Date = new Date();
  userId: number;
  disableSaveNextBtn: boolean = false;
  sectionIndex: number = 4;

  protected _onDestroy = new Subject<void>();

  public countryFilterCtrl: FormControl = new FormControl();
  countryFilteredList: Array<any> = [];

  public editCountryFilterCtrl: FormControl = new FormControl();
  editCountryFilteredList: Array<any> = [];
  
  manualSave: boolean;
  constructor(private router: Router, private fb: FormBuilder, private toastr: ToastrService,
    private sharedService: SharedService, private sisService: SisService,
    private dialog: MatDialog) { }

  ngOnInit(): void {
    this.studentId = this.sharedService.getStudentId();
    this.userId = this.sharedService.getUserId();
    this.isLgScreen = this.sharedService.isLgScreen();
    this.manualSave = false;
    this.getCountriesList();
    this.getVisaTypeList();
    this.getTravelHistory();
    this.initFormArray();
    this.initAddTravelForm();
    this.sharedService.scrollToTop();

    /////////// To search Country /////////
    this.countryFilterCtrl.valueChanges
      .pipe(takeUntil(this._onDestroy))
      .subscribe(() => {
        this.filterCountry();
      });
    /////////// To search Country //////////

    /////////// To search Edit Country /////////
    this.editCountryFilterCtrl.valueChanges
      .pipe(takeUntil(this._onDestroy))
      .subscribe(() => {
        this.filterEditCountry();
      });
    /////////// To search Edit Country //////////
  }

  ngOnDestroy() {
    this._onDestroy.next();
    this._onDestroy.complete();
  }

  preventSpace(event: KeyboardEvent) {
    if (event.code === "Space") {
      event.preventDefault();
    }
  }

  saveData() {
    this.sisService.setSISTravelHistoryAtLocal({ travelHistoryExist: this.travelHistoryData.travelHistoryExist.toString(), List: this.travelHistoryList });
    this.setPermission();
    if (this.router.url === '/sis-travel-history' || this.manualSave) {
      this.sisService.saveSISData(this.sectionIndex).subscribe(() => {
        console.log('data saved travel');
      })
    }
  }

  saveAndNext() {
    this.sisService.submitSIS(this.studentId, this.sectionIndex).subscribe((res: any) => {
      if (res) {
        this.router.navigate(['/sis-immigration-history'])
      }
    }, error => {
      this.toastr.error("Something went wrong", error);
    });
  }

  getCountriesList() {
    if (this.sisService.getCountriesListFromLocalStorage()) {
      this.countriesList = this.sisService.getCountriesListFromLocalStorage();
      this.filterCountry();
      this.filterEditCountry();
    } else {
      this.sisService.getCountriesList().subscribe((res) => {
        let response: any = res;
        if (response && response.data) {
          this.countriesList = response.data.items;
          this.sisService.setCountriesListInLocalStorage(this.countriesList);
          this.filterCountry();
          this.filterEditCountry();
        }
      })
    }
  }

  getVisaTypeList() {
    if (this.sisService.getVisaTypesListFromLocalStorage()) {
      this.visaTypesList = this.sisService.getVisaTypesListFromLocalStorage();
    } else {
      this.sisService.getVisaTypesList().subscribe(res => {
        let response: any = res;
        if (response && response.data) {
          this.visaTypesList = response.data.items;
          this.sisService.setVisaTypesListInLocalStorage(this.visaTypesList);
        }
      })
    }
  }

  getTravelHistory() {
    this.sisService.getSISTravelHistory(this.studentId)
      .subscribe((res: any) => {
        let response = JSON.parse(res.data);
        this.travelHistoryData = response;
        this.travelHistoryData.travelHistoryExist = parseInt(this.travelHistoryData.travelHistoryExist);
        if (response.List && response.List.length > 0) {
          response.List.forEach((res: any) => (res.isEditable = false));
          this.travelHistoryList = response.List;
        } else {
          this.travelHistoryList = [];
        }
        this.dataSource.data = this.travelHistoryList;
        this.saveData();
        this.createTravelFormArray();
        this.initAddTravelForm();
        this.createTravelFormArray();
        if (response.List && response.List.length > 0) {
          this.addMoreTH = true;
        }
      });
  }

  initFormArray() {
    this.travelTblForm = this.fb.group({
      travelHistoryFormArray: this.fb.array([]),
    });
  }

  initAddTravelForm() {
    this.addTravelForm = new FormGroup({
      countryId: new FormControl('', [
        Validators.required,
        Validators.pattern(/(\s*[^\s]+\s*)+/),
      ]),
      arrivalDate: new FormControl('', [
        Validators.required,
        Validators.pattern(/(\s*[^\s]+\s*)+/),
      ]),
      departureDate: new FormControl(''),
      visaStartDate: new FormControl('', [
        Validators.required,
        Validators.pattern(/(\s*[^\s]+\s*)+/),
      ]),
      visaEndDate: new FormControl('', [
        Validators.required,
        Validators.pattern(/(\s*[^\s]+\s*)+/),
      ]),
      purposeOfVisit: new FormControl('', [
        Validators.pattern(/(\s*[^\s]+\s*)+/),
      ]),
      visaTypeId: new FormControl('', [
        Validators.required,
        Validators.pattern(/(\s*[^\s]+\s*)+/),
      ]),
      isCurrentInUk: new FormControl(false),
      id: new FormControl(0),
    });
  }
  resetForm() {
    this.addTravelForm.reset();
  }
  createTravelFormArray() {
    this.travelTblForm = this.fb.group({
      travelHistoryFormArray: this.fb.array(
        this.travelHistoryList.map((data) =>
          this.fb.group({
            arrivalDate: [
              data.arrivalDate,
              [Validators.required, Validators.pattern(/(\s*[^\s]+\s*)+/)],
            ],
            departureDate: [data.departureDate],
            purposeOfVisit: [
              data.purposeOfVisit,
              [Validators.pattern(/(\s*[^\s]+\s*)+/)],
            ],
            countryId: [
              data.countryId,
              [Validators.required, Validators.pattern(/(\s*[^\s]+\s*)+/)],
            ],
            visaTypeId: [
              data.visaTypeId,
              [Validators.required, Validators.pattern(/(\s*[^\s]+\s*)+/)],
            ],
            visaStartDate: [
              data.visaStartDate,
              [Validators.required, Validators.pattern(/(\s*[^\s]+\s*)+/)],
            ],
            visaEndDate: [
              data.visaEndDate,
              [Validators.required, Validators.pattern(/(\s*[^\s]+\s*)+/)],
            ],
            isCurrentInUk: [data.isCurrentInUk ? data.isCurrentInUk : false],
            id: [data.id],
          })
        )
      ),
    });
    this.travelTblForm.controls.travelHistoryFormArray.disable();
    this.dataSource = new MatTableDataSource<any>(
      this.travelTblForm.controls.travelHistoryFormArray.controls
    );
    this.formsList = this.dataSource.connect();

    this.addMoreTH = true;
  }

  addNewTravelRecord(formDirective: FormGroupDirective) {
    const getVisName = this.visaTypesList.find(
      (res: any) => res.id === this.addTravelForm.get('visaTypeId').value
    );
    const travelObj: any = {
      arrivalDate: this.sharedService.formatDate(this.addTravelForm.value.arrivalDate),
      countryId: this.addTravelForm.value.countryId,
      departureDate: this.sharedService.formatDate(this.addTravelForm.value.departureDate),
      isCurrentInUk: this.addTravelForm.value.isCurrentInUk ? this.addTravelForm.value.isCurrentInUk : false,
      purposeOfVisit: this.addTravelForm.value.purposeOfVisit,
      visaEndDate: this.sharedService.formatDate(this.addTravelForm.value.visaEndDate),
      visaStartDate: this.sharedService.formatDate(this.addTravelForm.value.visaStartDate),
      visaTypeId: this.addTravelForm.value.visaTypeId,
      id: 0,
      visaTypeName: getVisName.name,
      studentId: this.studentId
    }
    this.travelHistoryList.push(travelObj);
    this.addTravelForm.reset();
    formDirective.resetForm('');
    this.createTravelFormArray();
    setTimeout(() => {
      this.manualSave = true;
      this.saveData();
    }, 500);
  }

  cancelAddNewTravelRecord() {
    this.addMoreTH = true;
    this.addTravelForm.reset();
  }

  checkDisableClass(cardFormGroup: any) {
    return this.sharedService.setDisableClass(cardFormGroup);
  }

  editTravelHistory(cardFormGroup: any, $index: number) {
    const getVisName = this.visaTypesList.find(
      (res: any) => res.id === cardFormGroup.get('visaTypeId').value
    );
    const params = {
      id: this.travelHistoryList[$index].id,
      arrivalDate: this.sharedService.formatDate(cardFormGroup.get('arrivalDate').value),
      departureDate: this.sharedService.formatDate(cardFormGroup.get('departureDate').value),
      purposeOfVisit: cardFormGroup.get('purposeOfVisit').value,
      visaTypeId: cardFormGroup.get('visaTypeId').value,
      visaStartDate: this.sharedService.formatDate(cardFormGroup.get('visaStartDate').value),
      visaEndDate: this.sharedService.formatDate(cardFormGroup.get('visaEndDate').value),
      isCurrentInUk: cardFormGroup.get('isCurrentInUk').value ? cardFormGroup.get('isCurrentInUk').value : false,
      studentId: this.travelHistoryList[$index].studentId,
      countryId: cardFormGroup.get('countryId').value,
      visaTypeName: getVisName.name
    };

    this.travelHistoryList[this.travelHistoryList.map((x, i) => [i, x]).filter(
      x => x[1]['id'] == this.travelHistoryList[$index].id)[0][0]] = params

    cardFormGroup.disable();
    setTimeout(() => {
      this.manualSave = true;
      this.saveData();
    }, 500);
  }

  removeTravelHistory(index: number) {
    this.travelHistoryList.splice(index, 1);
    this.addTravelForm.reset();
    this.createTravelFormArray();
    setTimeout(() => {
      this.manualSave = true;
      this.saveData();
    }, 500);
  }

  resetDepartureDate() {
    this.addTravelForm.patchValue({
      departureDate: null,
    });
  }

  get countryId(): boolean {
    return !!this.addTravelForm.get('countryId').value;
  }

  checkDataList() {
    if (this.travelHistoryList.length > 0) {
      const dialogRef = this.dialog.open(ErrorComponent, {
        data: { dialogText: 'Please remove all travel history entries to select the option.' }
      });
      setTimeout(() => {
        this.travelHistoryData.travelHistoryExist = 1;
      }, 10)
      return 0;
    } else {
      setTimeout(() => {
        this.manualSave = true;
        this.saveData();
      }, 10)
    }
  }

  setPermission() {
    setTimeout(() => {
      if (this.travelHistoryData.travelHistoryExist == 0 || (this.travelHistoryData.travelHistoryExist == 1 && this.travelHistoryList.length > 0)) {
        this.sisService.removePendingItems(this.sectionIndex);
        this.sisService.setSectionWisePermission('travelHistoryPerm', true);
        this.disableSaveNextBtn = false;
      } else {
        this.sisService.setPendingItems('Travel History', this.sectionIndex)
        this.sisService.setSectionWisePermission('travelHistoryPerm', false);
        this.disableSaveNextBtn = true;
      }
      this.sisService.emitSubmitPermissionChangeAlert('true');
    }, 100);
  }

  filterCountry() {
    let search = this.countryFilterCtrl.value;
    search = search ? search : '';
    this.countryFilteredList = this.countriesList.filter((x: any) => x?.name?.toLowerCase().startsWith(search.toLowerCase()));
  }

  filterEditCountry() {
    let search = this.editCountryFilterCtrl.value;
    search = search ? search : '';
    this.editCountryFilteredList = this.countriesList.filter((x: any) => x?.name?.toLowerCase().startsWith(search.toLowerCase()));
  }
}
