import { Component, OnDestroy, OnInit } from '@angular/core';
import { BreakpointObserver } from '@angular/cdk/layout';

@Component({
  selector: 'app-sis-expiry',
  templateUrl: './sis-expiry.component.html',
  styleUrls: ['./sis-expiry.component.scss']
})
export class SisExpiryComponent implements OnInit, OnDestroy {
  private bpoSubscription: any = {};
  isLgScreen: boolean = false;

  constructor(private bpo: BreakpointObserver) { }
  ngOnInit() {
    const styleToCheck = '(min-width: 720px)';

    //Listen to changes in screen width
    this.bpoSubscription = this.bpo.observe([styleToCheck])
      .subscribe(result => {
        if (result.matches) {
          // Redirect to larger view
          this.isLgScreen = true;
        }
        else {
          // Redirect to smaller view
          this.isLgScreen = false;
        }
      });

  }

  ngOnDestroy(): void {
    this.bpoSubscription.unsubscribe();
  }
}
