import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { SharedService } from '../../shared/shared.service';
import { SisService } from '../sis.service';

@Component({
  selector: 'app-sis-documents',
  templateUrl: './sis-documents.component.html',
  styleUrls: ['./sis-documents.component.scss']
})
export class SisDocumentsComponent {
  documentsList: any = [];
  public sisApDocuments: FormGroup;
  isLgScreen: boolean = false;
  studentId: number;
  userId: number;
  fileInfo: any = {};
  documentsDataList: any = [];
  selectedFile: any = {};

  displayedColumns: string[] = ['documentType', 'document'];
  dataSource: any = [];
  loader: boolean = false;
  manualSave: boolean;
  constructor(private router: Router, private fb: FormBuilder, private toastr: ToastrService,
    private activate: ActivatedRoute, private sharedService: SharedService, private sisService: SisService) {
    this.sisApDocuments = this.fb.group({
      documentCode: ['', [Validators.required]],
      documentFile: ['']
    });
  }

  ngOnInit(): void {
    this.studentId = this.sharedService.getStudentId();
    this.userId = this.sharedService.getUserId();
    this.isLgScreen = this.sharedService.isLgScreen();
    this.manualSave = false;
    this.getDocumentTypesList();
    this.getSISDocumentsList();
    this.sharedService.scrollToTop();
  }

  initializeForm() {
    this.sisApDocuments = this.fb.group({
      documentCode: ['']
    });
  }

  submitData() {
    this.toastr.success("Record Successfully Saved")

  }
  preventSpace(event: KeyboardEvent) {
    if (event.code === "Space") {
      event.preventDefault();
    }
  }

  saveData() {
    this.sisService.setSISDocumentsAtLocal(this.documentsDataList);
    if (this.router.url === '/sis-documents' || this.manualSave) {
      this.sisService.saveSISData(12).subscribe(() => {
        console.log('data saved documents');
      })
    }
  }

  saveAndNext() {
    this.sisService.submitSIS(this.studentId, 12).subscribe((res: any) => {
      if (res) {
        this.router.navigate(['/sis-declaration'])
      }
    }, error => {
      this.toastr.error("Something went wrong", error);
    });
  }

  getDocumentTypesList() {
    this.sisService.getDocumentTypesList(this.studentId).subscribe(res => {
      let response: any = res;
      if (response && response.data) {
        this.documentsList = response.data.items;
      }
    })
  }

  filterDocuments(isRequired: boolean) {
    if (isRequired) {
      return this.documentsList.filter((x: any) => x.isRequired);
    } else {
      return this.documentsList.filter((x: any) => !x.isRequired);
    }
  }

  async onFileSelect(input: HTMLInputElement) {

    /**
     * Format the size to a human readable string
     *
     * @param bytes
     * @returns the formatted string e.g. `105 kB` or 25.6 MB
     */
    function formatBytes(bytes: number): string {
      const UNITS = ['Bytes', 'kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
      const factor = 1024;
      let index = 0;

      while (bytes >= factor) {
        bytes /= factor;
        index++;
      }

      return `${parseFloat(bytes.toFixed(2))} ${UNITS[index]}`;
    }

    const file = input.files[0];
    this.selectedFile = file;
    this.fileInfo = { size: formatBytes(file.size) };


    const toBase64 = (file: any) => new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = error => reject(error);
    });

    let base64: any = await toBase64(file);
    this.uploadDocument(base64);
  }

  uploadDocument(base64File: any) {
    this.loader = true;
    const dataObj = {
      data: {
        "studentId": this.studentId,
        "documentCode": this.sisApDocuments.value.documentCode,
        "document": base64File,
        "documentName": this.selectedFile.name,
        "id": 0
      },
      message: "string",
      messageCode: 0
    }

    this.sisService.uploadSISDocument(dataObj).subscribe((res: any) => {
      if (res) {
        this.sisApDocuments.reset();
        this.sisApDocuments.get('documentCode').setValue('');
        this.manualSave = true;
        this.getSISDocumentsList();
      }
    }, error => {
      this.loader = false;
    })
  }

  getSISDocumentsList() {
    this.loader = true;
    this.sisService.getSISDocumentsData(this.studentId).subscribe((res: any) => {
      let response: any = JSON.parse(res.data);
      if (response) {
        this.documentsDataList = response;
        this.loader = false;
        this.saveData();
      }
    }, error => {
      this.loader = false;
    })
  }

  removeDocument(index: number, document: any) {
    this.loader = true;
    let dataObj = { "Data": { "docId": document.studentDocumentId, "changeStatus": true, "userId": this.sharedService.getUserId() } };
    this.sisService.removeSISDocument(dataObj).subscribe((res: any) => {
      if (res) {
        this.manualSave = true;
        this.getSISDocumentsList();
      }
    }, error => {
      this.loader = false;
    })
  }
}
