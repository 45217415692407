import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { SharedService } from '../shared.service';

@Component({
  selector: 'app-confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.scss']
})
export class ConfirmDialogComponent {
  message: string;
  constructor(private dialogRef: MatDialogRef<ConfirmDialogComponent>, private sharedService: SharedService) {
    this.message = 'Do you wish to Logout ?';
  }

  onConfirmClick(): void {
    this.dialogRef.close(true);
  }

  ngOnInit(): void { }

  logout() {
    console.log('logout called');
    this.dialogRef.close(true);
    this.sharedService.logout();
  }
}
