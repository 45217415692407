import { Component } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { SharedService } from '../../shared/shared.service';
import { SisService } from '../sis.service';
import { debounceTime, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { SisPersonalDetailsDataSet } from '../sis-models/sis-personal-details-data-set';

@Component({
  selector: 'app-sis-personal-details-three',
  templateUrl: './sis-personal-details-three.component.html',
  styleUrls: ['./sis-personal-details-three.component.scss']
})
export class SisPersonalDetailsThreeComponent {
  public sisForm: FormGroup;
  countriesList: any = [];
  statesList: any = [];
  citiesList: any = [];
  isLgScreen: boolean = false;
  studentId: number;
  userId: number;

  protected _onDestroy = new Subject<void>();

  public countryFilterCtrl: FormControl = new FormControl();
  countryFilteredList: Array<any> = [];

  public stateFilterCtrl: FormControl = new FormControl();
  stateFilteredList: Array<any> = [];

  public cityFilterCtrl: FormControl = new FormControl();
  cityFilteredList: Array<any> = [];

  constructor(private router: Router, private fb: FormBuilder, private toastr: ToastrService,
    private activate: ActivatedRoute, private sharedService: SharedService, private sisService: SisService) { }

  ngOnInit(): void {
    this.studentId = this.sharedService.getStudentId();
    this.userId = this.sharedService.getUserId();
    this.isLgScreen = this.sharedService.isLgScreen();
    this.initializeForm();
    this.getCountriesList();
    this.getPersonalDetails();
    this.sharedService.scrollToTop();

    ///////// Auto Save Form //////////
    this.sisForm.valueChanges.pipe(debounceTime(1_000)).subscribe(x => {
      console.log('save personal 3 list');
      this.saveData();
    })
    ///////// Auto Save Form //////////

    /////////// To search Country /////////
    this.countryFilterCtrl.valueChanges
      .pipe(takeUntil(this._onDestroy))
      .subscribe(() => {
        this.filterCountry();
      });
    /////////// To search Country //////////

    /////////// To search State ////////////
    this.stateFilterCtrl.valueChanges
      .pipe(takeUntil(this._onDestroy))
      .subscribe(() => {
        this.filterState();
      });
    /////////// To search State ///////////

    /////////// To search City ////////////
    this.cityFilterCtrl.valueChanges
      .pipe(takeUntil(this._onDestroy))
      .subscribe(() => {
        this.filterCity();
      });
    /////////// To search City /////////////
  }

  ngOnDestroy() {
    this._onDestroy.next();
    this._onDestroy.complete();
  }

  initializeForm() {
    this.sisForm = this.fb.group({
      overseasCountryId: [''],
      overseasPostCode: ['', [Validators.maxLength(15)]],
      overseasAddressLine1: ['', [Validators.maxLength(200)]],
      overseasAddressLine2: ['', [Validators.maxLength(200)]],
      overseasState: [''],
      overseasCity: ['']
    });
  }

  submitData() {
    this.toastr.success("Record Successfully Saved")

  }
  preventSpace(event: KeyboardEvent) {
    if (event.code === "Space") {
      event.preventDefault();
    }
  }

  saveData() {
    const obj: any = JSON.parse(this.sisService.getDefaultValuesPersonalDetailsFromLocal());
    let dataObj: SisPersonalDetailsDataSet = { ...obj };

    dataObj.overseasCountryId = this.sisForm.value.overseasCountryId ? this.sisForm.value.overseasCountryId : '';
    dataObj.overseasPostCode = this.sisForm.value.overseasPostCode ? this.sisForm.value.overseasPostCode : '';
    dataObj.overseasAddressLine1 = this.sisForm.value.overseasAddressLine1 ? this.sisForm.value.overseasAddressLine1 : '';
    dataObj.overseasAddressLine2 = this.sisForm.value.overseasAddressLine2 ? this.sisForm.value.overseasAddressLine2 : '';
    dataObj.overseasState = this.sisForm.value.overseasState ? this.sisForm.value.overseasState : '';
    dataObj.overseasCity = this.sisForm.value.overseasCity ? this.sisForm.value.overseasCity : '';

    this.sisService.setDefaultValuesPersonalDetailsAtLocal(dataObj);

    this.sisService.saveSISData(3).subscribe(() => {
      console.log('data saved');
    })
  }

  saveAndNext() {
    this.sisService.submitSIS(this.studentId, 3).subscribe((res: any) => {
      if (res) {
        this.router.navigate(['/sis-travel-history']);
      }
    }, error => {
      this.toastr.error("Something went wrong", error);
    });
  }

  getCountriesList() {
    if (this.sisService.getCountriesListFromLocalStorage()) {
      this.countriesList = this.sisService.getCountriesListFromLocalStorage();
      this.filterCountry();
    } else {
      this.sisService.getCountriesList().subscribe((res) => {
        let response: any = res;
        if (response && response.data) {
          this.countriesList = response.data.items;
          this.sisService.setCountriesListInLocalStorage(this.countriesList);
          this.filterCountry();
        }
      })
    }
  }

  getStatesListCountryWise(event: any) {
    if (event) {
      this.sisService.getStatesCountryWise(event).subscribe(res => {
        let response: any = res;
        if (response && response.data) {
          this.statesList = response.data.items;
          this.filterState();
        }
      })
    }
  }

  getCitiesListStateWise(event: any) {
    if (event) {
      this.sisService.getCitiesStateWise(event).subscribe(res => {
        let response: any = res;
        if (response && response.data) {
          this.citiesList = response.data.items;
          this.filterCity();
        }
      })
    }
  }

  getPersonalDetails() {
    this.sisService.getSISPersonalDetails(this.studentId, this.userId).subscribe((res: any) => {
      if (res) {
        let response: any = JSON.parse(res.data);
        this.sisForm.controls['overseasCountryId'].setValue(response.overseasCountryId);
        this.sisForm.controls['overseasPostCode'].setValue(response.overseasPostCode);
        this.sisForm.controls['overseasAddressLine1'].setValue(response.overseasAddressLine1);
        this.sisForm.controls['overseasAddressLine2'].setValue(response.overseasAddressLine2);
        this.sisForm.controls['overseasState'].setValue(response.overseasState);
        this.sisForm.controls['overseasCity'].setValue(response.overseasCity);
      }
    })
  }

  filterCountry() {
    let search = this.countryFilterCtrl.value;
    search = search ? search : '';
    this.countryFilteredList = this.countriesList.filter((x: any) => x?.name?.toLowerCase().startsWith(search.toLowerCase()));
  }

  filterState() {
    let search = this.stateFilterCtrl.value;
    search = search ? search : '';
    this.stateFilteredList = this.statesList.filter((x: any) => x?.name?.toLowerCase().startsWith(search.toLowerCase()));
  }

  filterCity() {
    let search = this.cityFilterCtrl.value;
    search = search ? search : '';
    this.cityFilteredList = this.citiesList.filter((x: any) => x?.name?.toLowerCase().startsWith(search.toLowerCase()));
  }
}
