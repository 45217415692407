<app-sis-header *ngIf="!isLgScreen" class="sis-header"></app-sis-header>
<div id="" class="container" [ngClass]="!isLgScreen? 'mt-6':''">

    <fieldset fxLayoutGap="12px" class="mb-2" [ngClass]="!isLgScreen? 'text-center':'text-left'">
        <legend>AP Documents</legend>
        <form [formGroup]="sisApDocuments">
            <div fxLayoutGap="12px" style="margin-top: 10px;" [ngClass]="isLgScreen? 'flex':''">
                <div class="inputParent" [ngClass]="!isLgScreen? 'w-100':''">
                    <mat-label class="inputLabel">Document Type</mat-label>
                    <mat-form-field appearance="outline" class="input" style="padding-top: 5px;width: 100%;">
                        <mat-select formControlName="documentCode">

                            <mat-option value="">Please Select</mat-option>

                            <mat-optgroup style="font-weight: bold;" label="Document Required">
                                <mat-option *ngFor="let docType of filterDocuments(true)"
                                    [value]="docType.documentTypeCode">
                                    {{docType.documentTypeName}}
                                </mat-option>
                            </mat-optgroup>

                            <mat-optgroup style="font-weight: bold;" label="Other Documents">
                                <mat-option *ngFor="let docType of filterDocuments(false)"
                                    [value]="docType.documentTypeCode">
                                    {{docType.documentTypeName}}
                                </mat-option>
                            </mat-optgroup>
                        </mat-select>
                        <mat-hint style="color:red;"
                            *ngIf="sisApDocuments.controls['documentCode'].touched && sisApDocuments.controls['documentCode'].errors ">
                            Please Select Document
                        </mat-hint>
                    </mat-form-field>
                </div>
                <div [ngClass]="isLgScreen? 'mt-2':''">
                    <span *ngIf="!sisApDocuments.get('documentCode').value" style="cursor: no-drop;">
                        <button type="button" class="file-upload-btn" mat-raised-button color="primary" disabled="true">
                        </button>
                    </span>
                    <span *ngIf="sisApDocuments.get('documentCode').value">
                        <label for="file-upload" class="file-upload-btn">
                            <i class="fa fa-cloud-upload"></i>
                        </label>
                        <input id="file-upload" type="file" formControlName="documentFile" #fileInput
                            (change)="onFileSelect(fileInput)" class="file-upload" />
                    </span>
                </div>
            </div>
        </form>
        <br>
        <section id="docsList" *ngIf="documentsDataList.length!==0">
            <table>
                <thead>
                    <tr>
                        <td>Document Type</td>
                        <td>Document</td>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let doc of documentsDataList;let $index=index">
                        <td>{{doc.documentTypeName}}</td>
                        <td (click)="removeDocument($index, doc)" style="cursor: pointer;">
                            <span
                                *ngIf="doc.documentFormat.toLowerCase()=='png' || doc.documentFormat.toLowerCase()=='jpg' || doc.documentFormat.toLowerCase()=='jpeg'">
                                <img src="../../../../assets/images/image_icon.png" alt="image">
                            </span>
                            <span *ngIf="doc.documentFormat.toLowerCase()=='pdf'">
                                <img src="../../../../assets/images/pdf_icon.png" alt="pdf">
                            </span>
                            <span
                                *ngIf="doc.documentFormat.toLowerCase()!=='png' && doc.documentFormat.toLowerCase()!=='pdf' &&  doc.documentFormat.toLowerCase()!=='jpg' && doc.documentFormat.toLowerCase()!=='jpeg'">
                                <img src="../../../../assets/images/file_icon.png" alt="file">
                            </span>
                        </td>
                    </tr>
                </tbody>
            </table>
        </section>
    </fieldset>

    <div class="mb-1 mt-1" *ngIf="!isLgScreen">
        <button mat-raised-button color="primary" class="saveNextBtn" (click)="saveAndNext()" type="button">Save &
            Next</button>
    </div>
</div>