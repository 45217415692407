import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { SharedService } from '../../shared/shared.service';
import { SisService } from '../sis.service';
import { debounceTime } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { PendingFieldsDialogComponent } from '../../shared/pending-fields-dialog/pending-fields-dialog.component';

@Component({
  selector: 'app-sis-declaration',
  templateUrl: './sis-declaration.component.html',
  styleUrls: ['./sis-declaration.component.scss']
})
export class SisDeclarationComponent {
  public sisQuestionnaire: FormGroup;
  isLgScreen: boolean = false;
  studentId: number;
  userId: number;
  disabilityData: any = {};
  criminalData: any = {};
  declarationCheckbox: boolean = false;
  isSubmitBtnValid: boolean = false;
  sectionIndex: number = 13;
  sectionIndexCriminal: number = 14;
  isSubmitted: boolean = false;
  constructor(private router: Router, private fb: FormBuilder, private toastr: ToastrService,
    private activate: ActivatedRoute, private sharedService: SharedService, private sisService: SisService, private dialog: MatDialog) {
    this.sisQuestionnaire = this.fb.group({
      DisabilityAnswered: [''],
      DisabilityState: [''],
      CriminalConvictions: [''],
      CrimeDetails: ['']
    });
  }

  ngOnInit(): void {
    this.studentId = this.sharedService.getStudentId();
    this.userId = this.sharedService.getUserId();
    this.isLgScreen = this.sharedService.isLgScreen();
    this.getDisabilityData();
    this.getCriminalData();
    this.sharedService.scrollToTop();


    ///////// Auto Save Form //////////
    this.sisQuestionnaire.valueChanges.pipe(debounceTime(1_000)).subscribe(x => {
      console.log('save disability list');
      this.saveData(false);
    })
    ///////// Auto Save Form //////////

    this.sisService.getSubmitPermissionChangeAlert().subscribe((res: any) => {
      if (res) {
        if (!this.isSubmitted) {
          this.declarationCheckbox = false;
          this.setSubmitBtnPerm();
        }
      }
    });
  }

  initializeForm() {
    this.sisQuestionnaire = this.fb.group({
      DisabilityAnswered: [''],
      DisabilityState: [''],
      CriminalConvictions: [''],
      CrimeDetails: ['']
    });
  }

  saveData(callFromSubmitBtn: boolean) {
    const disabilityData: any = {
      DisabilityAnswered: this.sisQuestionnaire.value.DisabilityAnswered,
      DisabilityState: this.sisQuestionnaire.value.DisabilityState
    }
    const criminalData: any = {
      CriminalConvictions: this.sisQuestionnaire.value.CriminalConvictions,
      CrimeDetails: this.sisQuestionnaire.value.CrimeDetails
    }
    this.sisService.setSISDisablityDataAtLocal(disabilityData);
    this.sisService.setSISCriminalOffenceDataAtLocal(criminalData);
    this.sisService.setSISDeclarationDataAtLocal(this.declarationCheckbox);
    setTimeout(() => {
      this.submitData(callFromSubmitBtn);
    }, 500);
  }

  submitData(callFromSubmitBtn: boolean) {
    if (callFromSubmitBtn) {
      this.isSubmitted = true;
      this.isSubmitBtnValid = false;
    }
    this.sisService.saveSISData(this.sectionIndex).subscribe((res: any) => {
      if (res) {
        this.setPermission();
        if (callFromSubmitBtn) {
          this.finalSubmitSIS();
        }
      }
    }, error => {
      this.isSubmitted = false;
      this.isSubmitBtnValid = true;
      this.toastr.error("Something went wrong", error);
    })
  }

  preventSpace(event: KeyboardEvent) {
    if (event.code === "Space") {
      event.preventDefault();
    }
  }

  toggleDeclaration(event: any) {
    this.declarationCheckbox = event.target.checked;
    this.getCompletePermissionForSubmitBtn();
  }

  getDisabilityData() {
    this.sisService.getSISDisabilityData(this.studentId).subscribe((res: any) => {
      if (res) {
        this.disabilityData = JSON.parse(res.data);
        this.sisQuestionnaire.controls['DisabilityAnswered'].setValue(this.disabilityData.DisabilityAnswered);
        this.sisQuestionnaire.controls['DisabilityState'].setValue(this.disabilityData.DisabilityState);
      }
    })
  }

  getCriminalData() {
    this.sisService.getSISCriminalConvictionsData(this.studentId).subscribe((res: any) => {
      if (res) {
        this.criminalData = JSON.parse(res.data);
        this.sisQuestionnaire.controls['CriminalConvictions'].setValue(this.criminalData.CriminalConvictions);
        this.sisQuestionnaire.controls['CrimeDetails'].setValue(this.criminalData.CrimeDetails);
      }
    })
  }

  finalSubmitSIS() {
    this.isSubmitted = true;
    this.isSubmitBtnValid = false; // to disable submit btn once clicked to avoid multiple clicks
    this.sisService.submitSIS(this.studentId, this.sectionIndex).subscribe((res: any) => {
      if (res) {
        this.isSubmitted = false;
        this.isSubmitBtnValid = true;
        this.router.navigate(['/sis-thank-you']);
        this.sharedService.clearLocalStorage();
      }
    }, error => {
      this.isSubmitted = false;
      this.isSubmitBtnValid = true;
      this.toastr.error("Something went wrong", error);
    })
  }

  setPermission() {
    let disabilityFlag: boolean = false;
    let criminalFlag: boolean = false;
    if (!this.sisQuestionnaire.value.DisabilityAnswered || (this.sisQuestionnaire.value.DisabilityAnswered == true && this.sisQuestionnaire.value.DisabilityState !== '')) {
      this.sisService.removePendingItems(this.sectionIndex);
      disabilityFlag = true;
    } else {
      this.sisService.setPendingItems('Disability', this.sectionIndex);
      disabilityFlag = false;
    }
    if (!this.sisQuestionnaire.value.CriminalConvictions || (this.sisQuestionnaire.value.CriminalConvictions == true && this.sisQuestionnaire.value.CrimeDetails !== '')) {
      this.sisService.removePendingItems(this.sectionIndexCriminal);
      criminalFlag = true;
    } else {
      this.sisService.setPendingItems('Criminal Offense', this.sectionIndexCriminal);
      criminalFlag = false;
    }
    this.sisService.setSectionWisePermission('disablityDetailsPerm', disabilityFlag);
    this.sisService.setSectionWisePermission('criminalDetailsPerm', criminalFlag);
    this.sisService.emitSubmitPermissionChangeAlert('true');
  }

  getCompletePermissionForSubmitBtn() {
    let list = this.sisService.getPendingFieldsList();
    if (list && list.length > 0) {
      const dialogRef = this.dialog.open(PendingFieldsDialogComponent, {
        data: { pendingItems: list }
      });
      setTimeout(() => {
        this.declarationCheckbox = false;
      }, 10);
      return 0;
    }
    this.setSubmitBtnPerm();
  }

  setSubmitBtnPerm() {
    if (this.declarationCheckbox) {
      this.isSubmitBtnValid = true;
    } else {
      this.isSubmitBtnValid = false;
    }
  }
}
