import { Component, OnDestroy, OnInit } from '@angular/core';
import { BreakpointObserver } from '@angular/cdk/layout';
import { SharedService } from '../shared.service';

@Component({
  selector: 'app-sis-thank-you',
  templateUrl: './sis-thank-you.component.html',
  styleUrls: ['./sis-thank-you.component.scss']
})
export class SisThankYouComponent implements OnInit, OnDestroy {
  private bpoSubscription: any = {};
  isLgScreen: boolean = false;

  constructor(private bpo: BreakpointObserver, private sharedService: SharedService) { }
  ngOnInit() {
    const styleToCheck = '(min-width: 720px)';

    //Listen to changes in screen width
    this.bpoSubscription = this.bpo.observe([styleToCheck])
      .subscribe(result => {
        if (result.matches) {
          // Redirect to larger view
          this.isLgScreen = true;
        }
        else {
          // Redirect to smaller view
          this.isLgScreen = false;
        }
      });
    this.sharedService.clearLocalStorage();
  }

  ngOnDestroy(): void {
    this.bpoSubscription.unsubscribe();
  }
}
