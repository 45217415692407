<div class="container" style="padding-top: 50px;">
    <div fxLayout="column" class="flex-container" fxLayoutAlign="center center">

        <div fxLayout="row wrap" fxLayout.xs="column" fxLayoutGap="450px">
            <img *ngIf="!isCA" src="https://siuk-india.s3.ap-south-1.amazonaws.com/assets/images/email/mailchimp/SI-Global_logo.svg" class="logo-as-AP-SI">
            <img *ngIf="isCA" src="https://siuk-india.s3.ap-south-1.amazonaws.com/assets/images/email/mailchimp/SI-Canada_logo.svg" class="logo-as-AP">
        </div>

        <div style="margin-top:50px ;">
            <h3><b>SI-UK STUDENT INFORMATION SHEET</b></h3>
        </div>

        <div style="text-align: center;">
            <p>By completing this form, I permit SI-UK to enter my information into application forms for the courses
                and
                schools listed under University choice.</p>
        </div>

        <form #registerForm="ngForm" class="w-100" (ngSubmit)="registerForm.form.valid && showOTPform()" novalidate>
            <div class="inputSection" fxLayoutAlign="center center">
                <div (click)="enableEmail()" class="inputFieldSection"
                    *ngIf="(disableMobile && disableEmail) || !disableEmail2">
                    <p style="text-align: center;">Enter your registered Email ID <span class="error">*</span></p>
                    <mat-form-field appearance="outline" class="input">
                        <input type="text" email="true" #prmEmail="ngModel" name="primaryEmail" class="inputField"
                        matInput [(ngModel)]="loginDataObj.email" autocomplete="off" required>
                        <mat-error *ngIf="prmEmail.touched && prmEmail.errors?.['email']">
                            Email Id is Invalid
                        </mat-error>
                        <mat-error *ngIf="prmEmail.touched && prmEmail.errors?.['required']">
                            Email Id is required
                        </mat-error>
                    </mat-form-field>
                </div>
            </div>

            <div *ngIf="disableMobile && disableEmail">
                <p>or</p>
            </div>

            <div class="inputSection" fxLayoutAlign="center center">
                <div (click)="enableMobile()" class="inputFieldSection"
                    *ngIf="(disableMobile && disableEmail) || !disableMobile2">
                    <p style="text-align: center;">Enter your registered Mobile Number <span class="error">*</span></p>
                    <mat-form-field appearance="outline" class="input">
                        <ngx-mat-intl-tel-input [preferredCountries]="['in']" class="w-100 inputField" #mobile="ngModel" name="mobile" [enableSearch]="true"
                        placeholder="Enter Mobile Number"
                        [(ngModel)]="loginDataObj.mobile" autocomplete="off"></ngx-mat-intl-tel-input>
                        <mat-error *ngIf="mobile.touched && mobile.errors?.['required']">
                            Mobile Number is Required
                        </mat-error>
                    </mat-form-field>
                </div>
            </div>

            <div *ngIf="!disableEmail2 || !disableMobile2">
                <button mat-raised-button color="primary" type="submit" style="background-color: #0C3778;">Send
                    OTP
                    <span style="vertical-align: -5px;">
                        <mat-icon fontIcon="arrow_right_alt"> </mat-icon>
                    </span>
                </button>
            </div>

            <div *ngIf="!disableMobile2">
                <span class="material-icons" style="margin-top: 10px;"> arrow_back </span> <a
                    (click)="funArrowMobile(registerForm)"
                    style="text-decoration: none;margin-left:2px; font-weight: bold;color: black;cursor: pointer;vertical-align: super;">Back</a>
            </div>

            <div *ngIf="!disableEmail2">
                <span class="material-icons" style="margin-top: 10px;"> arrow_back </span> <a
                    (click)="funArrowEmail(registerForm)"
                    style="text-decoration: none;margin-left:2px; font-weight: bold;color: black;cursor: pointer;vertical-align: super;">Back</a>
            </div>
        </form>

        <div style="margin-top: 15px;text-align: center " class="inputFieldSection" *ngIf="otpForm">
            <h3 style="font-weight: bold;color:#0C3778;text-align: center ;">Enter OTP <span class="error">*</span></h3>
            <div>
                <p style="text-align: center ">
                    <span *ngIf="disableEmail">
                        Check your email for OTP
                    </span>
                    <span *ngIf="disableMobile">
                        Check your mobile for OTP
                    </span>
                </p>
                <p *ngIf="disableEmail"><strong>It may take around 1 minute to receive the OTP on email</strong></p>
                <mat-form-field appearance="outline" class="input">
                    <input type="number" class="inputField" matInput [(ngModel)]="loginDataObj.otp" placeholder="Enter OTP">
                </mat-form-field>
            </div>

            <div class="otpBtns">
                <div id="resendOtpBtn">
                    <button [disabled]="disableResendOTPBtn" [ngClass]="disableResendOTPBtn?'disabledBtn':'enabledBtn'"
                        mat-raised-button (click)="resendOtp()">Resend OTP
                        <span style="vertical-align: -5px;"><mat-icon fontIcon="refresh">
                            </mat-icon></span> </button>
                    <span class="resendOtpTimer" *ngIf="disableResendOTPBtn">( Resend OTP in {{counter | formatTime}}
                        )</span>
                </div>
                <div>
                    <button mat-raised-button color="primary" style="background-color: #128807;"
                        (click)="verifyOtp()">Verify
                        OTP <span style="vertical-align: -5px;"><mat-icon fontIcon="arrow_right_alt">
                            </mat-icon></span> </button>
                </div>
            </div>
            <div>
                <span class="material-icons" style="margin-top: 10px;"> arrow_back </span> <a (click)="otpBackBtn()"
                    style="text-decoration: none;margin-left:2px; font-weight: bold;color: black;cursor: pointer;vertical-align: super;">Back</a>
            </div>

        </div>
    </div>
</div>