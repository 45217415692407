import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ErrorComponent } from './error/error.component';
import { MatDialogModule } from '@angular/material/dialog';
import { MatCardModule } from '@angular/material/card';
import { FormatTimePipe } from './pipes/format-time.pipe';
import { SisExpiryComponent } from './sis-expiry/sis-expiry.component';
import { SisThankYouComponent } from './sis-thank-you/sis-thank-you.component';
import { PendingFieldsDialogComponent } from './pending-fields-dialog/pending-fields-dialog.component';
import { FormatPendingFieldsDisplayPipe } from './pipes/format-pending-fields-display.pipe';
import { ConfirmDialogComponent } from './confirm-dialog/confirm-dialog.component';



@NgModule({
  declarations: [
    ErrorComponent,
    FormatTimePipe,
    SisExpiryComponent,
    SisThankYouComponent,
    PendingFieldsDialogComponent,
    FormatPendingFieldsDisplayPipe,
    ConfirmDialogComponent
  ],
  imports: [
    CommonModule,
    MatDialogModule,
    MatCardModule
  ],
  exports: [FormatTimePipe, FormatPendingFieldsDisplayPipe]
})
export class SharedModule { }
