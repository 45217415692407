import { Component } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { SharedService } from '../../shared/shared.service';
import { SisService } from '../sis.service';
import { MatTableDataSource } from '@angular/material/table';
import { Observable } from 'rxjs';
import { ErrorComponent } from '../../shared/error/error.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-sis-english-language-exam',
  templateUrl: './sis-english-language-exam.component.html',
  styleUrls: ['./sis-english-language-exam.component.scss']
})
export class SisEnglishLanguageExamComponent {
  testsList: any = [];
  isLgScreen: boolean = false;
  englishExamsData: any = {};
  studentId: number;
  userId: number;

  languageExamForm: FormGroup;
  testTakenList: Array<any> = [];
  languageExamList: Array<any> = [];
  tableForm: any;
  dataSource = new MatTableDataSource<any>();
  formsList: Observable<any>;
  addAnother: boolean = false;
  isCompleted: boolean;
  currentDate: Date;
  englishExamNotRequired: any;
  showAddForm: boolean = true;
  currentYear: number;
  startDate: Date;
  isStudent: boolean;
  disableSaveNextBtn: boolean = false;
  sectionIndex: number = 8;
  manualSave: boolean;
  constructor(private router: Router, private formBuilder: FormBuilder, private toastr: ToastrService,
    private activate: ActivatedRoute, private sharedService: SharedService, private sisService: SisService, private dialog: MatDialog) { }

  ngOnInit(): void {
    this.currentDate = new Date();
    this.currentYear = this.currentDate.getFullYear();
    this.startDate = new Date(this.currentYear - 2, this.currentDate.getMonth(), this.currentDate.getDate());
    this.studentId = this.sharedService.getStudentId();
    this.userId = this.sharedService.getUserId();
    this.isLgScreen = this.sharedService.isLgScreen();
    this.manualSave = false;
    this.getTestsList();
    this.sharedService.scrollToTop();
    this.languageExamForm = new FormGroup({
      TestTakenId: new FormControl('', [Validators.required]),
      DateOfTest: new FormControl('', [Validators.required]),
      Score: new FormControl('', [Validators.required]),
      TestReferenceID: new FormControl(''),
      Remarks: new FormControl('')
    })
  }

  submitData() {
    this.toastr.success("Record Successfully Saved")

  }
  preventSpace(event: KeyboardEvent) {
    if (event.code === "Space") {
      event.preventDefault();
    }
  }

  saveData() {
    let flag;
    if (this.englishExamNotRequired == null) {
      flag = 'null';
    } else {
      flag = this.englishExamNotRequired ? 'true' : 'false';
    }
    const data = { IsEnglishExamNotRequired: flag, List: this.languageExamList };
    this.sisService.setSISEnglishExamDetailsAtLocal(data);
    this.setPermission();
    if (this.router.url === '/sis-english-language-exam' || this.manualSave) {
      this.sisService.saveSISData(this.sectionIndex).subscribe(() => {
        console.log('data saved engish');
      })
    }
  }

  saveAndNext() {
    this.sisService.submitSIS(this.studentId, this.sectionIndex).subscribe((res: any) => {
      if (res) {
        this.router.navigate(['/sis-other-exams'])
      }
    }, error => {
      this.toastr.error("Something went wrong", error);
    });
  }

  getTestsList() {
    this.sisService.getLanguageTestsList(1).subscribe(res => {
      let response: any = res;
      if (response && response.data) {
        this.testTakenList = response.data.items;
        this.getEnglishExamsData();
      }
    })
  }

  getEnglishExamsData() {
    this.sisService.getSISEnglishExamsData(this.studentId, this.userId).subscribe((res: any) => {
      if (res) {
        this.englishExamsData = JSON.parse(res.data);
        if (this.englishExamsData.List) {
          this.englishExamsData.List.forEach((item: any) => item.isEditable = false);
          this.languageExamList = this.englishExamsData.List;
        } else {
          this.englishExamsData.List = [];
          this.languageExamList = [];
        }

        if (this.englishExamsData.IsEnglishExamNotRequired == 'null') {
          this.englishExamNotRequired = null;
        } else {
          this.englishExamNotRequired = this.englishExamsData.IsEnglishExamNotRequired == "true" ? true : false;
        }

        if (this.englishExamNotRequired) {
          this.showAddForm = false;
        }
        this.dataSource.data = this.languageExamList;
        this.saveData();
        this.languageExamFormTbl();
      }
    })
  }

  addLanguageExam(formDirectiveGroup: any): void {
    const row = { ...this.languageExamForm.value, studentId: this.studentId, DateOfTest: this.sharedService.formatDate(this.languageExamForm.value.DateOfTest), Id: 0 };
    if (row.Score) {
      row.Score = row.Score.toString();
    }
    this.addAnother = false;
    this.languageExamList.push(row);
    this.englishExamNotRequired = true;
    this.languageExamForm.reset();
    this.languageExamFormTbl();
    setTimeout(() => {
      this.manualSave = true;
      this.saveData();
    }, 500);
  }

  updateLanguageExam(row: any, $index: number): void {
    const params = {
      Id: this.languageExamList[$index].Id,
      studentId: this.studentId,
      TestTakenId: row.value.TestTakenId,
      DateOfTest: this.sharedService.formatDate(row.value.DateOfTest),
      Score: row.value.Score.toString(),
      TestReferenceID: row.value.TestReferenceID,
      Remarks: row.value.Remarks
    }
    this.languageExamList[this.languageExamList.map((x, i) => [i, x]).filter(
      x => x[1]['Id'] == this.languageExamList[$index].Id)[0][0]] = params;

    row.disable();
    setTimeout(() => {
      this.manualSave = true;
      this.saveData();
    }, 500);
  }

  deleteLanguageExam($index: number): void {
    this.languageExamList.splice($index, 1);
    this.languageExamFormTbl();
    setTimeout(() => {
      this.manualSave = true;
      this.saveData();
    }, 500);
  }

  languageExamFormTbl() {
    this.tableForm = this.formBuilder.group({
      languageExamFormTbl: this.formBuilder.array(
        this.languageExamList.map((formControl: any) => this.formBuilder.group({
          TestTakenId: [formControl['TestTakenId'], [Validators.required]],
          DateOfTest: [formControl['DateOfTest'], [Validators.required]],
          Score: [formControl['Score'], [Validators.required]],
          TestReferenceID: [formControl['TestReferenceID']],
          Remarks: [formControl['Remarks']],
          id: [formControl['id']]
        }))
      )
    });
    this.tableForm.controls.languageExamFormTbl.disable();
    this.dataSource = new MatTableDataSource<any>(this.tableForm.controls.languageExamFormTbl.controls);
    this.formsList = this.dataSource.connect();
  }

  onChange(data: any) {
    if (data === 8) {
      this.languageExamForm.get('DateOfTest').disable();
      this.languageExamForm.get('Score').disable();
    } else {
      this.languageExamForm.get('DateOfTest').enable();
      this.languageExamForm.get('Score').enable();
    }
  }

  checkMinMax(testId: any, score: number, showError: boolean) {
    if (testId && this.testTakenList.findIndex(obj => obj.id == testId) != -1) {
      const scoreType = this.testTakenList[this.testTakenList.findIndex(obj => obj.id == testId)]['defaultScoreType'];
      if (scoreType == 'number') {
        if (!isNaN(score)) {
          const min = parseInt(this.testTakenList[this.testTakenList.findIndex(obj => obj.id == testId)]['minDefaultScoreRange']);
          const max = parseInt(this.testTakenList[this.testTakenList.findIndex(obj => obj.id == testId)]['maxDefaultScoreRange']);

          if ((score < min) || (score > max)) {
            if (showError) {
              return 'Score should be between ' + min + ' and ' + max;
            } else {
              return true;
            }
          } else {
            return false;
          }
        } else {
          return 'Score should be numeric for this test';
        }
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  checkDisableClass(cardFormGroup: any) {
    return this.sharedService.setDisableClass(cardFormGroup);
  }

  setPermission() {
    setTimeout(() => {
      if (!this.englishExamNotRequired || (this.englishExamNotRequired && this.languageExamList.length > 0)) {
        this.sisService.removePendingItems(this.sectionIndex);
        this.sisService.setSectionWisePermission('englishLanguageExamPerm', true);
        this.disableSaveNextBtn = false;
      } else {
        this.sisService.setPendingItems('English Language Exams', this.sectionIndex);
        this.sisService.setSectionWisePermission('englishLanguageExamPerm', false);
        this.disableSaveNextBtn = true;
      }
      this.sisService.emitSubmitPermissionChangeAlert('true');
    }, 100);
  }

  checkDataList() {
    if (this.languageExamList.length > 0) {
      const dialogRef = this.dialog.open(ErrorComponent, {
        data: { dialogText: 'Please remove all english exam entries to select the option.' }
      });
      setTimeout(() => {
        this.englishExamNotRequired = true;
      }, 10)
      return 0;
    } else {
      setTimeout(() => {
        this.manualSave = true;
        this.saveData();
      }, 10)
    }
  }
}
