import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-error',
  templateUrl: './error.component.html',
  styleUrls: ['./error.component.scss']
})
export class ErrorComponent {
  message: string;
  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<ErrorComponent>) {
    this.message = data.dialogText;
  }

  onConfirmClick(): void {
    this.dialogRef.close(true);
  }

  ngOnInit(): void { }
}
