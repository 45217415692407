import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'formatPendingFieldsDisplay'
})
export class FormatPendingFieldsDisplayPipe implements PipeTransform {

  transform(value: unknown, ...args: unknown[]): unknown {
    if (value == 'firstName') {
      return 'First Name'
    } else if (value == 'familyName') {
      return 'Surname'
    } else if (value == 'gender') {
      return 'Gender'
    } else if (value == 'MaritalStatus') {
      return 'Marital Status'
    } else if (value == 'phoneNum') {
      return 'Phone Number'
    } else if (value == 'dob') {
      return 'Date Of Birth'
    } else if (value == 'email') {
      return 'Email'
    } else if (value == 'nationalityId') {
      return 'Nationality'
    } else if (value == 'nameInPassport') {
      return 'Name In Passport'
    } else if (value == 'PlaceofBirth') {
      return 'Place Of Birth'
    } else if (value == 'AlternateEmailID') {
      return 'Alternate EmailID'
    } else if (value == 'AlternatePhoneNo') {
      return 'Alternate Phone Number'
    } else if (value == 'PermanentCountryId') {
      return 'Permanent Country'
    } else if (value == 'PermanentpostCode') {
      return 'Permanent PostCode'
    } else if (value == 'PermanentaddressLine1') {
      return 'Permanent Address Line 1'
    } else if (value == 'Permanentstate') {
      return 'Permanent State'
    } else if (value == 'Permanentcity') {
      return 'Permanent City'
    } else if (value == 'CurrentCountryId') {
      return 'Current Country'
    } else if (value == 'PostCode') {
      return 'Current Post Code'
    } else if (value == 'AddressLine1') {
      return 'Current Address Line 1'
    } else if (value == 'StateOfResidenceId') {
      return 'Current State'
    } else if (value == 'CityOfResidenceId') {
      return 'Current City'
    } else {
      return value;
    }
  }
}
