<app-sis-header *ngIf="!isLgScreen" class="sis-header"></app-sis-header>
<div id="personalDetailsPageOne" class="container" [ngClass]="!isLgScreen?' mt-6':''" fxLayout="row wrap"
    fxLayout.lt-md="column">

    <div *ngIf="!isLgScreen">
        <fieldset fxLayoutGap="12px" class="mb-2">
            <legend>Personal Details</legend>

            <form [formGroup]="sisForm">
                <h3 class="personal-details-head">Overseas Address<span style="color: red;"> (if available)</span>
                </h3>

                <div class="inputParent">
                    <mat-label class="inputLabel">Country</mat-label>
                    <mat-form-field appearance="outline" class="input">
                        <mat-select formControlName="overseasCountryId"
                            (ngModelChange)="getStatesListCountryWise($event)" placeholder="Please Select">
                            <mat-option>
                                <ngx-mat-select-search [formControl]="countryFilterCtrl"
                                    placeholderLabel="Search Country..."
                                    noEntriesFoundLabel="No results found"></ngx-mat-select-search>
                            </mat-option>
                            <mat-option *ngFor="let country of countryFilteredList" [value]="country.id">
                                {{country.name}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>

                <div class="inputParent">
                    <mat-label class="inputLabel">Post Code</mat-label>
                    <mat-form-field appearance="outline" class="input">
                        <input matInput type="text" formControlName="overseasPostCode" placeholder="Enter Post Code">
                        <mat-error *ngIf="sisForm.controls['overseasPostCode'].errors?.['maxlength'] ">
                            Maximum limit reached
                        </mat-error>
                    </mat-form-field>
                </div>

                <div class="inputParent">
                    <mat-label class="inputLabel">Address 1</mat-label>
                    <mat-form-field appearance="outline" class="input">
                        <input matInput placeholder="Enter Address 1" formControlName="overseasAddressLine1">
                        <mat-error *ngIf="sisForm.controls['overseasAddressLine1'].errors?.['maxlength'] ">
                            Maximum limit reached
                        </mat-error>
                    </mat-form-field>
                </div>

                <div class="inputParent">
                    <mat-label class="inputLabel">Address 2</mat-label>
                    <mat-form-field appearance="outline" class="input">
                        <input matInput placeholder="Enter Address 2" formControlName="overseasAddressLine2">
                    </mat-form-field>
                </div>

                <div class="inputParent">
                    <mat-label class="inputLabel">State/Territory</mat-label>
                    <mat-form-field appearance="outline" class="input">
                        <mat-select formControlName="overseasState" (ngModelChange)="getCitiesListStateWise($event)"
                            placeholder="Please Select">
                            <mat-option>
                                <ngx-mat-select-search [formControl]="stateFilterCtrl"
                                    placeholderLabel="Search State..."
                                    noEntriesFoundLabel="No results found"></ngx-mat-select-search>
                            </mat-option>
                            <mat-option *ngFor="let state of stateFilteredList" [value]="state.id">
                                {{state.name}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>

                <div class="inputParent">
                    <mat-label class="inputLabel">City</mat-label>
                    <mat-form-field appearance="outline" class="input">
                        <mat-select formControlName="overseasCity" placeholder="Please Select">
                            <mat-option>
                                <ngx-mat-select-search [formControl]="cityFilterCtrl" placeholderLabel="Search City..."
                                    noEntriesFoundLabel="No results found"></ngx-mat-select-search>
                            </mat-option>
                            <mat-option *ngFor="let city of cityFilteredList" [value]="city.id">
                                {{city.name}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </form>
        </fieldset>

        <div class="mb-1">
            <button mat-raised-button color="primary" class="saveNextBtn" (click)="saveAndNext()" type="button">Save &
                Next</button>
        </div>
    </div>

    <div *ngIf="isLgScreen">
        <form [formGroup]="sisForm" class="personalForm">
            <h3 class="text-left personal-details-head">Overseas Address<span style="color: red;"> (if
                    available)</span></h3>

            <div class="inputParent">
                <mat-label class="inputLabel">Country</mat-label>
                <mat-form-field appearance="outline" class="input">
                    <mat-select formControlName="overseasCountryId" (ngModelChange)="getStatesListCountryWise($event)"
                        placeholder="Please Select">
                        <mat-option>
                            <ngx-mat-select-search [formControl]="countryFilterCtrl"
                                placeholderLabel="Search Country..."
                                noEntriesFoundLabel="No results found"></ngx-mat-select-search>
                        </mat-option>
                        <mat-option *ngFor="let country of countryFilteredList" [value]="country.id">
                            {{country.name}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>

            <div class="inputParent">
                <mat-label class="inputLabel">Post Code</mat-label>
                <mat-form-field appearance="outline" class="input">
                    <input matInput type="text" formControlName="overseasPostCode" placeholder="Enter Post Code">
                    <mat-error *ngIf="sisForm.controls['overseasPostCode'].errors?.['maxlength'] ">
                        Maximum limit reached
                    </mat-error>
                </mat-form-field>
            </div>

            <div class="inputParent">
                <mat-label class="inputLabel">Address 1</mat-label>
                <mat-form-field appearance="outline" class="input">
                    <input matInput placeholder="Enter Address 1" formControlName="overseasAddressLine1">
                    <mat-error *ngIf="sisForm.controls['overseasAddressLine1'].errors?.['maxlength'] ">
                        Maximum limit reached
                    </mat-error>
                </mat-form-field>
            </div>

            <div class="inputParent">
                <mat-label class="inputLabel">Address 2</mat-label>
                <mat-form-field appearance="outline" class="input">
                    <input matInput placeholder="Enter Address 2" formControlName="overseasAddressLine2">
                </mat-form-field>
            </div>

            <div class="inputParent">
                <mat-label class="inputLabel">State/Territory</mat-label>
                <mat-form-field appearance="outline" class="input">
                    <mat-select formControlName="overseasState" (ngModelChange)="getCitiesListStateWise($event)"
                        placeholder="Please Select">
                        <mat-option>
                            <ngx-mat-select-search [formControl]="stateFilterCtrl" placeholderLabel="Search State..."
                                noEntriesFoundLabel="No results found"></ngx-mat-select-search>
                        </mat-option>
                        <mat-option *ngFor="let state of stateFilteredList" [value]="state.id">
                            {{state.name}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>

            <div class="inputParent">
                <mat-label class="inputLabel">City</mat-label>
                <mat-form-field appearance="outline" class="input">
                    <mat-select formControlName="overseasCity" placeholder="Please Select">
                        <mat-option>
                            <ngx-mat-select-search [formControl]="cityFilterCtrl" placeholderLabel="Search City..."
                                noEntriesFoundLabel="No results found"></ngx-mat-select-search>
                        </mat-option>
                        <mat-option *ngFor="let city of cityFilteredList" [value]="city.id">
                            {{city.name}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </form>
    </div>
</div>