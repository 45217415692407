import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ConfirmDialogComponent } from './confirm-dialog/confirm-dialog.component';
import { MatDialog } from '@angular/material/dialog';

@Injectable({
  providedIn: 'root'
})
export class SharedService {

  constructor(private router: Router, private dialog: MatDialog) { }

  scrollToTop() {
    let scrollToTop = window.setInterval(() => {
      let pos = window.pageYOffset;
      if (pos > 0) {
        window.scrollTo(0, pos - 20); // how far to scroll on each step
      } else {
        window.clearInterval(scrollToTop);
      }
    }, 16);
  }

  isLgScreen() {
    if (this.router.url === '/sis-form') {
      return true;
    } else {
      return false;
    }
  }

  setMasterKey(key: string) {
    localStorage.setItem('masterSisKey', key);
  }

  getMasterKey() {
    return localStorage.getItem('masterSisKey');
  }

  setToken(token: string) {
    localStorage.setItem('token', token);
  }

  getToken() {
    return localStorage.getItem('token');
  }

  setStudentId(id: number) {
    localStorage.setItem('sid', id.toString());
  }

  getStudentId() {
    let id: string = localStorage.getItem('sid');
    return parseInt(id);
  }

  setUserId(id: number) {
    localStorage.setItem('uid', id.toString());
  }

  getUserId() {
    let id: string = localStorage.getItem('uid');
    return parseInt(id);
  }

  // function to format date in yyyy-mm-dd
  formatDate(selectedDate: string) {
    let date = new Date(selectedDate);
    let getYear = date.toLocaleString("en-us", { year: "numeric" });
    let getMonth = date.toLocaleString("en-us", { month: "2-digit" });
    let getDay = date.toLocaleString("en-us", { day: "2-digit" });
    let dateFormat = getYear + "-" + getMonth + "-" + getDay;
    return dateFormat;
  }

  setDisableClass(cardFormGroup: any) {
    if (cardFormGroup.status == 'DISABLED') {
      return 'disabledColor';
    } else {
      return 'enabledColor';
    }
  }

  redirectToExpiryPage() {
    this.router.navigate(['/sis-expired']);
  }

  clearLocalStorage() {
    localStorage.clear();
  }

  redirectToMainWebsite() {
    window.open("https://www.studyin-uk.in/", "_self");
  }

  loggedIn() {
    return !!localStorage.getItem('token');
  }

  showLogoutDialog() {
    this.dialog.open(ConfirmDialogComponent);
  }

  logout() {
    this.router.navigate(['/home'], { queryParams: { key: this.getMasterKey(), isCA: this.getStudentCountryCheck() } });
  }

  setStudentCountryCheck(isCA: boolean){
    localStorage.setItem('studentCountryCheck', isCA.toString());
  }

  getStudentCountryCheck(){
    let isCA = localStorage.getItem('studentCountryCheck');
    return JSON.parse(isCA.toLowerCase())
  }
}
