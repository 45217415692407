import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SharedService } from '../../shared/shared.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class HomeService {

  constructor(private http: HttpClient, private sharedService: SharedService) { }

  sendMobileOtp(mobile: number) {
    return this.http.post(environment.baseUrl + 'SISLink/sendOtp?mobile=' + mobile + '&key=' + this.sharedService.getMasterKey(), null);
  }

  resendMobileOtp(mobile: number) {
    return this.http.post(environment.baseUrl + 'SISLink/resendOtp?mobile=' + mobile + '&key=' + this.sharedService.getMasterKey(), null);
  }

  verifyMobileOtp(mobile: number, otp: number) {
    return this.http.post(environment.baseUrl + 'SISLink/verifyOtp?mobile=' + mobile + '&otp=' + otp + '&key=' + this.sharedService.getMasterKey(), null);
  }

  sendEmailOtp(email: string) {
    return this.http.post(environment.baseUrl + 'SISLink/sendEmailOtp?email=' + email + '&key=' + this.sharedService.getMasterKey(), null);
  }

  resendEmailOtp(email: string) {
    return this.http.post(environment.baseUrl + 'SISLink/resendEmailOtp?email=' + email + '&key=' + this.sharedService.getMasterKey(), null);
  }

  verifyEmailOtp(email: string, otp: number) {
    return this.http.post(environment.baseUrl + 'SISLink/verifyEmailOtp?email=' + email + '&otp=' + otp + '&key=' + this.sharedService.getMasterKey(), null);
  }

  getLinkExpiryStatus() {
    return this.http.post(environment.baseUrl + 'SISLink/getSISLinkExpiryStatus?key=' + this.sharedService.getMasterKey(), null);
  }
}
