import { Component, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup, NgForm } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { HomeService } from './home.service';
import { SharedService } from '../../shared/shared.service';
import { timer, Subscription } from 'rxjs';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnDestroy {
  disableMobile: boolean = true;
  disableEmail: boolean = true;
  disableMobile2: boolean = true
  disableEmail2: boolean = true;
  otpForm: boolean = false;
  loginDataObj: any = {};
  key: string = '';
  disableResendOTPBtn: boolean = false;
  countDown: Subscription;
  counter = 60;
  tick = 1000;
  duplicateNumberMsg: string;
  showDuplicateNumberMsg: boolean = false;
  timer1: any;
  isCA: boolean;
  constructor(public toastr: ToastrService, private fb: FormBuilder, private router: Router, private homeService: HomeService, private actRoute: ActivatedRoute, private sharedService: SharedService) { }

  ngOnInit() {
    this.sharedService.clearLocalStorage();
    this.checkIfKeyValid();
    const bodyElement = document.body;
    // bodyElement.classList.add('gradientBG');
  }

  checkIfKeyValid() {
    this.actRoute.queryParams.subscribe(res => {
      this.key = res['key'];
      if(res.hasOwnProperty('isCA')){
        if(res['isCA'] == "true"){
          this.isCA = true;
        }else{
          this.isCA = false;
        }
      }else{
        this.isCA = false;
      }
      this.sharedService.setStudentCountryCheck(this.isCA)
    });
    if (this.key) {
      this.sharedService.setMasterKey(this.key);
      this.checkLinkExpiry();
    } else {
      this.sharedService.clearLocalStorage();
      this.sharedService.redirectToMainWebsite();
    }
  }

  checkLinkExpiry() {
    this.homeService.getLinkExpiryStatus().subscribe((res: any) => {
      if (res && res.data) {
        this.sharedService.redirectToExpiryPage();
      }
    })
  }

  enableEmail() {
    this.disableMobile = false
    this.disableEmail = true
    this.disableEmail2 = false
  }

  enableMobile() {
    this.disableEmail = false
    this.disableMobile = true
    this.disableMobile2 = false
  }

  funArrowMobile(registerForm?: NgForm) {
    this.disableEmail = true
    this.disableMobile = true
    this.disableMobile2 = true
    this.loginDataObj = {};
    if (registerForm) {
      registerForm.form.reset();
    }
  }

  funArrowEmail(registerForm?: NgForm) {
    this.disableEmail = true
    this.disableMobile = true
    this.disableEmail2 = true
    this.loginDataObj = {};
    if (registerForm) {
      registerForm.form.reset();
    }
  }

  disableResendOTP() {
    this.disableResendOTPBtn = true;
    this.counter = 60;
    const tick = 1000;
    this.countDown = timer(0, tick).subscribe(() => --this.counter);
    this.timer1 = setInterval(() => {
      this.disableResendOTPBtn = false;
      this.counter = 60;
      this.countDown.unsubscribe();
      console.log('💥')
    }, 60000);
  }

  showOTPform() {
    if (this.disableMobile) {
      if (this.loginDataObj.mobile) {
        this.homeService.sendMobileOtp(this.loginDataObj.mobile).subscribe((res: any) => {
          if (res && res.responseCode == 200) {
            this.disableResendOTP();
            this.otpForm = true
            this.disableEmail2 = true
            this.disableMobile2 = true
            this.showDuplicateNumberMsg = false;
          } else if (res && res.responseCode == 408) {
            this.duplicateNumberMsg = res.message;
            this.showDuplicateNumberMsg = true;
            setTimeout(() => {
              this.funArrowMobile();
              this.enableEmail();
            }, 5000);
            this.toastr.success(this.duplicateNumberMsg);
          } else {
            this.toastr.error(res.message);
          }
        }, error => {
          if (!error.error) {
            this.toastr.error('Something went wrong.');
          } else {
            this.toastr.error(error.error.message);
          }
        })
      } else {
        this.toastr.error('Please enter Mobile Number first.');
      }
    } else {
      this.homeService.sendEmailOtp(this.loginDataObj.email).subscribe((res: any) => {
        if (res && res.responseCode == 200) {
          this.otpForm = true
          this.disableEmail2 = true
          this.disableMobile2 = true
          this.disableResendOTP();
        } else {
          this.toastr.error(res.message);
        }
      }, error => {
        if (!error.error) {
          this.toastr.error('Something went wrong.');
        } else {
          this.toastr.error(error.error.message);
        }
      })
    }
  }

  otpBackBtn() {
    this.otpForm = false
    this.disableEmail2 = false
    this.loginDataObj.otp = null;
  }

  resendOtp() {
    if (this.disableMobile) {
      this.homeService.resendMobileOtp(this.loginDataObj.mobile).subscribe((res: any) => {
        if (res && res.responseCode == 200) {
          this.disableResendOTP();
          this.toastr.success(res.message);
        } else {
          this.toastr.error(res.message);
        }
      }, error => {
        if (error.error.data.otpExpired) {
          this.sharedService.redirectToExpiryPage();
        } else {
          this.toastr.error(error.error.message);
        }
      })
    } else {
      this.homeService.resendEmailOtp(this.loginDataObj.email).subscribe((res: any) => {
        if (res && res.responseCode == 200) {
          this.disableResendOTP();
          this.toastr.success(res.message);
        } else {
          this.toastr.error(res.message);
        }
      }, error => {
        if (error.error.data.otpExpired) {
          this.sharedService.redirectToExpiryPage();
        } else {
          this.toastr.error(error.error.message);
        }
      })
    }
  }

  setLocalDetails(response: any) {
    const token = response['token'];
    this.sharedService.setToken(token);

    const studentId = response['detail']['studentId'];
    this.sharedService.setStudentId(studentId);

    const userId = response['detail']['userId'];
    this.sharedService.setUserId(userId);
  }

  verifyOtp() {
    if (this.disableMobile) {
      this.homeService.verifyMobileOtp(this.loginDataObj.mobile, this.loginDataObj.otp).subscribe((res: any) => {
        if (res && res.responseCode == 200) {
          this.setLocalDetails(res.data);
          this.router.navigate(['/sis-form'])
        } else {
          this.toastr.error(res.message);
        }
      }, error => {
        this.toastr.error(error.error.message);
      })
    } else {
      this.homeService.verifyEmailOtp(this.loginDataObj.email, this.loginDataObj.otp).subscribe((res: any) => {
        if (res && res.responseCode == 200) {
          this.setLocalDetails(res.data);
          this.router.navigate(['/sis-form'])
        } else {
          this.toastr.error(res.message);
        }
      }, error => {
        this.toastr.error(error.error.message);
      })
    }
  }

  ngOnDestroy() {
    clearInterval(this.timer1);
  }
}