import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { SharedService } from '../../shared/shared.service';
import { SisService } from '../sis.service';
import { debounceTime, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { SisPersonalDetailsDataSet } from '../sis-models/sis-personal-details-data-set';

@Component({
  selector: 'app-sis-personal-details-two',
  templateUrl: './sis-personal-details-two.component.html',
  styleUrls: ['./sis-personal-details-two.component.scss']
})
export class SisPersonalDetailsTwoComponent {
  public sisForm: FormGroup;
  countriesList: any = [];
  statesListForPermanent: any = [];
  citiesListForPermanent: any = [];
  statesListForCurrent: any = [];
  citiesListForCurrent: any = [];
  isLgScreen: boolean = false;
  studentId: number;
  userId: number;
  sectionIndex: number = 2;

  parmanentStateNotAvail: boolean = false;
  currentStateNotAvail: boolean = false;

  protected _onDestroy = new Subject<void>();

  public permanentCountryFilterCtrl: FormControl = new FormControl();
  permanentCountryFilteredList: Array<any> = [];

  public permanentStateFilterCtrl: FormControl = new FormControl();
  permanentStateFilteredList: Array<any> = [];

  public permanentCityFilterCtrl: FormControl = new FormControl();
  permanentCityFilteredList: Array<any> = [];

  public currentCountryFilterCtrl: FormControl = new FormControl();
  currentCountryFilteredList: Array<any> = [];

  public currentStateFilterCtrl: FormControl = new FormControl();
  currentStateFilteredList: Array<any> = [];

  public currentCityFilterCtrl: FormControl = new FormControl();
  currentCityFilteredList: Array<any> = [];

  constructor(private router: Router, private fb: FormBuilder, private toastr: ToastrService,
    private activate: ActivatedRoute, private sharedService: SharedService, private sisService: SisService) {
    this.initializeForm();
  }

  ngOnInit(): void {
    this.studentId = this.sharedService.getStudentId();
    this.userId = this.sharedService.getUserId();
    this.isLgScreen = this.sharedService.isLgScreen();
    this.getCountriesList();
    this.getPersonalDetails();
    this.sharedService.scrollToTop();

    ///////// Auto Save Form //////////
    this.sisForm.valueChanges.pipe(debounceTime(1_000)).subscribe(x => {
      console.log('save personal 2 list');
      this.saveData();
    })
    ///////// Auto Save Form //////////

    /////////// To search Permanent Country /////////
    this.permanentCountryFilterCtrl.valueChanges
      .pipe(takeUntil(this._onDestroy))
      .subscribe(() => {
        this.filterPermanentCountry();
      });
    /////////// To search Permanent Country //////////

    /////////// To search Permanent State ////////////
    this.permanentStateFilterCtrl.valueChanges
      .pipe(takeUntil(this._onDestroy))
      .subscribe(() => {
        this.filterPermanentState();
      });
    /////////// To search Permanent State ///////////

    /////////// To search Permanent City ////////////
    this.permanentCityFilterCtrl.valueChanges
      .pipe(takeUntil(this._onDestroy))
      .subscribe(() => {
        this.filterPermanentCity();
      });
    /////////// To search Permanent City /////////////

    /////////// To search Current Country /////////
    this.currentCountryFilterCtrl.valueChanges
      .pipe(takeUntil(this._onDestroy))
      .subscribe(() => {
        this.filterCurrentCountry();
      });
    /////////// To search Current Country //////////

    /////////// To search Current State ////////////
    this.currentStateFilterCtrl.valueChanges
      .pipe(takeUntil(this._onDestroy))
      .subscribe(() => {
        this.filterCurrentState();
      });
    /////////// To search Current State ///////////

    /////////// To search Current City ////////////
    this.currentCityFilterCtrl.valueChanges
      .pipe(takeUntil(this._onDestroy))
      .subscribe(() => {
        this.filterCurrentCity();
      });
    /////////// To search Current City /////////////
  }

  ngOnDestroy() {
    this._onDestroy.next();
    this._onDestroy.complete();
  }

  initializeForm() {
    this.sisForm = this.fb.group({
      PermanentCountryId: ['', [Validators.required]],
      PermanentpostCode: ['', [Validators.required, Validators.maxLength(15)]],
      PermanentaddressLine1: ['', [Validators.required, Validators.maxLength(200)]],
      PermanentaddressLine2: ['', [Validators.maxLength(200)]],
      Permanentstate: ['', [Validators.required]],
      Permanentcity: ['', [Validators.required]],
      CurrentCountryId: ['', [Validators.required]],
      PostCode: ['', [Validators.required, Validators.maxLength(15)]],
      AddressLine1: ['', [Validators.required, Validators.maxLength(200)]],
      AddressLine2: ['', [Validators.maxLength(200)]],
      StateOfResidenceId: ['', [Validators.required]],
      CityOfResidenceId: ['', [Validators.required]],
      SameAsPermanentAddress: [false]
    });

  }

  submitData() {
    this.toastr.success("Record Successfully Saved")

  }
  preventSpace(event: KeyboardEvent) {
    if (event.code === "Space") {
      event.preventDefault();
    }
  }

  saveData() {
    const obj: any = JSON.parse(this.sisService.getDefaultValuesPersonalDetailsFromLocal());
    let dataObj: SisPersonalDetailsDataSet = { ...obj };

    dataObj.PermanentCountryId = this.sisForm.value.PermanentCountryId ? this.sisForm.value.PermanentCountryId : '';
    dataObj.PermanentpostCode = this.sisForm.value.PermanentpostCode ? this.sisForm.value.PermanentpostCode : '';
    dataObj.PermanentaddressLine1 = this.sisForm.value.PermanentaddressLine1 ? this.sisForm.value.PermanentaddressLine1 : '';
    dataObj.PermanentaddressLine2 = this.sisForm.value.PermanentaddressLine2 ? this.sisForm.value.PermanentaddressLine2 : '';
    dataObj.Permanentstate = this.sisForm.value.Permanentstate ? this.sisForm.value.Permanentstate : '';
    dataObj.Permanentcity = this.sisForm.value.Permanentcity ? this.sisForm.value.Permanentcity : '';
    dataObj.CurrentCountryId = this.sisForm.value.CurrentCountryId ? this.sisForm.value.CurrentCountryId : '';
    dataObj.PostCode = this.sisForm.value.PostCode ? this.sisForm.value.PostCode : '';
    dataObj.AddressLine1 = this.sisForm.value.AddressLine1 ? this.sisForm.value.AddressLine1 : '';
    dataObj.AddressLine2 = this.sisForm.value.AddressLine2 ? this.sisForm.value.AddressLine2 : '';
    dataObj.StateOfResidenceId = this.sisForm.value.StateOfResidenceId ? this.sisForm.value.StateOfResidenceId : '';
    dataObj.CityOfResidenceId = this.sisForm.value.CityOfResidenceId ? this.sisForm.value.CityOfResidenceId : '';
    dataObj.SameAsPermanentAddress = this.sisForm.value.SameAsPermanentAddress ? this.sisForm.value.SameAsPermanentAddress : false;

    this.sisService.setDefaultValuesPersonalDetailsAtLocal(dataObj);

    this.sisService.saveSISData(this.sectionIndex).subscribe(() => {
      this.setPermission();
      console.log('data saved');
    })
  }

  saveAndNext() {
    this.sisService.submitSIS(this.studentId, this.sectionIndex).subscribe((res: any) => {
      if (res) {
        this.router.navigate(['/sis-personal-details-three']);
      }
    }, error => {
      this.toastr.error("Something went wrong", error);
    });
  }

  getCountriesList() {
    if (this.sisService.getCountriesListFromLocalStorage()) {
      this.countriesList = this.sisService.getCountriesListFromLocalStorage();
      this.filterPermanentCountry();
      this.filterCurrentCountry();
    } else {
      this.sisService.getCountriesList().subscribe((res) => {
        let response: any = res;
        if (response && response.data) {
          this.countriesList = response.data.items;
          this.sisService.setCountriesListInLocalStorage(this.countriesList);
          this.filterPermanentCountry();
          this.filterCurrentCountry();
        }
      })
    }
  }

  getStatesListCountryWise(event: any, callFromPermanent: boolean) {
    callFromPermanent ?this.parmanentStateNotAvail = false : this.currentStateNotAvail = false;
    if (event) {
      this.sisService.getStatesCountryWise(event).subscribe(res => {
        let response: any = res;
        
        if (response && response.data) {
          if (callFromPermanent) {           
            if(response.data.items.length==0){
              this.sisForm.controls['Permanentstate'].setValue(parseInt(event));
              this.sisForm.controls['Permanentcity'].setValue(parseInt(event));
              this.parmanentStateNotAvail = true; 
            }
            this.statesListForPermanent = response.data.items;
            this.filterPermanentState();
          } else {
            this.currentStateNotAvail = false;
            if(response.data.items.length==0){
              this.sisForm.controls['StateOfResidenceId'].setValue(parseInt(event));
              this.sisForm.controls['CityOfResidenceId'].setValue(parseInt(event));
              this.currentStateNotAvail = true;
            }
            this.statesListForCurrent = response.data.items;
            this.filterCurrentState();
          }
        }
      })
    }
  }

  getCitiesListStateWise(event: any, callFromPermanent: boolean) {
    if (event) {
      this.sisService.getCitiesStateWise(event).subscribe(res => {
        let response: any = res;
        if (response && response.data) {
          if (callFromPermanent) {
            this.citiesListForPermanent = response.data.items;
            this.filterPermanentCity();
          } else {
            this.citiesListForCurrent = response.data.items;
            this.filterCurrentCity();
          }
        }
      })
    }
  }

  getPersonalDetails() {
    this.sisService.getSISPersonalDetails(this.studentId, this.userId).subscribe((res: any) => {
      if (res) {
        let response: any = JSON.parse(res.data);
        this.sisForm.controls['PermanentCountryId'].setValue(parseInt(response.PermanentCountryId));
        this.sisForm.controls['PermanentpostCode'].setValue(response.PermanentpostCode);
        this.sisForm.controls['PermanentaddressLine1'].setValue(response.PermanentaddressLine1);
        this.sisForm.controls['PermanentaddressLine2'].setValue(response.PermanentaddressLine2);
        this.sisForm.controls['Permanentstate'].setValue(parseInt(response.Permanentstate));
        this.sisForm.controls['Permanentcity'].setValue(parseInt(response.Permanentcity));

        this.sisForm.controls['SameAsPermanentAddress'].setValue(response.SameAsPermanentAddress);

        this.sisForm.controls['CurrentCountryId'].setValue(parseInt(response.CurrentCountryId));
        this.sisForm.controls['PostCode'].setValue(response.PostCode);
        this.sisForm.controls['AddressLine1'].setValue(response.AddressLine1);
        this.sisForm.controls['AddressLine2'].setValue(response.AddressLine2);
        this.sisForm.controls['StateOfResidenceId'].setValue(parseInt(response.StateOfResidenceId));
        this.sisForm.controls['CityOfResidenceId'].setValue(parseInt(response.CityOfResidenceId));
      }
    })
  }

  setCurrentAddressAsPermanent(event: any) {
    if (event.target.checked) {
      this.sisForm.controls['CurrentCountryId'].setValue(parseInt(this.sisForm.controls['PermanentCountryId']['value']));
      this.sisForm.controls['PostCode'].setValue(this.sisForm.controls['PermanentpostCode']['value']);
      this.sisForm.controls['AddressLine1'].setValue(this.sisForm.controls['PermanentaddressLine1']['value']);
      this.sisForm.controls['AddressLine2'].setValue(this.sisForm.controls['PermanentaddressLine2']['value']);
      this.sisForm.controls['StateOfResidenceId'].setValue(parseInt(this.sisForm.controls['Permanentstate']['value']));
      this.sisForm.controls['CityOfResidenceId'].setValue(parseInt(this.sisForm.controls['Permanentcity']['value']));
    }
  }

  setPermission() {
    if (this.sisForm.valid) {
      this.sisService.removePendingItems(this.sectionIndex);
      this.sisService.setSectionWisePermission('personalDetails2Perm', true);
    } else {
      this.sisService.setPendingItems('Personal Details Address', this.sectionIndex, this.sisService.returnInvalidControls(this.sisForm))
      this.sisService.setSectionWisePermission('personalDetails2Perm', false);
    }
    this.sisService.emitSubmitPermissionChangeAlert('true');
  }

  filterPermanentCountry() {
    let search = this.permanentCountryFilterCtrl.value;
    search = search ? search : '';
    this.permanentCountryFilteredList = this.countriesList.filter((x: any) => x?.name?.toLowerCase().startsWith(search.toLowerCase()));
  }

  filterPermanentState() {
    let search = this.permanentStateFilterCtrl.value;
    search = search ? search : '';
    this.permanentStateFilteredList = this.statesListForPermanent.filter((x: any) => x?.name?.toLowerCase().startsWith(search.toLowerCase()));
  }

  filterPermanentCity() {
    let search = this.permanentCityFilterCtrl.value;
    search = search ? search : '';
    this.permanentCityFilteredList = this.citiesListForPermanent.filter((x: any) => x?.name?.toLowerCase().startsWith(search.toLowerCase()));
  }

  filterCurrentCountry() {
    let search = this.currentCountryFilterCtrl.value;
    search = search ? search : '';
    this.currentCountryFilteredList = this.countriesList.filter((x: any) => x?.name?.toLowerCase().startsWith(search.toLowerCase()));
  }

  filterCurrentState() {
    let search = this.currentStateFilterCtrl.value;
    search = search ? search : '';
    this.currentStateFilteredList = this.statesListForCurrent.filter((x: any) => x?.name?.toLowerCase().startsWith(search.toLowerCase()));
  }

  filterCurrentCity() {
    let search = this.currentCityFilterCtrl.value;
    search = search ? search : '';
    this.currentCityFilteredList = this.citiesListForCurrent.filter((x: any) => x?.name?.toLowerCase().startsWith(search.toLowerCase()));
  }
}