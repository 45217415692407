import { Component } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, FormGroupDirective, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { SharedService } from '../../shared/shared.service';
import { SisService } from '../sis.service';
import { Observable, Subject, takeUntil } from 'rxjs';
import { MatTableDataSource } from '@angular/material/table';
import { MatDialog } from '@angular/material/dialog';
import { ErrorComponent } from '../../shared/error/error.component';

@Component({
  selector: 'app-sis-visa-rejection',
  templateUrl: './sis-visa-rejection.component.html',
  styleUrls: ['./sis-visa-rejection.component.scss']
})
export class SisVisaRejectionComponent {
  public sisFormImmigrationVisa: FormGroup;
  countriesList: any = [];
  refusalTypesList: any = [];
  visaTypesList: any = [];
  isLgScreen: boolean = false;
  visaRejectionData: any = {};
  studentId: number;
  userId: number;
  today: Date = new Date();
  immigrationHistoryForm: FormGroup;
  immigrationHistory: any;
  immigrationHistoryData: Array<any>;
  formsList: Observable<any>;
  _immigrationHistoryData: any = [];
  immigrationHistoryFormTable: any;
  visaRejectionExist: any = null;
  dataSource = new MatTableDataSource<any>();
  hideSaveBut: boolean = false;
  clickedSave: boolean = false;
  showAddForm: boolean = false;
  disableSaveNextBtn: boolean = false;
  sectionIndex: number = 6;

  protected _onDestroy = new Subject<void>();

  public countryFilterCtrl: FormControl = new FormControl();
  countryFilteredList: Array<any> = [];

  public editCountryFilterCtrl: FormControl = new FormControl();
  editCountryFilteredList: Array<any> = [];

  manualSave: boolean;

  constructor(private router: Router, private toastr: ToastrService,
    private activate: ActivatedRoute, private sharedService: SharedService, private sisService: SisService,
    private dialog: MatDialog,
    private formBuilder: FormBuilder) { }

  ngOnInit(): void {
    this.studentId = this.sharedService.getStudentId();
    this.userId = this.sharedService.getUserId();
    this.isLgScreen = this.sharedService.isLgScreen();
    this.manualSave = false;
    this.getCountriesList();
    this.getRefusalTypeList();
    this.getVisaTypeList();
    this.getVisaRejectionDetails();
    this.createImmigrationHistoryForm();
    this.initializeForm();
    this.sharedService.scrollToTop();

    /////////// To search Country /////////
    this.countryFilterCtrl.valueChanges
      .pipe(takeUntil(this._onDestroy))
      .subscribe(() => {
        this.filterCountry();
      });
    /////////// To search Country //////////

    /////////// To search Edit Country /////////
    this.editCountryFilterCtrl.valueChanges
      .pipe(takeUntil(this._onDestroy))
      .subscribe(() => {
        this.filterEditCountry();
      });
    /////////// To search Edit Country //////////
  }

  ngOnDestroy() {
    this._onDestroy.next();
    this._onDestroy.complete();
  }

  initializeForm() {
    this.immigrationHistoryFormTable = this.formBuilder.group({
      immigrationHistory: this.formBuilder.array([]),
    });
  }

  submitData() {
    this.toastr.success("Record Successfully Saved");
  }

  preventSpace(event: KeyboardEvent) {
    if (event.code === "Space") {
      event.preventDefault();
    }
  }

  saveData() {
    let dataObj = {
      IsVisaRejection: this.visaRejectionExist.toString(),
      visarejectionhistory: this.visaRejectionData.visarejectionhistory
    }
    this.sisService.setSISVisaRejectionHistoryAtLocal(dataObj);
    this.setPermission();
    if (this.router.url === '/sis-visa-rejection' || this.manualSave) {
      this.sisService.saveSISData(this.sectionIndex).subscribe(() => {
        console.log('data saved visa');
      })
    }
  }

  saveAndNext() {
    this.sisService.submitSIS(this.studentId, this.sectionIndex).subscribe((res: any) => {
      if (res) {
        this.router.navigate(['/sis-education-details'])
      }
    }, error => {
      this.toastr.error("Something went wrong", error);
    });
  }

  getCountriesList() {
    if (this.sisService.getCountriesListFromLocalStorage()) {
      this.countriesList = this.sisService.getCountriesListFromLocalStorage();
      this.filterCountry();
      this.filterEditCountry();
    } else {
      this.sisService.getCountriesList().subscribe((res) => {
        let response: any = res;
        if (response && response.data) {
          this.countriesList = response.data.items;
          this.sisService.setCountriesListInLocalStorage(this.countriesList);
          this.filterCountry();
          this.filterEditCountry();
        }
      })
    }
  }

  getRefusalTypeList() {
    this.sisService.getRefusalTypesList().subscribe(res => {
      let response: any = res;
      if (response && response.data) {
        this.refusalTypesList = response.data.items;
      }
    })
  }

  getVisaTypeList() {
    if (this.sisService.getVisaTypesListFromLocalStorage()) {
      this.visaTypesList = this.sisService.getVisaTypesListFromLocalStorage();
    } else {
      this.sisService.getVisaTypesList().subscribe(res => {
        let response: any = res;
        if (response && response.data) {
          this.visaTypesList = response.data.items;
          this.sisService.setVisaTypesListInLocalStorage(this.visaTypesList);
        }
      })
    }
  }

  getVisaRejectionDetails() {
    this.sisService.getSISVisaRejectionData(this.studentId).subscribe((res: any) => {
      if (res) {
        let response: any = JSON.parse(res.data);
        this.visaRejectionData = response;
        this.visaRejectionExist = parseInt(response.IsVisaRejection);
        this.visaRejectionData.visarejectionhistory.forEach(
          (item: any) => (item.isEditable = false)
        );
        this.immigrationHistoryData = this.visaRejectionData.visarejectionhistory;
        this._immigrationHistoryData = [...this.immigrationHistoryData];
        this.dataSource.data = this._immigrationHistoryData;
        this.saveData();
        this.createFormGroups();
      }
    })
  }

  addImmigrationHistory(
    immigrationHistoryForm: FormGroup,
    formDirective: FormGroupDirective,
    clickedSave: any
  ) {
    if (!immigrationHistoryForm.valid) {
      return;
    }
    this.showAddForm = clickedSave;
    let getVisaRefuselName = '';
    for (const row of this.refusalTypesList) {
      if (immigrationHistoryForm.value.visaRefusedId === row.id) {
        getVisaRefuselName = row.name;
        break;
      }
    }
    let immigrationDataObj: any = {};
    immigrationDataObj = immigrationHistoryForm.value;
    immigrationDataObj.problemDate = this.sharedService.formatDate(immigrationDataObj.problemDate);
    this._immigrationHistoryData.push({
      id: 0,
      isEditable: false,
      ...immigrationDataObj,
      visaRefusedName: getVisaRefuselName,
      studentId: this.studentId
    });

    immigrationHistoryForm.reset();
    this.hideSaveBut = true;
    formDirective.resetForm();
    this.dataSource.data = this._immigrationHistoryData;
    this.visaRejectionData.visarejectionhistory = this._immigrationHistoryData;
    this.createFormGroups();
    this.createImmigrationHistoryForm();
    setTimeout(() => {
      this.manualSave = true;
      this.saveData();
    }, 500);
  }

  cancelAddImmigrationHistory(formDirective: FormGroupDirective) {
    this.immigrationHistoryForm.reset();
    formDirective.resetForm();
    this.showAddForm = false;
    this.hideSaveBut = false;
  }

  checkVisaAndCountries(row: any) {
    const getCountry = this.countriesList.filter(
      (item: any) => row.country === item.name
    );
    const getVisaType = this.visaTypesList.filter(
      (item: any) => row.visaType === item.name
    );
    row.visaType = getVisaType.length ? getVisaType[0].name : '';
    row.country = getCountry.length ? getCountry[0].name : '';
  }

  removeVisaRejectionHistory(index: number) {
    this._immigrationHistoryData.splice(index, 1);
    this.visaRejectionData.visarejectionhistory = this._immigrationHistoryData;
    this.createFormGroups();
    this.createImmigrationHistoryForm();
    setTimeout(() => {
      this.manualSave = true;
      this.saveData();
    }, 500);
  }

  // CREATE FORMGROUP FOR EVERY ROW
  createFormGroups() {
    this.immigrationHistoryFormTable = this.formBuilder.group({
      immigrationHistory: this.formBuilder.array(
        this._immigrationHistoryData.map((formControl: any) => {
          return this.formBuilder.group({
            visaRefusedName: [formControl.visaRefusedId, [Validators.required]],
            country: [formControl.country, [Validators.required]],
            problemDate: [formControl.problemDate, [Validators.required]],
            visaType: [formControl.visaType, [Validators.required]],
            details: [formControl.details],
            id: [formControl.id],
          });
        })
      ),
    });
    this.immigrationHistoryFormTable.controls.immigrationHistory.disable();
    this.dataSource = new MatTableDataSource<any>(
      this.immigrationHistoryFormTable.controls.immigrationHistory.controls
    );
    this.formsList = this.dataSource.connect();
  }

  // CREATE FORM TO ADD NEW RECORD
  createImmigrationHistoryForm() {
    this.immigrationHistoryForm = new FormGroup({
      visaRefusedId: new FormControl('', [Validators.required]),
      country: new FormControl('', [Validators.required]),
      problemDate: new FormControl('', Validators.required),
      visaType: new FormControl('', [Validators.required]),
      details: new FormControl(''),
      id: new FormControl(0),
    });
  }

  submitVisaRejection(immigrationHistoryForm: any, formDirective: any, clickedSave: any) {
    this.addImmigrationHistory(immigrationHistoryForm, formDirective, clickedSave);
  }

  editImmigrationHistory(cardFormGroup: FormGroup, $index: number) {
    let getVisaRefuselName = '';
    for (const row of this.refusalTypesList) {
      if (cardFormGroup.get('visaRefusedName').value === row.id) {
        getVisaRefuselName = row.name;
        break;
      }
    }
    const params = {
      visaRefusedName: getVisaRefuselName,
      visaRefusedId: cardFormGroup.get('visaRefusedName').value,
      country: cardFormGroup.get('country').value,
      problemDate: this.sharedService.formatDate(cardFormGroup.get('problemDate').value),
      visaType: cardFormGroup.get('visaType').value,
      details: cardFormGroup.get('details').value,
      id: this._immigrationHistoryData[$index].id,
      isEditable: this._immigrationHistoryData[$index].isEditable,
      studentId: this._immigrationHistoryData[$index].studentId,
    };

    this._immigrationHistoryData[this._immigrationHistoryData.map((x: any, i: any) => [i, x]).filter(
      (x: any) => x[1]['id'] == this._immigrationHistoryData[$index].id)[0][0]] = params

    this.visaRejectionData.visarejectionhistory = this._immigrationHistoryData;
    cardFormGroup.disable();
    setTimeout(() => {
      this.manualSave = true;
      this.saveData();
    }, 500);
  }

  checkDisableClass(cardFormGroup: any) {
    return this.sharedService.setDisableClass(cardFormGroup);
  }

  checkDataList() {
    if (this._immigrationHistoryData.length > 0) {
      const dialogRef = this.dialog.open(ErrorComponent, {
        data: { dialogText: 'Please remove all visa rejection entries to select the option.' }
      });
      setTimeout(() => {
        this.visaRejectionExist = 1;
      }, 10)
      return 0;
    } else {
      setTimeout(() => {
        this.manualSave = true;
        this.saveData();
      }, 10)
    }
  }

  setPermission() {
    setTimeout(() => {
      if (this.visaRejectionExist == 0 || (this.visaRejectionExist == 1 && this._immigrationHistoryData.length > 0)) {
        this.sisService.removePendingItems(this.sectionIndex);
        this.sisService.setSectionWisePermission('visaRejectionsPerm', true);
        this.disableSaveNextBtn = false;
      } else {
        this.sisService.setPendingItems('Visa Rejection', this.sectionIndex);
        this.sisService.setSectionWisePermission('visaRejectionsPerm', false);
        this.disableSaveNextBtn = true;
      }
      this.sisService.emitSubmitPermissionChangeAlert('true');
    }, 100);
  }

  filterCountry() {
    let search = this.countryFilterCtrl.value;
    search = search ? search : '';
    this.countryFilteredList = this.countriesList.filter((x: any) => x?.name?.toLowerCase().startsWith(search.toLowerCase()));
  }

  filterEditCountry() {
    let search = this.editCountryFilterCtrl.value;
    search = search ? search : '';
    this.editCountryFilteredList = this.countriesList.filter((x: any) => x?.name?.toLowerCase().startsWith(search.toLowerCase()));
  }
}
