<app-sis-header *ngIf="!isLgScreen" class="sis-header"></app-sis-header>
<div id="personalDetailsPageOne" class="container" [ngClass]="!isLgScreen? 'mt-6':''">

    <div fxLayoutGap="12px" class="mb-2 text-left" *ngIf="isLgScreen">
        <h3>Visa Rejection<span class="error"> *</span></h3>

        <p>For any country have you ever been refused permission to stay or remain, refused asylum or deported? <span
                class="error">*</span></p>
        <div>
            <mat-radio-group aria-label="Select an option" [(ngModel)]="visaRejectionExist">
                <mat-radio-button [value]="1" (click)="setPermission()" color="primary"><span
                        style="font-weight: bold;">Yes</span></mat-radio-button>
                <mat-radio-button [value]="0" (click)="checkDataList()" color="primary"><span
                        style="font-weight: bold;">No</span></mat-radio-button>
            </mat-radio-group>
        </div>
    </div>

    <fieldset fxLayoutGap="12px" class="mb-2" *ngIf="!isLgScreen">
        <legend>Visa Rejection<span class="error"> *</span></legend>

        <p>For any country have you ever been refused permission to stay or remain, refused asylum or deported? <span
                class="error">*</span></p>
        <div>
            <mat-radio-group aria-label="Select an option" [(ngModel)]="visaRejectionExist">
                <mat-radio-button [value]="1" (click)="setPermission()" color="primary"><span
                        style="font-weight: bold;">Yes</span></mat-radio-button>
                <mat-radio-button [value]="0" (click)="checkDataList()" color="primary"><span
                        style="font-weight: bold;">No</span></mat-radio-button>
            </mat-radio-group>
        </div>
    </fieldset>

    <div *ngIf="_immigrationHistoryData.length" style="margin-top: 10px;"
        [ngClass]="!isLgScreen? 'text-center':'text-left'">
        <form [formGroup]="immigrationHistoryFormTable">
            <ng-container formArrayName="immigrationHistory">
                <div *ngFor="let cardFormGroup of formsList | async;let $index = index" class="custom-card my-5 p-4">
                    <fieldset class="fieldsetArray">
                        <legend *ngIf="$index==0">IF YES, Please fill the fields</legend>
                        <div fxLayout="column"
                            *ngIf="$index==0 && isLgScreen && !showAddForm && visaRejectionExist && _immigrationHistoryData.length>0">
                            <button fxFlexAlign="end" mat-raised-button color="primary"
                                (click)="showAddForm=true;hideSaveBut=true;">
                                Add more
                            </button>
                        </div>
                        <ng-container [formGroup]="cardFormGroup">
                            <div fxLayout="row wrap" [ngClass]="isLgScreen? 'ml-2':''" fxLayout.lt-sm="column"
                                fxLayoutGap.xs="5%" class="mb-2">

                                <div class="inputParent">
                                    <mat-label class="inputLabel" [ngClass]="checkDisableClass(cardFormGroup)">Refusal
                                        type<span class="red">*</span></mat-label>
                                    <mat-form-field appearance="outline" class="input">
                                        <mat-select [(ngModel)]="cardFormGroup.get('visaRefusedName').value"
                                            formControlName="visaRefusedName">
                                            <mat-option value="">Please Select</mat-option>
                                            <mat-option *ngFor="let refuselItem of refusalTypesList"
                                                [value]="refuselItem.id">{{refuselItem.name}}
                                            </mat-option>
                                        </mat-select>
                                        <mat-error *ngIf="cardFormGroup.get('visaRefusedName').hasError('required')">
                                            Refusal Type is <strong>required</strong>
                                        </mat-error>
                                    </mat-form-field>
                                </div>

                                <div class="inputParent">
                                    <mat-label class="inputLabel"
                                        [ngClass]="checkDisableClass(cardFormGroup)">Country<span
                                            class="red">*</span></mat-label>
                                    <mat-form-field appearance="outline" class="input">
                                        <mat-select name="country" formControlName="country"
                                            [(ngModel)]="cardFormGroup.get('country').value"
                                            placeholder="Please Select">
                                            <mat-option>
                                                <ngx-mat-select-search [formControl]="editCountryFilterCtrl"
                                                    placeholderLabel="Search Country..."
                                                    noEntriesFoundLabel="No results found"></ngx-mat-select-search>
                                            </mat-option>
                                            <mat-option *ngFor="let country of editCountryFilteredList"
                                                [value]="country.name">
                                                {{country.name}}
                                            </mat-option>
                                        </mat-select>
                                        <mat-error *ngIf="cardFormGroup.get('country').hasError('required')">
                                            Country Type is <strong>required</strong>
                                        </mat-error>
                                        <mat-error *ngIf="cardFormGroup.get('country').hasError('pattern')">
                                            Country Type is <strong>required</strong>
                                        </mat-error>
                                    </mat-form-field>
                                </div>

                                <div class="inputParent">
                                    <mat-label class="inputLabel" [ngClass]="checkDisableClass(cardFormGroup)">Date of
                                        refusal<span class="red">*</span></mat-label>
                                    <mat-form-field appearance="outline" class="input">
                                        <input matInput [matDatepicker]="problemDate" formControlName="problemDate"
                                        [max]="today" [(ngModel)]="cardFormGroup.get('problemDate').value"
                                            placeholder="Date of Refusal">
                                        <mat-datepicker-toggle matSuffix [for]="problemDate"></mat-datepicker-toggle>
                                        <mat-datepicker #problemDate disabled="false"></mat-datepicker>
                                        <mat-error *ngIf="cardFormGroup.get('problemDate').hasError('required')">
                                            Date of refusal is <strong>required</strong>
                                        </mat-error>
                                    </mat-form-field>
                                </div>

                                <div class="inputParent">
                                    <mat-label class="inputLabel" [ngClass]="checkDisableClass(cardFormGroup)">Visa
                                        type<span class="red">*</span></mat-label>
                                    <mat-form-field appearance="outline" class="input">
                                        <mat-select name="visaType" formControlName="visaType"
                                            [(ngModel)]="cardFormGroup.get('visaType').value">
                                            <mat-option value=""> Please Select</mat-option>
                                            <mat-option [value]="visa.name" *ngFor="let visa of visaTypesList">
                                                {{visa.name}}
                                            </mat-option>
                                        </mat-select>
                                        <mat-error *ngIf="cardFormGroup.get('visaType').hasError('required')">
                                            Visa Type is <strong>required</strong>
                                        </mat-error>
                                        <mat-error *ngIf="cardFormGroup.get('visaType').hasError('pattern')">
                                            Visa Type is <strong>required</strong>
                                        </mat-error>
                                    </mat-form-field>
                                </div>

                                <div class="inputParent">
                                    <mat-label class="inputLabel"
                                        [ngClass]="checkDisableClass(cardFormGroup)">Details</mat-label>
                                    <mat-form-field appearance="outline" class="input">
                                        <textarea type="text" matInput formControlName="details" placeholder="Details"
                                            [(ngModel)]="cardFormGroup.get('details').value"></textarea>
                                    </mat-form-field>
                                </div>
                            </div>
                            <div fxLayout="row" fxLayoutAlign="end center" class="my-2 md:my-0">
                                <ng-container *ngIf="!cardFormGroup.disabled">
                                    <button mat-flat-button color="primary" [disabled]="cardFormGroup.invalid"
                                        class="mr-1"
                                        (click)="editImmigrationHistory(cardFormGroup,$index);">Save</button>
                                    <button mat-flat-button color="warn"
                                        (click)="cardFormGroup.disable();">Cancel</button>
                                </ng-container>
                                <ng-container *ngIf="cardFormGroup.disabled">
                                    <button mat-flat-button color="primary" class="mr-1"
                                        (click)="cardFormGroup.enable();">Edit</button>
                                    <button mat-flat-button color="warn" (click)="removeVisaRejectionHistory($index)">
                                        Delete
                                    </button>
                                </ng-container>
                            </div>
                        </ng-container>
                    </fieldset>
                </div>
            </ng-container>
        </form>
    </div>

    <fieldset *ngIf="visaRejectionExist==1 && (dataSource.data.length == 0 || showAddForm)"
        [ngClass]="!isLgScreen? 'text-center':'text-left'" class="fieldsetArray">
        <legend *ngIf="dataSource.data.length==0">IF YES, Please fill the fields</legend>
        <form [formGroup]="immigrationHistoryForm" novalidate
            (ngSubmit)="clickedSave = false;submitVisaRejection(immigrationHistoryForm,formDirective,clickedSave);"
            #formDirective="ngForm" class="mt-3">
            <div fxLayoutGap="10px" style="margin-top: 8px;" [ngClass]="isLgScreen? 'ml-2':''">

                <div class="inputParent">
                    <mat-label class="inputLabel">Refusal type<span class="red">*</span></mat-label>
                    <mat-form-field appearance="outline" class="input">
                        <mat-select name="Refusal-Type" formControlName="visaRefusedId">
                            <mat-option value="">
                                Please Select
                            </mat-option>
                            <mat-option *ngFor="let refuselItem of refusalTypesList" [value]="refuselItem.id">
                                {{refuselItem.name}}
                            </mat-option>
                        </mat-select>
                        <mat-error
                            *ngIf="immigrationHistoryForm.controls['visaRefusedId'].touched && immigrationHistoryForm.get('visaRefusedId').hasError('required')">
                            Refusal type is <strong>required</strong>
                        </mat-error>
                    </mat-form-field>
                </div>

                <div class="inputParent">
                    <mat-label class="inputLabel">Country<span class="red">*</span></mat-label>
                    <mat-form-field appearance="outline" class="input">
                        <mat-select name="country" formControlName="country" placeholder="Please Select">
                            <mat-option>
                                <ngx-mat-select-search [formControl]="countryFilterCtrl"
                                    placeholderLabel="Search Country..."
                                    noEntriesFoundLabel="No results found"></ngx-mat-select-search>
                            </mat-option>
                            <mat-option *ngFor="let country of countryFilteredList" [value]="country.name">
                                {{country.name}}
                            </mat-option>
                        </mat-select>
                        <mat-error
                            *ngIf="immigrationHistoryForm.controls['country'].touched && immigrationHistoryForm.get('country').hasError('required')">
                            Country is <strong>required</strong>
                        </mat-error>
                    </mat-form-field>
                </div>

                <div class="inputParent">
                    <mat-label class="inputLabel">Date of Refusal<span class="red">*</span></mat-label>
                    <mat-form-field appearance="outline" class="input">
                        <input matInput [matDatepicker]="problemDate" formControlName="problemDate"
                        [max]="today" placeholder="Date of Refusal">
                        <mat-datepicker-toggle matSuffix [for]="problemDate"></mat-datepicker-toggle>
                        <mat-datepicker #problemDate disabled="false"></mat-datepicker>
                        <mat-error
                            *ngIf="immigrationHistoryForm.controls['problemDate'].touched && immigrationHistoryForm.get('problemDate').hasError('required')">
                            Date of refusal is <strong>required</strong>
                        </mat-error>
                    </mat-form-field>
                </div>

                <div class="inputParent">
                    <mat-label class="inputLabel">Visa type<span class="red">*</span></mat-label>
                    <mat-form-field appearance="outline" class="input">
                        <mat-select name="visaType" formControlName="visaType">
                            <mat-option value=""> Please Select</mat-option>
                            <mat-option [value]="visa.name" *ngFor="let visa of visaTypesList">{{visa.name}}
                            </mat-option>
                        </mat-select>
                        <mat-error
                            *ngIf="immigrationHistoryForm.controls['visaType'].touched && immigrationHistoryForm.get('visaType').hasError('required')">
                            Visa type is <strong>required</strong>
                        </mat-error>
                    </mat-form-field>
                </div>

                <div class="inputParent">
                    <mat-label class="inputLabel">Details</mat-label>
                    <mat-form-field appearance="outline" class="input">
                        <textarea matInput formControlName="details" cdkTextareaAutosize cdkAutosizeMinRows="5"
                            cdkAutosizeMaxRows="20" placeholder="Details"></textarea>
                    </mat-form-field>
                </div>
            </div>
            <div fxLayoutAlign="end">
                <button mat-raised-button color="primary" class="mr-1" type="submit"
                    [disabled]="immigrationHistoryForm.invalid">Add
                </button>
                <button mat-flat-button color="warn" (click)="cancelAddImmigrationHistory(formDirective)">
                    Cancel
                </button>
            </div>
        </form>
    </fieldset>

    <div class="addMoreBtnSection" (click)="showAddForm=true;hideSaveBut=true;" *ngIf="!isLgScreen">
        <mat-icon class="icon-display">add_circle_outline</mat-icon>
        <h4>Add More</h4>
    </div>

    <div class="mb-1 mt-1" *ngIf="!isLgScreen">
        <button mat-raised-button color="primary" [ngClass]="disableSaveNextBtn?'disabledBtn':'enabledBtn'"
            [disabled]="disableSaveNextBtn" class="saveNextBtn" (click)="saveAndNext()" type="button">Save &
            Next</button>
    </div>
</div>