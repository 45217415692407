<app-sis-header *ngIf="!isLgScreen" class="sis-header"></app-sis-header>
<div id="personalDetailsPageOne" class="container"  [ngClass]="!isLgScreen? 'mt-6':''">

    <div class="mb-2 text-left" *ngIf="isLgScreen">
        <h3>Immigration History<span class="error"> *</span></h3>

        <p>Do you need a visa to stay in any of the following countries ? Please tick all that apply.</p>

        <section id="checkboxSection">
            <ng-container *ngFor="let country of immigrationCountriesListStudentDesires;let i=index">
                <mat-checkbox class="immigration-history__title  ml-4" id="immigration{{country.id}}"
                    [value]="country.id" [checked]="country.checked"
                    (change)="checkImmigrationHistory($event)" color="primary">{{country.Name}}</mat-checkbox>
            </ng-container>
        </section>
    </div>

    <fieldset *ngIf="!isLgScreen" fxLayoutGap="12px" class="mb-2">
        <legend>Immigration History<span class="error"> *</span></legend>

        <p>Do you need a visa to stay in any of the following countries ? Please tick all that apply.</p>

        <section id="checkboxSection" [ngClass]="!isLgScreen? 'vertical-display':'horizontal-display'">
            <ng-container *ngFor="let country of immigrationCountriesListStudentDesires;let i=index">
                <mat-checkbox class="immigration-history__title  ml-4" id="immigration{{country.id}}"
                    style="margin-right: 1rem;margin-left: 1rem;" [value]="country.id" [checked]="country.checked"
                    (change)="checkImmigrationHistory($event)" color="primary">{{country.Name}}</mat-checkbox>
            </ng-container>
        </section>
    </fieldset>

    <div class="mb-1" *ngIf="!isLgScreen">
        <button mat-raised-button color="primary" [ngClass]="arr_countryCheckedValue.length==0?'disabledBtn':'enabledBtn'" [disabled]="arr_countryCheckedValue.length==0" class="saveNextBtn" (click)="saveAndNext()" type="button">Save &
            Next</button>
    </div>
</div>