import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { SisDeclarationComponent } from './sis-declaration/sis-declaration.component';
import { SisDocumentsComponent } from './sis-documents/sis-documents.component';
import { SisEducationDetailsComponent } from './sis-education-details/sis-education-details.component';
import { SisEnglishLanguageExamComponent } from './sis-english-language-exam/sis-english-language-exam.component';
import { SisFormComponent } from './sis-form/sis-form.component';
import { SisImmigrationHistoryComponent } from './sis-immigration-history/sis-immigration-history.component';
import { SisOtherExamsComponent } from './sis-other-exams/sis-other-exams.component';
import { SisPersonalDetailsOneComponent } from './sis-personal-details-one/sis-personal-details-one.component';
import { SisPersonalDetailsThreeComponent } from './sis-personal-details-three/sis-personal-details-three.component';
import { SisPersonalDetailsTwoComponent } from './sis-personal-details-two/sis-personal-details-two.component';
import { SisReferenceComponent } from './sis-reference/sis-reference.component';
import { SisTravelHistoryComponent } from './sis-travel-history/sis-travel-history.component';
import { SisVisaRejectionComponent } from './sis-visa-rejection/sis-visa-rejection.component';
import { SisWorkExperienceComponent } from './sis-work-experience/sis-work-experience.component';
import { AuthGuard } from 'src/app/guards/auth.guard';

const routes: Routes = [
  { path: 'sis-form', component: SisFormComponent,canActivate: [AuthGuard] },
  { path: 'sis-personal-details-one', component: SisPersonalDetailsOneComponent,canActivate: [AuthGuard] },
  { path: 'sis-personal-details-two', component: SisPersonalDetailsTwoComponent,canActivate: [AuthGuard] },
  { path: 'sis-personal-details-three', component: SisPersonalDetailsThreeComponent,canActivate: [AuthGuard] },
  { path: 'sis-travel-history', component: SisTravelHistoryComponent,canActivate: [AuthGuard] },
  { path: 'sis-immigration-history', component: SisImmigrationHistoryComponent,canActivate: [AuthGuard] },
  { path: 'sis-visa-rejection', component: SisVisaRejectionComponent,canActivate: [AuthGuard] },
  { path: 'sis-education-details', component: SisEducationDetailsComponent,canActivate: [AuthGuard] },
  { path: 'sis-english-language-exam', component: SisEnglishLanguageExamComponent,canActivate: [AuthGuard] },
  { path: 'sis-other-exams', component: SisOtherExamsComponent,canActivate: [AuthGuard] },
  { path: 'sis-documents', component: SisDocumentsComponent,canActivate: [AuthGuard] },
  { path: 'sis-work-experience', component: SisWorkExperienceComponent,canActivate: [AuthGuard] },
  { path: 'sis-reference', component: SisReferenceComponent,canActivate: [AuthGuard] },
  { path: 'sis-declaration', component: SisDeclarationComponent,canActivate: [AuthGuard] }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class PostAuthRoutingModule { }
