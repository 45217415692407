<app-sis-header *ngIf="!isLgScreen" class="sis-header"></app-sis-header>
<div id="personalDetailsPageOne" class="container" [ngClass]="!isLgScreen? 'mt-6':''" fxLayout="row wrap"
    fxLayout.lt-md="column">
    <div *ngIf="_refereeDetailsDataList.length>0">
        <form [formGroup]="refereeDetailsTable">
            <ng-container formArrayName="refereeDetails">
                <div *ngFor="let cardFormGroup of formsList | async;let $index = index" class="custom-card my-5 p-4">
                    <fieldset fxLayoutGap="12px" class="fieldsetArray"
                        [ngClass]="!isLgScreen? 'text-center':'text-left'">
                        <legend *ngIf="$index==0">Reference <span style="color:red;font-size: 14px;">(if
                                available)</span></legend>
                        <div fxLayout="column"
                            *ngIf="$index==0 && !showAddForm && isLgScreen && _refereeDetailsDataList.length>0">
                            <button fxFlexAlign="end" mat-raised-button color="primary"
                                (click)="showAddForm = true;hideSaveBut=true;">Add
                                more</button>
                        </div>
                        <ng-container [formGroup]="cardFormGroup">
                            <div fxLayout="row wrap" [ngClass]="isLgScreen? 'ml-2':''" fxLayout.lt-sm="column"
                                fxLayoutGap.xs="5%" class="mb-2">

                                <div class="inputParent">
                                    <mat-label class="inputLabel"
                                        [ngClass]="checkDisableClass(cardFormGroup)">Title<span
                                            class="red">*</span></mat-label>
                                    <mat-form-field appearance="outline" class="input">
                                        <mat-select formControlName="title" placeholder="Select Title">
                                            <mat-option value="">Please Select</mat-option>
                                            <mat-option [value]="'Mr.'"> Mr.</mat-option>
                                            <mat-option [value]="'Mrs.'"> Mrs.</mat-option>
                                            <mat-option [value]="'Miss'"> Miss</mat-option>
                                            <mat-option [value]="'Ms.'"> Ms.</mat-option>
                                        </mat-select>
                                        <mat-error
                                            *ngIf="refereeDetailsForm.controls['title'].touched && refereeDetailsForm.controls['title'].errors">
                                            Title is required
                                        </mat-error>
                                    </mat-form-field>
                                </div>

                                <div class="inputParent">
                                    <mat-label class="inputLabel" [ngClass]="checkDisableClass(cardFormGroup)">Name<span
                                            class="red">*</span></mat-label>
                                    <mat-form-field appearance="outline" class="input">
                                        <input type="text" matInput formControlName="name" placeholder="Name">
                                        <mat-error
                                            *ngIf="refereeDetailsTable.get('refereeDetails').get($index.toString()).get('name').hasError('required')">
                                            Name is <strong>required</strong>
                                        </mat-error>
                                    </mat-form-field>
                                </div>

                                <div class="inputParent">
                                    <mat-label class="inputLabel"
                                        [ngClass]="checkDisableClass(cardFormGroup)">Position<span
                                            class="red">*</span></mat-label>
                                    <mat-form-field appearance="outline" class="input">
                                        <input type="text" matInput formControlName="position" placeholder="Position">
                                        <mat-error
                                            *ngIf="refereeDetailsTable.get('refereeDetails').get($index.toString()).get('position').hasError('required')">
                                            Position is <strong>required</strong>
                                        </mat-error>
                                    </mat-form-field>
                                </div>

                                <div class="inputParent">
                                    <mat-label class="inputLabel" [ngClass]="checkDisableClass(cardFormGroup)">Work
                                        email<span class="red">*</span></mat-label>
                                    <mat-form-field appearance="outline" class="input">
                                        <input type="text" matInput formControlName="email" placeholder="Work email">
                                        <mat-error
                                            *ngIf="refereeDetailsTable.get('refereeDetails').get($index.toString()).get('email').hasError('required')">
                                            Please input work email.
                                        </mat-error>
                                        <mat-error
                                            *ngIf="refereeDetailsTable.get('refereeDetails').get($index.toString()).get('email').hasError('pattern')">
                                            Please check work email.
                                        </mat-error>
                                    </mat-form-field>
                                </div>

                                <div class="inputParent">
                                    <mat-label class="inputLabel" [ngClass]="checkDisableClass(cardFormGroup)">How long
                                        has the person known you?</mat-label>
                                    <mat-form-field appearance="outline" class="input">
                                        <input type="text" matInput formControlName="howlongHasPersonknown"
                                            placeholder="How long has the person known you?">
                                    </mat-form-field>
                                </div>

                                <div class="inputParent ngx-mat-section">
                                    <mat-label class="inputLabel" [ngClass]="checkDisableClass(cardFormGroup)">Mobile
                                        number<span class="red">*</span></mat-label>
                                    <mat-form-field appearance="outline" class="input">
                                        <ngx-mat-intl-tel-input [preferredCountries]="['in']" name="phoneNum"
                                            formControlName="phoneNum"></ngx-mat-intl-tel-input>
                                        <mat-error
                                            *ngIf="refereeDetailsTable.get('refereeDetails').get($index.toString()).get('phoneNum').hasError('required')">
                                            Phone number is <strong> required</strong>
                                        </mat-error>
                                        <mat-error
                                            *ngIf="refereeDetailsTable.get('refereeDetails').get($index.toString()).get('phoneNum').hasError('validatePhoneNumber')">
                                            Phone number is <strong> invalid</strong>
                                        </mat-error>
                                    </mat-form-field>
                                </div>

                                <div class="inputParent">
                                    <mat-label class="inputLabel"
                                        [ngClass]="checkDisableClass(cardFormGroup)">Relationship to you<span
                                            class="red">*</span></mat-label>
                                    <mat-form-field appearance="outline" class="input">
                                        <input type="text" matInput formControlName="relationship"
                                            placeholder="Relationship to you">
                                        <mat-error
                                            *ngIf="refereeDetailsTable.get('refereeDetails').get($index.toString()).get('relationship').hasError('required')">
                                            Relationship is <strong>required</strong>.
                                        </mat-error>
                                    </mat-form-field>
                                </div>

                                <div class="inputParent">
                                    <mat-label class="inputLabel" [ngClass]="checkDisableClass(cardFormGroup)">Name of
                                        institution<span class="red">*</span></mat-label>
                                    <mat-form-field appearance="outline" class="input">
                                        <input type="text" matInput formControlName="institution"
                                            placeholder="Name of institution">
                                        <mat-error
                                            *ngIf="refereeDetailsTable.get('refereeDetails').get($index.toString()).get('institution').hasError('required')">
                                            Institution is <strong>required</strong>.
                                        </mat-error>
                                    </mat-form-field>
                                </div>

                                <div class="inputParent">
                                    <mat-label class="inputLabel" [ngClass]="checkDisableClass(cardFormGroup)">Address
                                        of institution<span class="red">*</span></mat-label>
                                    <mat-form-field appearance="outline" class="input">
                                        <input type="text" matInput formControlName="address"
                                            placeholder="Address of institution">
                                        <mat-error
                                            *ngIf="refereeDetailsTable.get('refereeDetails').get($index.toString()).get('address').hasError('required')">
                                            Address is <strong>required</strong>.
                                        </mat-error>
                                    </mat-form-field>
                                </div>
                            </div>
                            <div fxLayout="row" fxLayoutAlign="end center" class="my-2 md:my-0">
                                <ng-container *ngIf="!cardFormGroup.disabled">
                                    <button mat-flat-button color="primary" [disabled]="cardFormGroup.invalid"
                                        class="mr-1"
                                        (click)="saveEditRefereeDetails(cardFormGroup, $index);">Save</button>
                                    <button mat-flat-button color="warn"
                                        (click)="cardFormGroup.disable();">Cancel</button>
                                </ng-container>
                                <ng-container *ngIf="cardFormGroup.disabled">
                                    <button mat-flat-button color="primary" class="mr-1"
                                        (click)="cardFormGroup.enable();">Edit</button>
                                    <button mat-flat-button color="warn" (click)="removeRefereeDetails($index)">
                                        Delete
                                    </button>
                                </ng-container>
                            </div>
                        </ng-container>
                    </fieldset>
                </div>
            </ng-container>
        </form>
    </div>

    <fieldset *ngIf="showAddForm  || _refereeDetailsDataList.length == 0" class="mb-2"
        [ngClass]="!isLgScreen? 'text-center':'text-left'" class="fieldsetArray">
        <legend *ngIf="_refereeDetailsDataList.length==0">Reference <span style="color:red;font-size: 14px;">(if
                available)</span></legend>
        <form [formGroup]="refereeDetailsForm" #formDirective="ngForm"
            (ngSubmit)="clickedSave = true;addRefereeDetails(refereeDetailsForm,formDirective,clickedSave);">

            <div fxLayoutGap="10px" style="margin-top: 8px;" [ngClass]="isLgScreen? 'ml-2':''">

                <div class="inputParent">
                    <mat-label class="inputLabel">Title<span class="red">*</span></mat-label>
                    <mat-form-field appearance="outline" class="input">
                        <mat-select formControlName="title" placeholder="Select Title">
                            <mat-option value="">Please Select</mat-option>
                            <mat-option [value]="'Mr.'"> Mr.</mat-option>
                            <mat-option [value]="'Mrs.'"> Mrs.</mat-option>
                            <mat-option [value]="'Miss'"> Miss</mat-option>
                            <mat-option [value]="'Ms.'"> Ms.</mat-option>
                        </mat-select>
                        <mat-error
                            *ngIf="refereeDetailsForm.controls['title'].touched && refereeDetailsForm.controls['title'].errors">
                            Title is required
                        </mat-error>
                    </mat-form-field>
                </div>

                <div class="inputParent">
                    <mat-label class="inputLabel">Name<span class="red">*</span></mat-label>
                    <mat-form-field appearance="outline" class="input">
                        <input type="text" matInput formControlName="name" placeholder="Name">
                        <mat-error *ngIf="refereeDetailsForm.get('name').hasError('required')">
                            Name is <strong>required</strong>.
                        </mat-error>
                    </mat-form-field>
                </div>

                <div class="inputParent">
                    <mat-label class="inputLabel">Position<span class="red">*</span></mat-label>
                    <mat-form-field appearance="outline" class="input">
                        <input type="text" matInput formControlName="position" placeholder="Position">
                        <mat-error *ngIf="refereeDetailsForm.get('position').hasError('required')">
                            Position is <strong>required</strong>.
                        </mat-error>
                    </mat-form-field>
                </div>



                <div class="inputParent">
                    <mat-label class="inputLabel">Work email<span class="red">*</span></mat-label>
                    <mat-form-field appearance="outline" class="input">
                        <input type="text" matInput formControlName="email" placeholder="Work email">
                        <mat-error *ngIf="refereeDetailsForm.get('email').hasError('required')">
                            Work email is <strong>required</strong>.
                        </mat-error>
                        <mat-error *ngIf="refereeDetailsForm.get('email').hasError('pattern')">
                            Work email <strong>invalid</strong>.
                        </mat-error>
                    </mat-form-field>
                </div>

                <div class="inputParent">
                    <mat-label class="inputLabel">How long has the person known you?</mat-label>
                    <mat-form-field appearance="outline" class="input">
                        <input type="text" matInput formControlName="howlongHasPersonknown"
                            placeholder="How long has the person known you?">
                    </mat-form-field>
                </div>

                <div class="inputParent ngx-mat-section">
                    <mat-label class="inputLabel">Mobile number<span class="red">*</span></mat-label>
                    <mat-form-field appearance="outline" class="input">
                        <ngx-mat-intl-tel-input [preferredCountries]="['in']" name="phoneNum"
                            formControlName="phoneNum"></ngx-mat-intl-tel-input>
                        <mat-error *ngIf="refereeDetailsForm.get('phoneNum').hasError('required')">
                            Phone number is<strong> required</strong>.
                        </mat-error>
                        <mat-error *ngIf="refereeDetailsForm.get('phoneNum').hasError('validatePhoneNumber')">
                            Phone number is <strong>invalid</strong>.
                        </mat-error>
                    </mat-form-field>
                </div>

                <div class="inputParent">
                    <mat-label class="inputLabel">Relationship to you<span class="red">*</span></mat-label>
                    <mat-form-field appearance="outline" class="input">
                        <input type="text" matInput formControlName="relationship" placeholder="Relationship to you">
                        <mat-error *ngIf="refereeDetailsForm.get('relationship').hasError('required')">
                            Relationship is <strong>required</strong>.
                        </mat-error>
                    </mat-form-field>
                </div>

                <div class="inputParent">
                    <mat-label class="inputLabel">Name of institution<span class="red">*</span></mat-label>
                    <mat-form-field appearance="outline" class="input">
                        <input type="text" matInput formControlName="institution" placeholder="Name of institution">
                        <mat-error *ngIf="refereeDetailsForm.get('institution').hasError('required')">
                            Institution is <strong>required</strong>.
                        </mat-error>
                    </mat-form-field>
                </div>

                <div class="inputParent">
                    <mat-label class="inputLabel">Address of institution<span class="red">*</span></mat-label>
                    <mat-form-field appearance="outline" class="input">
                        <input type="text" matInput formControlName="address" placeholder="Address of institution">
                        <mat-error *ngIf="refereeDetailsForm.get('address').hasError('address')">
                            Address is <strong>required</strong>.
                        </mat-error>
                    </mat-form-field>
                </div>
            </div>

            <div class="text-right" fxLayoutAlign="end">
                <button
                    *ngIf="(!showAddForm && _refereeDetailsDataList.length == 0 ) || (showAddForm && _refereeDetailsDataList.length) || (showAddForm && _refereeDetailsDataList.length == 0)"
                    class="mr-1" mat-flat-button color="primary" [disabled]="refereeDetailsForm.invalid">Add
                </button>
                <button mat-flat-button color="warn" (click)="showAddForm = false;hideSaveBut=false;">
                    Cancel
                </button>
            </div>
        </form>
    </fieldset>

    <div class="addMoreBtnSection" *ngIf="!isLgScreen" (click)="showAddForm = true;hideSaveBut=true;">
        <mat-icon class="icon-display">add_circle_outline</mat-icon>
        <h4>Add More</h4>
    </div>

    <div class="mb-1 mt-1" *ngIf="!isLgScreen">
        <button mat-raised-button color="primary" class="saveNextBtn" (click)="saveAndNext()" type="button">Save &
            Next</button>
    </div>
</div>