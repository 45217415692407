<app-sis-header *ngIf="!isLgScreen" class="sis-header"></app-sis-header>
<div id="personalDetailsPageOne" class="container" [ngClass]="!isLgScreen? 'mt-6':''" fxLayout="row wrap"
    fxLayout.lt-md="column">
    <div *ngIf="academicHistories?.length" [ngClass]="!isLgScreen? 'text-center':'text-left'">
        <form [formGroup]="tableForms">
            <ng-container formArrayName="academicHistoryTbl">
                <div *ngFor="let cardFormGroup of formsList | async;let $index = index" class="custom-card my-5 p-4">
                    <fieldset class="fieldsetArray">
                        <legend *ngIf="$index==0">Education Details<span class="error"> *</span></legend>
                        <div fxLayout="column"
                            *ngIf="$index==0 && isLgScreen  && !addAnother && dataSource.data.length>0">
                            <button fxFlexAlign="end" mat-raised-button color="primary" (click)="addAnother = true;">Add
                                more</button>
                        </div>
                        <ng-container [formGroup]="cardFormGroup">
                            <div fxLayout="row wrap" [ngClass]="isLgScreen? 'ml-2':''" fxLayout.lt-sm="column"
                                fxLayoutGap.xs="5%" class="mb-2">

                                <div class="inputParent">
                                    <mat-label class="inputLabel" [ngClass]="checkDisableClass(cardFormGroup)">Country
                                        <span>*</span></mat-label>
                                    <mat-form-field appearance="outline" class="input">
                                        <mat-select formControlName="countryId"
                                            [(ngModel)]="cardFormGroup.get('countryId').value" placeholder="Please Select">
                                            <mat-option>
                                                <ngx-mat-select-search [formControl]="editCountryFilterCtrl"
                                                    placeholderLabel="Search Country..."
                                                    noEntriesFoundLabel="No results found"></ngx-mat-select-search>
                                            </mat-option>
                                            <mat-option *ngFor="let country of editCountryFilteredList" [value]="country.id">
                                                {{country.name}}
                                            </mat-option>
                                        </mat-select>
                                        <mat-error *ngIf="cardFormGroup.get('countryId').hasError('required')">
                                            Country is required.
                                        </mat-error>
                                    </mat-form-field>
                                </div>

                                <div class="inputParent">
                                    <mat-label class="inputLabel" [ngClass]="checkDisableClass(cardFormGroup)">Name of
                                        Institution <span>*</span></mat-label>
                                    <mat-form-field appearance="outline" class="input">
                                        <input type="text" matInput formControlName="institution"
                                            [(ngModel)]="cardFormGroup.get('institution').value"
                                            placeholder="Institution">
                                        <mat-error *ngIf="cardFormGroup.get('institution').hasError('required')">
                                            Institution is required.
                                        </mat-error>
                                    </mat-form-field>
                                </div>

                                <div class="inputParent">
                                    <mat-label class="inputLabel" [ngClass]="checkDisableClass(cardFormGroup)">Course
                                        Title <span>*</span></mat-label>
                                    <mat-form-field appearance="outline" class="input">
                                        <input type="text" matInput formControlName="course"
                                            [(ngModel)]="cardFormGroup.get('course').value" placeholder="Course">
                                        <mat-error
                                            *ngIf="cardFormGroup.get('course').hasError('required') || cardFormGroup.get('course').hasError('pattern')">
                                            Course is required.
                                        </mat-error>
                                    </mat-form-field>
                                </div>

                                <div class="inputParent">
                                    <mat-label class="inputLabel" [ngClass]="checkDisableClass(cardFormGroup)">Level of
                                        study <span>*</span></mat-label>
                                    <mat-form-field appearance="outline" class="input">
                                        <mat-select formControlName="levelOfStudy"
                                            [(ngModel)]="cardFormGroup.get('levelOfStudy').value">
                                            <mat-option value=""> Please Select</mat-option>
                                            <mat-option *ngFor="let option of levelOfStudyList" [value]="option.name">
                                                {{option.name}}
                                            </mat-option>
                                        </mat-select>
                                        <mat-error
                                            *ngIf="cardFormGroup.get('levelOfStudy').hasError('required') || cardFormGroup.get('levelOfStudy').hasError('pattern')">
                                            Level of Study is required.
                                        </mat-error>
                                    </mat-form-field>
                                </div>

                                <div class="inputParent">
                                    <mat-label class="inputLabel" [ngClass]="checkDisableClass(cardFormGroup)">Start
                                        date <span>*</span></mat-label>
                                    <mat-form-field appearance="outline" class="input">
                                        <input matInput [matDatepicker]="startDateTemp" formControlName="startDate"
                                            placeholder="Start Date"
                                            [max]="cardFormGroup.get('endDate').value || currentDate" readonly>
                                        <mat-datepicker-toggle matSuffix [for]="startDateTemp"></mat-datepicker-toggle>
                                        <mat-datepicker #startDateTemp startView="multi-year"
                                            panelClass="example-month-picker">
                                        </mat-datepicker>
                                        <mat-error
                                            *ngIf="cardFormGroup.get('startDate').hasError('required') || cardFormGroup.get('startDate').hasError('pattern')">
                                            Start Date is required.
                                        </mat-error>
                                    </mat-form-field>
                                </div>

                                <div class="inputParent">
                                    <mat-label class="inputLabel" [ngClass]="checkDisableClass(cardFormGroup)">End
                                        date <span>*</span></mat-label>
                                    <mat-form-field appearance="outline" class="input">
                                        <input matInput [matDatepicker]="endDateTblTemp" formControlName="endDate"
                                            [max]="upcomingDate" placeholder="End Date"
                                            [min]="cardFormGroup.get('startDate').value" readonly>
                                        <mat-datepicker-toggle matSuffix [for]="endDateTblTemp"></mat-datepicker-toggle>
                                        <mat-datepicker #endDateTblTemp startView="multi-year"
                                            panelClass="example-month-picker">
                                        </mat-datepicker>
                                        <mat-error
                                            *ngIf="cardFormGroup.get('endDate').hasError('required') || cardFormGroup.get('endDate').hasError('pattern')">
                                            Start Date is required.
                                        </mat-error>
                                    </mat-form-field>
                                </div>

                                <div class="inputParent">
                                    <mat-label class="inputLabel" [ngClass]="checkDisableClass(cardFormGroup)">Grading
                                        Score <span>*</span></mat-label>
                                    <mat-form-field appearance="outline" class="input">
                                        <mat-select formControlName="gradingScore"
                                            [(ngModel)]="cardFormGroup.get('gradingScore').value" (selectionChange)="onChangeGradingScore($index)">
                                            <mat-option value=""> Please Select</mat-option>
                                            <mat-option [value]="'percentage'">
                                                Percentage
                                            </mat-option>
                                            <mat-option [value]="'gpa'">
                                                GPA
                                            </mat-option>
                                            <mat-option [value]="'other'">
                                                Other
                                            </mat-option>
                                        </mat-select>
                                        <mat-error *ngIf="cardFormGroup.get('gradingScore').hasError('required')">
                                            Grading Score is required.
                                        </mat-error>
                                    </mat-form-field>
                                </div>

                                <div class="inputParent">
                                    <mat-label class="inputLabel" [ngClass]="checkDisableClass(cardFormGroup)">Full
                                        time/Part time <span>*</span></mat-label>
                                    <mat-form-field appearance="outline" class="input">
                                        <mat-select formControlName="fulltimeorParttime"
                                            [(ngModel)]="cardFormGroup.get('fulltimeorParttime').value">
                                            <mat-option value=""> Please Select</mat-option>
                                            <mat-option [value]="'full'">
                                                Full Time
                                            </mat-option>
                                            <mat-option [value]="'part'">
                                                Part Time
                                            </mat-option>
                                        </mat-select>
                                        <mat-error *ngIf="cardFormGroup.get('fulltimeorParttime').hasError('required')">
                                            Full / Part Time is required.
                                        </mat-error>
                                    </mat-form-field>
                                </div>

                                <div *ngIf="cardFormGroup.get('gradingScore').value==='other'" fxLayout="column"
                                    fxFlex="32%" fxFlex.lt-lg="32%" fxFlex.lt-md="49%"
                                    class="xl:mb-1 md:mb-4 sm:mb-5 mb-3">
                                    <span style="margin-bottom: 0.35rem;"
                                        class="title-form md:whitespace-no-wrap pb-2 text-left"
                                        [ngClass]="checkDisableClass(cardFormGroup)">Result info -
                                        Other <span>*</span> </span>
                                    <div fxLayout="row">
                                        <div class="inputParent">
                                            <mat-form-field appearance="outline" class="input">
                                                <input type="text" formControlName="resultOther" matInput
                                                    placeholder="Other" readonly="true" (keyup)="validateResultOther($index)">
                                                    <mat-error
                                                    *ngIf="cardFormGroup.get('resultOther').hasError('min')">
                                                    Should be from 0 to 100.
                                                </mat-error>
                                            </mat-form-field>
                                        </div>
                                    </div>
                                </div>

                                <div *ngIf="cardFormGroup.get('gradingScore').value==='percentage'" fxLayout="column"
                                    fxFlex="32%" fxFlex.lt-lg="32%" fxFlex.lt-md="49%"
                                    class="xl:mb-1 md:mb-4 sm:mb-5 mb-3">
                                    <span class="title-form md:whitespace-no-wrap pb-2 text-left"
                                        [ngClass]="checkDisableClass(cardFormGroup)">Result info -
                                        Percentage</span>
                                    <div fxLayout="row">
                                        <div class="inputParent">
                                            <mat-label class="inputLabel"
                                                [ngClass]="checkDisableClass(cardFormGroup)">0-100<span>*</span></mat-label>
                                            <mat-form-field appearance="outline" class="input">
                                                <input type="text" formControlName="resultPercentage" matInput
                                                    (keyup)="validatResultPercentage($index)" placeholder="0-100" >
                                                <mat-error
                                                    *ngIf="cardFormGroup.get('resultPercentage').hasError('pattern')">
                                                    Should be integer.
                                                </mat-error>
                                                <mat-error
                                                    *ngIf="cardFormGroup.get('resultPercentage').hasError('min') ||cardFormGroup.get('resultPercentage').hasError('max')">
                                                    Should be from 0 to 100.
                                                </mat-error>
                                                <mat-error
                                                *ngIf="cardFormGroup.get('resultPercentage').hasError('required')">
                                                Result info is required
                                            </mat-error>
                                            </mat-form-field>
                                        </div>
                                    </div>
                                </div>
                                <div *ngIf="cardFormGroup.get('gradingScore').value==='gpa'" fxLayout="column"
                                    fxFlex="32%" fxFlex.lt-lg="32%" fxFlex.lt-md="49%"
                                    class="xl:mb-1 md:mb-4 sm:mb-5 mb-3">
                                    <span class="title-form md:whitespace-no-wrap pb-2 text-left"
                                        [ngClass]="checkDisableClass(cardFormGroup)">Result info - Grade
                                        Point
                                        Average</span>
                                    <div [ngClass]="!isLgScreen? 'columnFlex':''" fxLayout="row"
                                        fxLayoutAlign="space-between">
                                        <div class="inputParent">
                                            <mat-label class="inputLabel"
                                                [ngClass]="checkDisableClass(cardFormGroup)">Result<span>*</span></mat-label>
                                            <mat-form-field appearance="outline" class="input">
                                                <input (keyup)="checkValidateResult($index)" type="text"
                                                    formControlName="result" matInput placeholder="Result"
                                                    (keyup)="checkValidateResult($index)">
                                                <mat-error *ngIf="cardFormGroup.get('result').hasError('min')">Minimum
                                                    value
                                                    is 0
                                                </mat-error>
                                                <mat-error *ngIf="cardFormGroup.get('result').hasError('max')">Maximum
                                                    value
                                                    is
                                                    10 and
                                                    Should be less than or equal Out of</mat-error>
                                                <mat-error
                                                    *ngIf="cardFormGroup.get('result').hasError('pattern')">Should be
                                                    number
                                                </mat-error>
                                                <mat-error
                                                    *ngIf="cardFormGroup.get('result').hasError('required')">Result
                                                    is
                                                    required
                                                </mat-error>
                                            </mat-form-field>
                                        </div>

                                        <div class="inputParent">
                                            <mat-label class="inputLabel"
                                                [ngClass]="checkDisableClass(cardFormGroup)">Out
                                                of <span>*</span></mat-label>
                                            <mat-form-field appearance="outline" class="input">
                                                <input type="text" formControlName="outOf" matInput
                                                    (keyup)="validateResult($index)" placeholder="Out Of">
                                                <mat-error *ngIf="cardFormGroup.get('outOf').hasError('min')">Minimum
                                                    value
                                                    is 1
                                                </mat-error>
                                                <mat-error *ngIf="cardFormGroup.get('outOf').hasError('max')">Maximum
                                                    value
                                                    is 10
                                                </mat-error>
                                                <mat-error *ngIf="cardFormGroup.get('outOf').hasError('pattern')">Should
                                                    be
                                                    integer
                                                </mat-error>
                                                <mat-error *ngIf="cardFormGroup.get('outOf').hasError('required')">Out
                                                    of is
                                                    required
                                                </mat-error>
                                            </mat-form-field>
                                        </div>
                                    </div>

                                </div>
                            </div>
                            <div fxLayout="row" fxLayoutAlign="end center" class="my-2 md:my-0">
                                <ng-container *ngIf="!cardFormGroup.disabled">
                                    <button mat-flat-button color="primary"
                                        [disabled]="cardFormGroup.invalid || validateUpdateResult" class="mr-1"
                                        (click)="editAcademicHistory(cardFormGroup,$index);">Save</button>
                                    <button mat-flat-button color="warn"
                                        (click)="cardFormGroup.disable();">Cancel</button>
                                </ng-container>
                                <ng-container *ngIf="cardFormGroup.disabled">
                                    <button mat-flat-button color="primary" class="mr-1"
                                        (click)="cardFormGroup.enable();">Edit</button>
                                    <button mat-flat-button color="warn" (click)="deleteRow($index)">
                                        Delete
                                    </button>
                                </ng-container>
                            </div>
                        </ng-container>
                    </fieldset>
                </div>
            </ng-container>
        </form>
    </div>

    <fieldset fxLayoutGap="12px" class="mb-2" *ngIf="addAnother || (academicHistories?.length < 1)"
        [ngClass]="!isLgScreen? 'text-center':'text-left'" class="fieldsetArray">
        <legend *ngIf="academicHistories?.length==0">Education Details<span class="error"> *</span></legend>

        <form #formDirectiveGroup="ngForm" [formGroup]="academicHistoryForm"
            (ngSubmit)="addAnother=false;addAcademicHistory(formDirectiveGroup)">
            <div fxLayout="row wrap" fxLayout.lt-sm="column" fxLayoutGap.xs="5%" fxLayoutAlign="space-between">

                <div class="inputParent">
                    <mat-label class="inputLabel">Country <span class="red">*</span></mat-label>
                    <mat-form-field appearance="outline" class="input">
                        <mat-select formControlName="countryId" placeholder="Please Select">
                            <mat-option>
                                <ngx-mat-select-search [formControl]="countryFilterCtrl"
                                    placeholderLabel="Search Country..."
                                    noEntriesFoundLabel="No results found"></ngx-mat-select-search>
                            </mat-option>
                            <mat-option *ngFor="let country of countryFilteredList" [value]="country.id">
                                {{country.name}}
                            </mat-option>
                        </mat-select>
                        <mat-error *ngIf="academicHistoryForm.get('countryId').hasError('required')">
                            Country is <strong>required</strong>
                        </mat-error>
                    </mat-form-field>
                </div>

                <div class="inputParent">
                    <mat-label class="inputLabel">Name of Institution <span class="red">*</span></mat-label>
                    <mat-form-field appearance="outline" class="input">
                        <input matInput formControlName="institution" placeholder="Institution">
                        <mat-error *ngIf="academicHistoryForm.get('institution').hasError('required')">Institution is
                            required
                        </mat-error>
                    </mat-form-field>
                </div>

                <div class="inputParent">
                    <mat-label class="inputLabel">Course Title <span class="red">*</span></mat-label>
                    <mat-form-field appearance="outline" class="input">
                        <input formControlName="course" placeholder="Course" matInput>
                        <mat-error *ngIf="academicHistoryForm.get('course').hasError('required')
                               || academicHistoryForm.get('course').hasError('pattern')">Course is required</mat-error>
                    </mat-form-field>
                </div>

                <div class="inputParent">
                    <mat-label class="inputLabel">Level of study <span class="red">*</span></mat-label>
                    <mat-form-field appearance="outline" class="input">
                        <mat-select formControlName="levelOfStudy">
                            <mat-option value=""> Please Select</mat-option>
                            <mat-option *ngFor="let option of levelOfStudyList" [value]="option.name">
                                {{option.name}}
                            </mat-option>
                        </mat-select>
                        <mat-error *ngIf="academicHistoryForm.get('levelOfStudy').hasError('required')
                                   || academicHistoryForm.get('levelOfStudy').hasError('pattern')">Level of study is
                            required
                        </mat-error>
                    </mat-form-field>
                </div>

                <div class="inputParent">
                    <mat-label class="inputLabel">Start date <span class="red">*</span></mat-label>
                    <mat-form-field appearance="outline" class="input">
                        <input matInput [matDatepicker]="dp" placeholder="Start Date" formControlName="startDate"
                            [max]="endDate || currentDate" [(ngModel)]="startDate" readonly>
                        <mat-datepicker-toggle matSuffix [for]="dp"></mat-datepicker-toggle>
                        <mat-datepicker #dp startView="multi-year" panelClass="example-month-picker">
                        </mat-datepicker>
                        <mat-error *ngIf="academicHistoryForm.get('startDate').hasError('required') ||
                               academicHistoryForm.get('startDate').hasError('pattern')">Start date is
                            required</mat-error>
                    </mat-form-field>
                </div>

                <div class="inputParent">
                    <mat-label class="inputLabel">End date <span class="red">*</span></mat-label>
                    <mat-form-field appearance="outline" class="input">
                        <input matInput [matDatepicker]="endDateTemp" formControlName="endDate" [min]="startDate"
                            [max]="upcomingDate" placeholder="End Date" [(ngModel)]="endDate" readonly>
                        <mat-datepicker-toggle matSuffix [for]="endDateTemp"></mat-datepicker-toggle>
                        <mat-datepicker #endDateTemp startView="multi-year" panelClass="example-month-picker">
                        </mat-datepicker>
                        <mat-error *ngIf="academicHistoryForm.get('endDate').hasError('required') ||
                               academicHistoryForm.get('endDate').hasError('pattern')">End date is required</mat-error>
                    </mat-form-field>
                </div>

                <div class="inputParent">
                    <mat-label class="inputLabel">Grading Score <span class="red">*</span></mat-label>
                    <mat-form-field appearance="outline" class="input">
                        <mat-select formControlName="gradingScore">
                            <mat-option value=""> Please Select</mat-option>
                            <mat-option [value]="'percentage'">
                                Percentage
                            </mat-option>
                            <mat-option [value]="'gpa'">
                                GPA
                            </mat-option>
                        </mat-select>
                        <mat-error *ngIf="academicHistoryForm.get('gradingScore').hasError('required')">
                            Grading Score is <strong>required</strong>
                        </mat-error>
                    </mat-form-field>
                </div>

                <div class="inputParent">
                    <mat-label class="inputLabel">Full time/Part time <span class="red">*</span></mat-label>
                    <mat-form-field appearance="outline" class="input">
                        <mat-select formControlName="fulltimeorParttime">
                            <mat-option value=""> Please Select</mat-option>
                            <mat-option [value]="'full'">
                                Full Time
                            </mat-option>
                            <mat-option [value]="'part'">
                                Part Time
                            </mat-option>
                        </mat-select>
                        <mat-error *ngIf="academicHistoryForm.get('fulltimeorParttime').hasError('required')">
                            Full / Part Time is <strong>required</strong>
                        </mat-error>
                    </mat-form-field>
                </div>

                <div *ngIf="academicHistoryForm.get('gradingScore').value==='other'" fxLayout="column" fxFlex="32%"
                    fxFlex.lt-lg="32%" fxFlex.lt-md="49%" class="xl:mb-1 md:mb-4 sm:mb-5 mb-3">
                    <span style="margin-bottom: 0.35rem;" class="title-form md:whitespace-no-wrap pb-2 text-left">Result
                        info - Other<span class="red">*</span></span>
                    <div fxLayout="row">

                        <div class="inputParent">
                            <mat-form-field appearance="outline" class="input">
                                <input type="text" formControlName="resultOther" readonly="true" placeholder="Other" matInput>
                            </mat-form-field>
                        </div>
                    </div>
                </div>

                <div *ngIf="academicHistoryForm.get('gradingScore').value==='percentage'" fxLayout="column" fxFlex="32%"
                    fxFlex.lt-lg="32%" fxFlex.lt-md="49%" class="xl:mb-1 md:mb-4 sm:mb-5 mb-3">
                    <span class="title-form md:whitespace-no-wrap pb-2 text-left">Result info - Percentage</span>
                    <div fxLayout="row">

                        <div class="inputParent">
                            <mat-label class="inputLabel">0-100 <span class="red">*</span></mat-label>
                            <mat-form-field appearance="outline" class="input">
                                <input type="text" formControlName="resultPercentage" placeholder="0-100" matInput>
                                <mat-error *ngIf="academicHistoryForm.get('resultPercentage').hasError('pattern')">
                                    Should be integer.
                                </mat-error>
                                <mat-error
                                    *ngIf="academicHistoryForm.get('resultPercentage').hasError('min') ||academicHistoryForm.get('resultPercentage').hasError('max')">
                                    Should be from 0 to 100.
                                </mat-error>
                            </mat-form-field>
                        </div>
                    </div>
                </div>
                <div *ngIf="academicHistoryForm.get('gradingScore').value==='gpa'" fxLayout="column" fxFlex="32%"
                    fxFlex.lt-lg="32%" fxFlex.lt-md="49%" class="xl:mb-1 md:mb-4 sm:mb-5 mb-3">
                    <span class="title-form md:whitespace-no-wrap pb-2 text-left">Result info - Grade Point
                        Average</span>
                    <div [ngClass]="!isLgScreen? 'columnFlex':''" fxLayout="row" fxLayoutAlign="space-between">

                        <div class="inputParent">
                            <mat-label class="inputLabel">Result <span class="red">*</span></mat-label>
                            <mat-form-field appearance="outline" class="input">
                                <input (keyup)="ValidationToResult()" type="text" placeholder="Result"
                                    formControlName="result" matInput>
                                <mat-error *ngIf="academicHistoryForm.get('result').hasError('min')">Minimum value is
                                    0</mat-error>
                                <mat-error *ngIf="academicHistoryForm.get('result').hasError('max')">Maximum value is 10
                                    and
                                    Should be less than or equal Out of</mat-error>
                                <mat-error *ngIf="academicHistoryForm.get('result').hasError('pattern')">Should be
                                    number</mat-error>
                                <mat-error *ngIf="academicHistoryForm.get('result').hasError('required')">Result is
                                    required</mat-error>
                            </mat-form-field>
                        </div>

                        <div class="inputParent">
                            <mat-label class="inputLabel">Out of <span class="red">*</span></mat-label>
                            <mat-form-field appearance="outline" class="input">
                                <input type="text" formControlName="outOf" placeholder="Out Of" matInput
                                    (keyup)="addValidationToResult()">
                                <mat-error *ngIf="academicHistoryForm.get('outOf').hasError('min')">Minimum value is
                                    1</mat-error>
                                <mat-error *ngIf="academicHistoryForm.get('outOf').hasError('max')">Maximum value is
                                    10</mat-error>
                                <mat-error *ngIf="academicHistoryForm.get('outOf').hasError('pattern')">Should be
                                    number</mat-error>
                                <mat-error *ngIf="academicHistoryForm.get('outOf').hasError('required')">Out of is
                                    required</mat-error>
                            </mat-form-field>
                        </div>
                    </div>
                </div>
            </div>
            <div fxLayoutAlign="end">
                <button *ngIf="addAnother || academicHistories?.length == 0" mat-flat-button color="primary"
                    class="mr-1" [disabled]="academicHistoryForm.invalid || check">Add</button>
                <button mat-flat-button color="warn" (click)="addAnother = false;">
                    Cancel
                </button>
            </div>
        </form>
    </fieldset>



    <div class="addMoreBtnSection" *ngIf="!isLgScreen" (click)="addAnother = true;">
        <mat-icon class="icon-display">add_circle_outline</mat-icon>
        <h4>Add More</h4>
    </div>

    <div class="mb-1 mt-1" *ngIf="!isLgScreen">
        <button mat-raised-button color="primary" [ngClass]="academicHistories.length==0?'disabledBtn':'enabledBtn'" [disabled]="academicHistories.length==0" class="saveNextBtn" (click)="saveAndNext()" type="button">Save &
            Next</button>
    </div>
</div>
