import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PostAuthModule } from './modules/post-auth/post-auth.module';
import { PreAuthModule } from './modules/pre-auth/pre-auth.module';
import { SisExpiryComponent } from './modules/shared/sis-expiry/sis-expiry.component';
import { SisThankYouComponent } from './modules/shared/sis-thank-you/sis-thank-you.component';

const routes: Routes = [
  {
    path: '',
    redirectTo: '/home',
    pathMatch: 'full'
  }, {
    path: 'home',
    loadChildren: () => import('./modules/pre-auth/pre-auth.module').then(m => m.PreAuthModule)
  }, {
    path: 'sis-form',
    loadChildren: () => PostAuthModule
  }, {
    path: 'sis-expired',
    component: SisExpiryComponent,
    pathMatch: 'full'
  }, {
    path: 'sis-thank-you',
    component: SisThankYouComponent,
    pathMatch: 'full'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule]
})
export class AppRoutingModule { }

export const routingModulesMain = [PreAuthModule, PostAuthModule];