import { Component } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormControl, FormGroup, FormGroupDirective, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { SharedService } from '../../shared/shared.service';
import { SisService } from '../sis.service';
import { Observable } from 'rxjs';
import { MatTableDataSource } from '@angular/material/table';

@Component({
  selector: 'app-sis-work-experience',
  templateUrl: './sis-work-experience.component.html',
  styleUrls: ['./sis-work-experience.component.scss']
})
export class SisWorkExperienceComponent {
  public sisWorkExp: FormGroup;
  isLgScreen: boolean = false;
  studentId: number;
  userId: number;

  addWorkDetialsForm: FormGroup;
  cardWorkDetialsForm: FormGroup;
  workDetailsInfo: any;
  workDetailsList: any[] = [];
  isHasExperience: boolean = false;
  today: Date = new Date();
  isEditable: boolean = false;
  addAnother: boolean = false;
  noWorkExperience: FormControl = new FormControl(false);
  formsList: Observable<any>;
  dataSource: MatTableDataSource<any>;
  manualSave: boolean;
  constructor(private router: Router, private fb: FormBuilder, private toastr: ToastrService,
    private activate: ActivatedRoute, private sharedService: SharedService, private sisService: SisService) { }

  ngOnInit(): void {
    this.studentId = this.sharedService.getStudentId();
    this.userId = this.sharedService.getUserId();
    this.isLgScreen = this.sharedService.isLgScreen();
    this.manualSave = false;
    this.addWorkDetaislFormInit();
    this.getWorkExperienceData();
    this.sharedService.scrollToTop();
  }

  get jobTitle(): AbstractControl {
    return this.addWorkDetialsForm.get('jobTitle');
  }

  get organisationName(): AbstractControl {
    return this.addWorkDetialsForm.get('organisationName');
  }

  get organisationAddress(): AbstractControl {
    return this.addWorkDetialsForm.get('organisationAddress');
  }

  get companyNumber(): AbstractControl {
    return this.addWorkDetialsForm.get('companyNumber');
  }

  get fromDate(): AbstractControl {
    return this.addWorkDetialsForm.get('fromDate');
  }

  get toDate(): AbstractControl {
    return this.addWorkDetialsForm.get('toDate');
  }

  get duties(): AbstractControl {
    return this.addWorkDetialsForm.get('duties');
  }

  get stillWork(): AbstractControl {
    return this.addWorkDetialsForm.get('stillWork');
  }

  get cardsForm(): FormArray {
    return this.cardWorkDetialsForm.controls['cardsForm'] as FormArray;
  }

  submitData() {
    this.toastr.success("Record Successfully Saved")

  }
  preventSpace(event: KeyboardEvent) {
    if (event.code === "Space") {
      event.preventDefault();
    }
  }

  saveData() {
    this.sisService.setSISWorkExperienceDetailsAtLocal(this.workDetailsInfo);
    if (this.router.url === '/sis-work-experience' || this.manualSave) {
      this.sisService.saveSISData(10).subscribe(() => {
        console.log('data saved work');
      })
    }
  }

  saveAndNext() {
    this.sisService.submitSIS(this.studentId, 10).subscribe((res: any) => {
      if (res) {
        this.router.navigate(['/sis-reference'])
      }
    }, error => {
      this.toastr.error("Something went wrong", error);
    });
  }

  private addWorkDetaislFormInit(): void {
    this.addWorkDetialsForm = new FormGroup({
      jobTitle: new FormControl('', Validators.required),
      organisationName: new FormControl('', Validators.required),
      organisationAddress: new FormControl('', Validators.required),
      companyNumber: new FormControl('', [Validators.pattern(/^[0-9-+]+$/)]),
      fromDate: new FormControl('', Validators.required),
      toDate: new FormControl('', Validators.required),
      duties: new FormControl(''),
      stillWork: new FormControl(false)
    })
  }

  private initFormForCards(): void {
    this.cardWorkDetialsForm = this.fb.group({
      cardsForm: this.fb.array([]),
    })
    this.createFormForEachCard();
  }

  private createFormForEachCard(): void {
    if (this.workDetailsList.length) {
      this.workDetailsList.forEach(res => {
        res.isEditable = false;
        let cardFormGroup = this.fb.group({
          jobTitle: [res.jobTitle, Validators.required],
          organisationName: [res.name, Validators.required],
          organisationAddress: [res.address, Validators.required],
          companyNumber: [res.companyPhoneNumber, [Validators.pattern(/^[0-9-+]+$/)]],
          fromDate: [res.fromDate, Validators.required],
          toDate: [res.isPresent ? null : res.toDate, res.isPresent ? '' : Validators.required],
          duties: [res.duties],
          stillWork: [res.isPresent]
        })
        cardFormGroup.disable();
        this.cardsForm.push(cardFormGroup);
      })
      this.dataSource = new MatTableDataSource<any>(this.cardsForm.controls);
      this.formsList = this.dataSource.connect();
    }
  }


  isStillwork(): void {
    if (this.stillWork.value) {
      this.toDate.patchValue(null);
      this.toDate.clearValidators();
    } else {
      this.toDate.setValidators(Validators.required);
    }
    this.toDate.updateValueAndValidity();
  }

  isStillWorkForCard(formGroup: FormGroup): void {
    if (formGroup.controls['stillWork'].value) {
      formGroup.controls['toDate'].patchValue(null);
      formGroup.controls['toDate'].clearValidators();
    } else {
      formGroup.controls['toDate'].setValidators(Validators.required)
    }
    formGroup.controls['toDate'].updateValueAndValidity();
  }

  addWorkDetails(validationDirective: FormGroupDirective): void {
    const dataObj = {
      id: 0,
      studentId: this.studentId,
      jobTitle: this.jobTitle.value,
      name: this.organisationName.value,
      address: this.organisationAddress.value,
      fromDate: this.sharedService.formatDate(this.fromDate.value),
      toDate: this.sharedService.formatDate(this.toDate.value),
      companyPhoneNumber: this.companyNumber.value,
      duties: this.duties.value,
      isEditable: this.isEditable,
      isPresent: this.stillWork.value || false,
    }
    this.addAnother = false;
    this.workDetailsList.push(dataObj);
    this.workDetailsInfo.noExperience = true;
    this.addWorkDetaislFormInit();
    this.initFormForCards();
    setTimeout(() => {
      this.manualSave = true;
      this.saveData();
    }, 500);
  }

  cancelAddWorkDetails() {
    this.addAnother = false;
    this.addWorkDetaislFormInit();
    this.initFormForCards();
  }

  showAddForm() {
    this.addAnother = true;
  }

  deleteWorkDetails($index: number): void {
    this.workDetailsList.splice($index, 1);
    this.initFormForCards();
    setTimeout(() => {
      this.manualSave = true;
      this.saveData();
    }, 500);
  }

  saveEditWorkDetail(cardFormGroup: FormGroup, $index: number) {
    const params = {
      id: this.workDetailsList[$index].id,
      studentId: this.workDetailsList[$index].studentId,
      jobTitle: cardFormGroup.controls['jobTitle'].value,
      name: cardFormGroup.controls['organisationName'].value,
      address: cardFormGroup.controls['organisationAddress'].value,
      fromDate: this.sharedService.formatDate(cardFormGroup.controls['fromDate'].value),
      toDate: this.sharedService.formatDate(cardFormGroup.controls['toDate'].value),
      companyPhoneNumber: cardFormGroup.controls['companyNumber'].value,
      duties: cardFormGroup.controls['duties'].value,
      isEditable: true,
      isPresent: cardFormGroup.controls['stillWork'].value
    }
    this.workDetailsList[this.workDetailsList.map((x: any, i: any) => [i, x]).filter(
      (x: any) => x[1]['id'] == this.workDetailsList[$index].id)[0][0]] = params;

    cardFormGroup.disable();
    setTimeout(() => {
      this.manualSave = true;
      this.saveData();
    }, 500);
  }

  getWorkExperienceData() {
    this.sisService.getSISWorkExperienceData(this.studentId, this.userId).subscribe((res: any) => {
      if (res) {
        this.workDetailsInfo = JSON.parse(res.data);
        this.workDetailsList = this.workDetailsInfo.List;
        this.noWorkExperience.setValue(this.workDetailsInfo.noExperience);
        this.saveData();
        this.initFormForCards();
      }
    })
  }

  checkDisableClass(cardFormGroup: any) {
    return this.sharedService.setDisableClass(cardFormGroup);
  }
}
