import { Component, QueryList, ViewChildren } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { SharedService } from '../../shared/shared.service';
import { SisService } from '../sis.service';
import { ErrorComponent } from '../../shared/error/error.component';
import { MatDialog } from '@angular/material/dialog';
import { MatCheckbox } from '@angular/material/checkbox';

@Component({
  selector: 'app-sis-immigration-history',
  templateUrl: './sis-immigration-history.component.html',
  styleUrls: ['./sis-immigration-history.component.scss']
})
export class SisImmigrationHistoryComponent {
  isLgScreen: boolean = false;
  immigrationCountriesListStudentDesires: Array<any> = [];
  arr_countryCheckedValue: any = [];
  studentId: number;
  userId: number;
  @ViewChildren(MatCheckbox) private checkboxes: QueryList<MatCheckbox>;
  arr_countryStr: any = [];
  immigrationData: any = {};
  sectionIndex: number = 5;  
  manualSave: boolean;
  constructor(private router: Router, private fb: FormBuilder, private toastr: ToastrService,
    private activate: ActivatedRoute, private sharedService: SharedService, private sisService: SisService,
    private dialog: MatDialog) { }

  ngOnInit(): void {
    this.studentId = this.sharedService.getStudentId();
    this.userId = this.sharedService.getUserId();
    this.isLgScreen = this.sharedService.isLgScreen();
    this.manualSave = false;
    this.sharedService.scrollToTop();
    this.getImmigrationCountriesStudentDesires();
  }

  submitData() {
    this.toastr.success("Record Successfully Saved")
  }

  preventSpace(event: KeyboardEvent) {
    if (event.code === "Space") {
      event.preventDefault();
    }
  }

  saveData() {
    this.sisService.setSISImmigrationHistoryAtLocal({ immigrationCountryIds: this.arr_countryCheckedValue.toString() });
    this.setPermission();
    if (this.router.url === '/sis-immigration-history' || this.manualSave) {
      this.sisService.saveSISData(this.sectionIndex).subscribe(() => {
        console.log('data saved immigration');
      })
    }
  }

  saveAndNext() {
    this.sisService.submitSIS(this.studentId, this.sectionIndex).subscribe((res: any) => {
      if (res) {
        this.router.navigate(['/sis-visa-rejection'])
      }
    }, error => {
      this.toastr.error("Something went wrong", error);
    });
  }

  getImmigrationCountriesStudentDesires() {
    this.sisService.getImmigrationCountriesList(this.studentId).subscribe(res => {
      let response: any = res;
      let countriesData = response.data.items;
      if (countriesData && countriesData.length > 0) {
        countriesData.push({ id: -1, name: 'None' });
        this.immigrationCountriesListStudentDesires = countriesData.map((item: any, index: number) => {
          return {
            id: item.id.toString(),
            Name: item.name,
            checked: false,
            recordFound: false,
          };
        });
        this.getImmigrationDetails();
      } else {
        this.immigrationCountriesListStudentDesires = [];
      }
    })
  }

  checkImmigrationHistory(e: any) {
    if (e.source.value === '-1' && this.arr_countryCheckedValue.length > 0 && !this.arr_countryCheckedValue.includes('-1')) {
      const dialogRef = this.dialog.open(ErrorComponent, {
        data: { dialogText: 'Please unselect all the selected countries first for this option.' }
      });
      let final: MatCheckbox;
      for (let i = 0; i < this.checkboxes['_results']['length']; i++) {
        if (this.checkboxes['_results'][i]['id'] === 'immigration-1') {
          final = this.checkboxes['_results'][i];
        }
      }
      final.checked = false;
      return 0;
    }
    if (e.checked) {
      this.arr_countryCheckedValue.push(e.source.value);
      this.arr_countryStr = this.arr_countryCheckedValue.toString();
    } else {

      var second = e.source.value;
      this.arr_countryCheckedValue = this.arr_countryCheckedValue.filter(
        (item: any) => item !== second
      );
    }
    if (this.arr_countryCheckedValue.length > 1 && this.arr_countryCheckedValue.includes('-1')) {
      this.arr_countryCheckedValue.splice(this.arr_countryCheckedValue.indexOf('-1'), 1);
      this.immigrationCountriesListStudentDesires = this.immigrationCountriesListStudentDesires.map((item) => {
        if (item.id === '-1') {
          return { ...item, checked: false, recordFound: false };
        }
        return item;
      });
    }
    setTimeout(() => {
      this.manualSave = true;
      this.saveData();
    }, 1000);
  }

  getImmigrationDetails() {
    this.sisService.getSISImmigrationHistory(this.studentId).subscribe((res: any) => {
      if (res) {
        let response: any = JSON.parse(res.data);
        this.immigrationData = response;
        if (this.immigrationData.immigrationCountryIds) {
          let savedVal = this.immigrationData.immigrationCountryIds.split(',').map(function (item: any) {
            return parseInt(item, 10);
          });;
          this.arr_countryCheckedValue = [];
          this.immigrationCountriesListStudentDesires = this.immigrationCountriesListStudentDesires.map((item) => {
            if (savedVal.includes(parseInt(item.id))) {
              this.arr_countryCheckedValue.push(item.id);
              return { ...item, checked: true, recordFound: true };
            }
            return item;
          });
          this.saveData();
        }
      }
    })
  }

  setPermission() {
    if (this.arr_countryCheckedValue.length > 0) {
      this.sisService.removePendingItems(this.sectionIndex);
      this.sisService.setSectionWisePermission('immigrationHistoryPerm', true);
    } else {
      this.sisService.setPendingItems('Immigration History', this.sectionIndex);
      this.sisService.setSectionWisePermission('immigrationHistoryPerm', false);
    }
    this.sisService.emitSubmitPermissionChangeAlert('true');
  }
}
