import { Location } from '@angular/common';
import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { SharedService } from '../../shared/shared.service';

@Component({
  selector: 'app-sis-header',
  templateUrl: './sis-header.component.html',
  styleUrls: ['./sis-header.component.scss']
})
export class SisHeaderComponent {

  showBackBtn: boolean = true;
  isCA: boolean
  constructor(private location: Location, router: Router, private sharedService: SharedService) {
    if (router.url === '/sis-personal-details-one') {
      this.showBackBtn = false;
    } else {
      this.showBackBtn = true;
    }
    this.isCA = this.sharedService.getStudentCountryCheck();
  }

  goBack() {
    this.location.back(); // <-- go back to previous location on cancel
  }

  logout() {
    this.sharedService.showLogoutDialog();
  }
}
