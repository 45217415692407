<app-sis-header *ngIf="!isLgScreen" class="sis-header"></app-sis-header>
<div id="personalDetailsPageOne" class="container" [ngClass]="!isLgScreen? 'top-5':''" fxLayout="row wrap"
    fxLayout.lt-md="column">

    <div *ngIf="!isLgScreen">

        <section id="sub-header">
            <h2>SI-UK STUDENT INFORMATION SHEET</h2>
        </section>

        <section id="consent" class="mb-1">
            <p>By completing this form, I permit SI-UK to enter my information into application forms for the courses
                and
                schools listed under University choice.</p>
        </section>

        <fieldset fxLayoutGap="12px" class="mb-2">
            <legend>Personal Details</legend>

            <form [formGroup]="sisForm">
                <div class="inputParent">
                    <mat-label class="inputLabel">Title<span class="red">*</span></mat-label>
                    <mat-form-field appearance="outline" class="input">
                        <mat-select formControlName="Title">
                            <mat-option value="">Please Select</mat-option>
                            <mat-option [value]="'Mr.'"> Mr.</mat-option>
                            <mat-option [value]="'Mrs.'"> Mrs.</mat-option>
                            <mat-option [value]="'Miss'"> Miss</mat-option>
                            <mat-option [value]="'Ms.'"> Ms.</mat-option>
                        </mat-select>
                        <mat-error *ngIf="sisForm.controls['Title'].touched && sisForm.controls['Title'].errors">
                            Title is required
                        </mat-error>
                    </mat-form-field>
                </div>

                <div class="inputParent">
                    <mat-label class="inputLabel">First Name<span class="red">*</span></mat-label>
                    <mat-form-field appearance="outline" class="input">
                        <input matInput formControlName="firstName" placeholder="Enter First Name">
                        <mat-error
                            *ngIf="sisForm.controls['firstName'].touched && sisForm.controls['firstName'].errors?.['required'] ">
                            First Name is required
                        </mat-error>
                        <mat-error *ngIf="sisForm.controls['firstName'].errors?.['pattern'] ">
                            Only Alphabets are allowed
                        </mat-error>
                        <mat-error *ngIf="sisForm.controls['firstName'].errors?.['maxlength'] ">
                            Maximum limit reached
                        </mat-error>
                    </mat-form-field>
                </div>

                <div class="inputParent">
                    <mat-label class="inputLabel">Last Name<span class="red">*</span></mat-label>
                    <mat-form-field appearance="outline" class="input">
                        <input matInput formControlName="familyName" placeholder="Enter Last Name">
                        <mat-error
                            *ngIf="sisForm.controls['familyName'].touched && sisForm.controls['familyName'].errors?.['required']">
                            Last Name is required
                        </mat-error>
                        <mat-error
                            *ngIf="sisForm.controls['familyName'].touched && sisForm.controls['familyName'].errors?.['pattern'] ">
                            Only Alphabets are allowed
                        </mat-error>
                        <mat-error
                            *ngIf="sisForm.controls['familyName'].touched && sisForm.controls['familyName'].errors?.['minlength'] ">
                            Maximum limit reached
                        </mat-error>
                    </mat-form-field>
                </div>

                <div class="inputParent">
                    <mat-label class="inputLabel">Gender<span class="red">*</span></mat-label>

                    <mat-form-field appearance="outline" class="input">
                        <mat-select formControlName="gender">
                            <mat-option value="">Please Select</mat-option>
                            <mat-option [value]="'male'">
                                Male
                            </mat-option>
                            <mat-option [value]="'female'">
                                Female
                            </mat-option>
                        </mat-select>
                        <mat-error *ngIf="sisForm.controls['gender'].touched && sisForm.controls['gender'].errors ">
                            Select Gender
                        </mat-error>
                    </mat-form-field>
                </div>

                <div class="inputParent">
                    <mat-label class="inputLabel">Marital Status<span class="red">*</span></mat-label>
                    <mat-form-field appearance="outline" class="input">
                        <mat-select formControlName="MaritalStatus">
                            <mat-option value="">Please Select</mat-option>
                            <mat-option [value]="'unmarried'">
                                Unmarried
                            </mat-option>
                            <mat-option [value]="'married'">
                                Married
                            </mat-option>
                            <mat-option [value]="'divorced'">
                                Divorced
                            </mat-option>
                            <mat-option [value]="'widowed'">
                                Widowed
                            </mat-option>
                        </mat-select>
                        <mat-error
                            *ngIf="sisForm.controls['MaritalStatus'].touched && sisForm.controls['MaritalStatus'].errors ">
                            Select Marital Status
                        </mat-error>
                    </mat-form-field>
                </div>

                <div class="inputParent disable-cursor ngx-mat-section" id="phone-section">
                    <mat-label class="inputLabel">Phone Number/Mobile Number<span class="red">*</span></mat-label>
                    <mat-form-field appearance="outline" class="input disable-pointer-events">
                        <ngx-mat-intl-tel-input [preferredCountries]="['in']" name="primaryPhone"
                            formControlName="phoneNum"></ngx-mat-intl-tel-input>
                        <mat-error
                            *ngIf="sisForm.controls['phoneNum'].touched && sisForm.controls['phoneNum'].errors?.['required'] ">
                            Phone Number is required
                        </mat-error>
                        <mat-error
                            *ngIf="sisForm.controls['phoneNum'].touched && sisForm.controls['phoneNum'].errors?.['maxlength']">
                            Phone Number Limit Exceeded
                        </mat-error>
                    </mat-form-field>
                </div>

                <div class="inputParent ngx-mat-section">
                    <mat-label class="inputLabel">Alternate Phone/Mobile Number<span style="color:red">(if
                            available)</span></mat-label>
                    <mat-form-field appearance="outline" class="input">
                        <ngx-mat-intl-tel-input [preferredCountries]="['in']" name="alternatePhone"
                            formControlName="AlternatePhoneNo"
                            placeholder="Enter Mobile Number"></ngx-mat-intl-tel-input>
                        <mat-error
                            *ngIf="sisForm.controls['AlternatePhoneNo'].touched && sisForm.controls['AlternatePhoneNo'].errors?.['maxlength']">
                            Phone Number Limit Exceeded
                        </mat-error>
                    </mat-form-field>
                </div>

                <div class="inputParent">
                    <mat-label class="inputLabel">Date of Birth <span class="red">*</span></mat-label>
                    <mat-form-field class="dob" appearance="outline" class="input">
                        <input formControlName="dob" matInput [matDatepicker]="picker" [max]="dobMaxDate" readonly>
                        <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
                        <mat-datepicker #picker></mat-datepicker>
                        <mat-error *ngIf="sisForm.controls['dob'].touched && sisForm.controls['dob'].errors ">
                            DOB is required
                        </mat-error>
                    </mat-form-field>
                </div>

                <div class="inputParent">
                    <mat-label class="inputLabel">Country of Birth<span class="red">*</span></mat-label>
                    <mat-form-field appearance="outline" class="input">
                        <mat-select formControlName="CountryOfBirth" placeholder="Please Select">
                            <mat-option>
                                <ngx-mat-select-search [formControl]="countryOfBirthFilterCtrl"
                                    placeholderLabel="Search Country of Birth..."
                                    noEntriesFoundLabel="No results found"></ngx-mat-select-search>
                            </mat-option>
                            <mat-option *ngFor="let country of countriesOfBithFilteredList" [value]="country.id">
                                {{country.name}}
                            </mat-option>
                        </mat-select>
                        <mat-error
                            *ngIf="sisForm.controls['CountryOfBirth'].touched && sisForm.controls['CountryOfBirth'].errors ">
                            Select Country of birth
                        </mat-error>
                    </mat-form-field>
                </div>

                <div class="inputParent">
                    <mat-label class="inputLabel">Place of Birth as it appears in passport<span
                            class="red">*</span></mat-label>
                    <mat-form-field appearance="outline" class="input">
                        <input matInput type="text" formControlName="PlaceofBirth" placeholder="Enter Place of Birth">
                        <mat-error
                            *ngIf="sisForm.controls['PlaceofBirth'].touched && sisForm.controls['PlaceofBirth'].errors?.['required'] ">
                            Place of Birth is required
                        </mat-error>
                        <mat-error
                            *ngIf="sisForm.controls['PlaceofBirth'].touched && sisForm.controls['PlaceofBirth'].errors?.['pattern'] ">
                            Only Alphabets are allowed
                        </mat-error>
                        <mat-error
                            *ngIf="sisForm.controls['PlaceofBirth'].touched && sisForm.controls['PlaceofBirth'].errors?.['maxlength'] ">
                            Maximum limit reached
                        </mat-error>
                    </mat-form-field>
                </div>

                <div class="inputParent email-section">
                    <mat-label class="inputLabel">Email <span class="red">*</span></mat-label>
                    <mat-form-field appearance="outline" class="input">
                        <input matInput type="email" formControlName="email" placeholder="Enter Email"
                            (keydown)="preventSpace($event)" readonly="true" class="disable-cursor disable-section">
                        <mat-error
                            *ngIf="sisForm.controls['email'].touched && sisForm.controls['email'].errors?.['required'] ">
                            Email is required
                        </mat-error>
                        <mat-error *ngIf="sisForm.controls['email'].errors?.['pattern'] ">
                            Email is not Valid
                        </mat-error>
                        <mat-error *ngIf="sisForm.controls['email'].errors?.['maxlength'] ">
                            Maximum limit reached
                        </mat-error>
                    </mat-form-field>
                </div>

                <div class="inputParent">
                    <mat-label class="inputLabel">Alternate Email</mat-label>
                    <mat-form-field appearance="outline" class="input">
                        <input matInput formControlName="AlternateEmailID" placeholder="Enter Email"
                            (keydown)="preventSpace($event)">
                        <mat-error
                            *ngIf="sisForm.controls['AlternateEmailID'].touched && sisForm.controls['AlternateEmailID'].errors?.['pattern'] ">
                            Email is not Valid
                        </mat-error>
                        <mat-error
                            *ngIf="sisForm.controls['AlternateEmailID'].touched && sisForm.controls['AlternateEmailID'].errors?.['maxlength'] ">
                            Maximum limit reached
                        </mat-error>
                    </mat-form-field>
                </div>

                <div class="inputParent">
                    <mat-label class="inputLabel">Nationality <span class="red">*</span></mat-label>
                    <mat-form-field appearance="outline" class="input">
                        <mat-select formControlName="nationalityId" placeholder="Please Select">
                            <mat-option>
                                <ngx-mat-select-search [formControl]="nationalityFilterCtrl"
                                    placeholderLabel="Search Nationality..."
                                    noEntriesFoundLabel="No results found"></ngx-mat-select-search>
                            </mat-option>
                            <mat-option *ngFor="let nationality of nationalitiesFilteredList" [value]="nationality.id">
                                {{nationality.name}}
                            </mat-option>
                        </mat-select>
                        <mat-error
                            *ngIf="sisForm.controls['nationalityId'].touched && sisForm.controls['nationalityId'].errors ">
                            Select Nationality
                        </mat-error>
                    </mat-form-field>
                </div>

                <div class="inputParent">
                    <mat-label class="inputLabel">What is your mother tongue/native language?</mat-label>
                    <mat-form-field appearance="outline" class="input">
                        <mat-select formControlName="NativeLanguageId" placeholder="Please Select">
                            <mat-option>
                                <ngx-mat-select-search [formControl]="motherTongueFilterCtrl"
                                    placeholderLabel="Search Mother Tongue..."
                                    noEntriesFoundLabel="No results found"></ngx-mat-select-search>
                            </mat-option>
                            <mat-option *ngFor="let motherTongue of motherTonguesFilteredList"
                                [value]="motherTongue.id">
                                {{motherTongue.name}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>

                <div class="inputParent">
                    <mat-label class="inputLabel">Name as it appears on passport</mat-label>
                    <mat-form-field appearance="outline" class="input">
                        <input matInput formControlName="nameInPassport" placeholder="Enter Name"
                            formControlName="nameInPassport">
                        <mat-error
                            *ngIf="sisForm.controls['nameInPassport'].touched && sisForm.controls['nameInPassport'].errors?.['pattern'] ">
                            Only Alphabets are allowed
                        </mat-error>
                        <mat-error
                            *ngIf="sisForm.controls['nameInPassport'].touched && sisForm.controls['nameInPassport'].errors?.['maxlength'] ">
                            Maximum limit reached
                        </mat-error>
                    </mat-form-field>
                </div>

                <div class="inputParent">
                    <mat-label class="inputLabel">Passport Issue Location</mat-label>
                    <mat-form-field appearance="outline" class="input">
                        <mat-select formControlName="passportIssueCountryId" placeholder="Please Select">
                            <mat-option>
                                <ngx-mat-select-search [formControl]="passportIssueLocationFilterCtrl"
                                    placeholderLabel="Search Passport Issue Location..."
                                    noEntriesFoundLabel="No results found"></ngx-mat-select-search>
                            </mat-option>
                            <mat-option *ngFor="let location of passportIssueLocationFilteredList"
                                [value]="location.id">
                                {{location.name}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>

                <div class="inputParent">
                    <mat-label class="inputLabel">Passport Number</mat-label>
                    <mat-form-field appearance="outline" class="input">
                        <input matInput type="text" placeholder="Enter Passport Number" formControlName="PassportNumber"
                            (keydown)="preventSpace($event)">
                    </mat-form-field>
                </div>

                <div class="inputParent">
                    <mat-label class="inputLabel">Issue Date</mat-label>
                    <mat-form-field appearance="outline" class="input">
                        <input matInput [matDatepicker]="issueDate" placeholder="Issue Date"
                            formControlName="PassportIssuedDate" [max]="dobMaxDate" readonly>
                        <mat-datepicker-toggle matIconSuffix [for]="issueDate"></mat-datepicker-toggle>
                        <mat-datepicker #issueDate></mat-datepicker>
                    </mat-form-field>
                </div>

                <div class="inputParent">
                    <mat-label class="inputLabel">Expiry Date</mat-label>
                    <mat-form-field appearance="outline" class="input">
                        <input matInput [matDatepicker]="expiryDate" placeholder="Expiry Date"
                            formControlName="PassportExpirationDate" [min]="sisForm.get('PassportIssuedDate').value" readonly>
                        <mat-datepicker-toggle matIconSuffix [for]="expiryDate"></mat-datepicker-toggle>
                        <mat-datepicker #expiryDate></mat-datepicker>
                        <mat-error *ngIf="sisForm.controls['PassportExpirationDate'].errors?.['passportValidator']">
                            Passport Issue Date should be less than expiration date
                        </mat-error>
                    </mat-form-field>
                </div>

                <div class="inputParent">
                    <mat-label class="inputLabel">Religion <span class="red">*</span></mat-label>
                    <mat-form-field appearance="outline" class="input">
                        <input type="text" formControlName="Religion" matInput placeholder="Enter Religion">
                        <mat-error
                            *ngIf="sisForm.controls['Religion'].touched && sisForm.controls['Religion'].errors?.['required']">
                            Religion is required
                        </mat-error>
                    </mat-form-field>
                    <mat-checkbox id="isNotSharingReligion" (click)="toggleReligion($event)"
                        formControlName="isNotSharingReligion" color="primary">Do not want to share
                        Religion</mat-checkbox>
                </div>

            </form>
        </fieldset>

        <div class="mb-1">
            <button mat-raised-button color="primary" [ngClass]="sisForm.invalid?'disabledBtn':'enabledBtn'"
                [disabled]="sisForm.invalid" class="saveNextBtn" (click)="saveAndNext()" type="button">Save &
                Next</button>
        </div>
    </div>

    <div *ngIf="isLgScreen">
        <form [formGroup]="sisForm" class="personalForm">
            <div class="inputParent">
                <mat-label class="inputLabel">Title<span class="red">*</span></mat-label>
                <mat-form-field appearance="outline" class="input">
                    <mat-select formControlName="Title">
                        <mat-option value="">Please Select</mat-option>
                        <mat-option [value]="'Mr.'"> Mr.</mat-option>
                        <mat-option [value]="'Mrs.'"> Mrs.</mat-option>
                        <mat-option [value]="'Miss'"> Miss</mat-option>
                        <mat-option [value]="'Ms.'"> Ms.</mat-option>
                    </mat-select>
                    <mat-error *ngIf="sisForm.controls['Title'].touched && sisForm.controls['Title'].errors">
                        Title is required</mat-error>
                </mat-form-field>
            </div>

            <div class="inputParent">
                <mat-label class="inputLabel">First Name<span class="red">*</span></mat-label>
                <mat-form-field appearance="outline" class="input">
                    <input matInput formControlName="firstName" placeholder="Enter First Name">
                    <mat-error
                        *ngIf="sisForm.controls['firstName'].touched && sisForm.controls['firstName'].errors?.['required'] ">
                        First Name is required
                    </mat-error>
                    <mat-error *ngIf="sisForm.controls['firstName'].errors?.['pattern'] ">
                        Only Alphabets are allowed
                    </mat-error>
                    <mat-error *ngIf="sisForm.controls['firstName'].errors?.['maxlength'] ">
                        Maximum limit reached
                    </mat-error>
                </mat-form-field>
            </div>

            <div class="inputParent">
                <mat-label class="inputLabel">Last Name<span class="red">*</span></mat-label>
                <mat-form-field appearance="outline" class="input">
                    <input matInput formControlName="familyName" placeholder="Enter Last Name">
                    <mat-error
                        *ngIf="sisForm.controls['familyName'].touched && sisForm.controls['familyName'].errors?.['required']">
                        Last Name is required
                    </mat-error>
                    <mat-error
                        *ngIf="sisForm.controls['familyName'].touched && sisForm.controls['familyName'].errors?.['pattern'] ">
                        Only Alphabets are allowed
                    </mat-error>
                    <mat-error
                        *ngIf="sisForm.controls['familyName'].touched && sisForm.controls['familyName'].errors?.['maxlength'] ">
                        Maximum limit reached
                    </mat-error>
                </mat-form-field>
            </div>

            <div class="inputParent">
                <mat-label class="inputLabel">Gender<span class="red">*</span></mat-label>
                <mat-form-field appearance="outline" class="input">
                    <mat-select formControlName="gender">
                        <mat-option value="">Please Select</mat-option>
                        <mat-option [value]="'male'">
                            Male
                        </mat-option>
                        <mat-option [value]="'female'">
                            Female
                        </mat-option>
                    </mat-select>
                    <mat-error *ngIf="sisForm.controls['gender'].touched && sisForm.controls['gender'].errors ">
                        Select Gender
                    </mat-error>
                </mat-form-field>
            </div>

            <div class="inputParent">
                <mat-label class="inputLabel">Marital Status<span class="red">*</span></mat-label>
                <mat-form-field appearance="outline" class="input">
                    <mat-select formControlName="MaritalStatus">
                        <mat-option value="">Please Select</mat-option>
                        <mat-option [value]="'unmarried'">
                            Unmarried
                        </mat-option>
                        <mat-option [value]="'married'">
                            Married
                        </mat-option>
                        <mat-option [value]="'divorced'">
                            Divorced
                        </mat-option>
                        <mat-option [value]="'widowed'">
                            Widowed
                        </mat-option>
                    </mat-select>
                    <mat-error
                        *ngIf="sisForm.controls['MaritalStatus'].touched && sisForm.controls['MaritalStatus'].errors ">
                        Select Marital Status
                    </mat-error>
                </mat-form-field>
            </div>

            <div class="inputParent disable-cursor ngx-mat-section" id="phone-section">
                <mat-label class="inputLabel">Phone Number/Mobile Number<span class="red">*</span></mat-label>
                <mat-form-field appearance="outline" class="input disable-pointer-events">
                    <ngx-mat-intl-tel-input [preferredCountries]="['in']" name="primaryPhone"
                        formControlName="phoneNum"></ngx-mat-intl-tel-input>
                    <mat-error
                        *ngIf="sisForm.controls['phoneNum'].touched && sisForm.controls['phoneNum'].errors?.['required'] ">
                        Phone Number is required
                    </mat-error>
                    <mat-error
                        *ngIf="sisForm.controls['phoneNum'].touched && sisForm.controls['phoneNum'].errors?.['maxlength']">
                        Phone Number Limit Exceeded
                    </mat-error>
                </mat-form-field>
            </div>

            <div class="inputParent ngx-mat-section">
                <mat-label class="inputLabel">Alternate Phone/Mobile Number<span style="color:red">(if
                        available)</span></mat-label>
                <mat-form-field appearance="outline" class="input">
                    <ngx-mat-intl-tel-input [preferredCountries]="['in']" name="alternatePhone"
                        formControlName="AlternatePhoneNo" placeholder="Enter Mobile Number"></ngx-mat-intl-tel-input>
                    <mat-error
                        *ngIf="sisForm.controls['AlternatePhoneNo'].touched && sisForm.controls['AlternatePhoneNo'].errors?.['maxlength']">
                        Phone Number Limit Exceeded
                    </mat-error>
                </mat-form-field>
            </div>

            <div class="inputParent">
                <mat-label class="inputLabel">Date of Birth<span class="red">*</span></mat-label>
                <mat-form-field class="dob" appearance="outline" class="input">
                    <input formControlName="dob" matInput [matDatepicker]="picker" [max]="dobMaxDate" readonly>
                    <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
                    <mat-datepicker #picker></mat-datepicker>
                    <mat-error *ngIf="sisForm.controls['dob'].touched && sisForm.controls['dob'].errors ">
                        DOB is required
                    </mat-error>
                </mat-form-field>
            </div>

            <div class="inputParent">
                <mat-label class="inputLabel">Country of Birth<span class="red">*</span></mat-label>
                <mat-form-field appearance="outline" class="input">
                    <mat-select formControlName="CountryOfBirth" placeholder="Please Select">
                        <mat-option>
                            <ngx-mat-select-search [formControl]="countryOfBirthFilterCtrl"
                                placeholderLabel="Search Country of Birth..."
                                noEntriesFoundLabel="No results found"></ngx-mat-select-search>
                        </mat-option>
                        <mat-option *ngFor="let country of countriesOfBithFilteredList" [value]="country.id">
                            {{country.name}}
                        </mat-option>
                    </mat-select>
                    <mat-error
                        *ngIf="sisForm.controls['CountryOfBirth'].touched && sisForm.controls['CountryOfBirth'].errors ">
                        Country of birth is required
                    </mat-error>
                </mat-form-field>
            </div>

            <div class="inputParent">
                <mat-label class="inputLabel">Place of Birth as it appears in passport<span
                        class="red">*</span></mat-label>
                <mat-form-field appearance="outline" class="input">
                    <input matInput type="text" formControlName="PlaceofBirth" placeholder="Enter Place of Birth">
                    <mat-error
                        *ngIf="sisForm.controls['PlaceofBirth'].touched && sisForm.controls['PlaceofBirth'].errors?.['required'] ">
                        Place of Birth is required
                    </mat-error>
                    <mat-error
                        *ngIf="sisForm.controls['PlaceofBirth'].touched && sisForm.controls['PlaceofBirth'].errors?.['pattern'] ">
                        Only Alphabets are allowed
                    </mat-error>
                    <mat-error
                        *ngIf="sisForm.controls['PlaceofBirth'].touched && sisForm.controls['PlaceofBirth'].errors?.['maxlength'] ">
                        Maximum limit reached
                    </mat-error>
                </mat-form-field>
            </div>

            <div class="inputParent email-section">
                <mat-label class="inputLabel">Email<span class="red">*</span></mat-label>
                <mat-form-field appearance="outline" class="input">
                    <input matInput type="email" formControlName="email" placeholder="Enter Email"
                        (keydown)="preventSpace($event)" readonly="true" class="disable-cursor disable-section">
                    <mat-error
                        *ngIf="sisForm.controls['email'].touched && sisForm.controls['email'].errors?.['required'] ">
                        Email is required
                    </mat-error>
                    <mat-error *ngIf="sisForm.controls['email'].errors?.['pattern'] ">
                        Email is not Valid
                    </mat-error>
                    <mat-error *ngIf="sisForm.controls['email'].errors?.['maxlength'] ">
                        Maximum limit reached
                    </mat-error>
                </mat-form-field>
            </div>

            <div class="inputParent">
                <mat-label class="inputLabel">Alternate Email</mat-label>
                <mat-form-field appearance="outline" class="input">
                    <input matInput placeholder="Enter Email" formControlName="AlternateEmailID"
                        (keydown)="preventSpace($event)">
                    <mat-error
                        *ngIf="sisForm.controls['AlternateEmailID'].touched && sisForm.controls['AlternateEmailID'].errors?.['pattern'] ">
                        Email is not Valid
                    </mat-error>
                    <mat-error
                        *ngIf="sisForm.controls['AlternateEmailID'].touched && sisForm.controls['AlternateEmailID'].errors?.['maxlength'] ">
                        Maximum limit reached
                    </mat-error>
                </mat-form-field>
            </div>

            <div class="inputParent">
                <mat-label class="inputLabel">Nationality <span class="red">*</span></mat-label>
                <mat-form-field appearance="outline" class="input">
                    <mat-select formControlName="nationalityId" placeholder="Please Select">
                        <mat-option>
                            <ngx-mat-select-search [formControl]="nationalityFilterCtrl"
                                placeholderLabel="Search Nationality..."
                                noEntriesFoundLabel="No results found"></ngx-mat-select-search>
                        </mat-option>
                        <mat-option *ngFor="let nationality of nationalitiesFilteredList" [value]="nationality.id">
                            {{nationality.name}}
                        </mat-option>
                    </mat-select>
                    <mat-error
                        *ngIf="sisForm.controls['nationalityId'].touched && sisForm.controls['nationalityId'].errors ">
                        Select Nationality
                    </mat-error>
                </mat-form-field>
            </div>

            <div class="inputParent">
                <mat-label class="inputLabel">What is your mother tongue/native language?</mat-label>
                <mat-form-field appearance="outline" class="input">
                    <mat-select formControlName="NativeLanguageId" placeholder="Please Select">
                        <mat-option>
                            <ngx-mat-select-search [formControl]="motherTongueFilterCtrl"
                                placeholderLabel="Search Mother Tongue..."
                                noEntriesFoundLabel="No results found"></ngx-mat-select-search>
                        </mat-option>
                        <mat-option *ngFor="let motherTongue of motherTonguesFilteredList" [value]="motherTongue.id">
                            {{motherTongue.name}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>

            <div class="inputParent">
                <mat-label class="inputLabel">Name as it appears on passport</mat-label>
                <mat-form-field appearance="outline" class="input">
                    <input matInput formControlName="nameInPassport" placeholder="Enter Name">
                    <mat-error
                        *ngIf="sisForm.controls['nameInPassport'].touched && sisForm.controls['nameInPassport'].errors?.['pattern'] ">
                        Only Alphabets are allowed
                    </mat-error>
                    <mat-error
                        *ngIf="sisForm.controls['nameInPassport'].touched && sisForm.controls['nameInPassport'].errors?.['maxlength'] ">
                        Maximum limit reached
                    </mat-error>
                </mat-form-field>
            </div>

            <div class="inputParent">
                <mat-label class="inputLabel">Passport Issue Location</mat-label>
                <mat-form-field appearance="outline" class="input">
                    <mat-select formControlName="passportIssueCountryId" placeholder="Please Select">
                        <mat-option>
                            <ngx-mat-select-search [formControl]="passportIssueLocationFilterCtrl"
                                placeholderLabel="Search Passport Issue Location..."
                                noEntriesFoundLabel="No results found"></ngx-mat-select-search>
                        </mat-option>
                        <mat-option *ngFor="let location of passportIssueLocationFilteredList" [value]="location.id">
                            {{location.name}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>

            <div class="inputParent">
                <mat-label class="inputLabel">Passport Number</mat-label>
                <mat-form-field appearance="outline" class="input">
                    <input matInput type="text" placeholder="Enter Passport Number" formControlName="PassportNumber"
                        (keydown)="preventSpace($event)">
                </mat-form-field>
            </div>

            <div class="inputParent">
                <mat-label class="inputLabel">Issue Date</mat-label>
                <mat-form-field appearance="outline" class="input">
                    <input matInput [matDatepicker]="issueDate" placeholder="Issue Date"
                        formControlName="PassportIssuedDate" [max]="dobMaxDate" readonly>
                    <mat-datepicker-toggle matIconSuffix [for]="issueDate"></mat-datepicker-toggle>
                    <mat-datepicker #issueDate></mat-datepicker>
                </mat-form-field>
            </div>

            <div class="inputParent">
                <mat-label class="inputLabel">Expiry Date</mat-label>
                <mat-form-field appearance="outline" class="input">
                    <input matInput [matDatepicker]="expiryDate" placeholder="Expiry Date"
                        formControlName="PassportExpirationDate" [min]="sisForm.get('PassportIssuedDate').value" readonly>
                    <mat-datepicker-toggle matIconSuffix [for]="expiryDate"></mat-datepicker-toggle>
                    <mat-datepicker #expiryDate></mat-datepicker>
                    <mat-error *ngIf="sisForm.controls['PassportExpirationDate'].errors?.['passportValidator']">
                        Passport Issue Date should be less than expiration date
                    </mat-error>
                </mat-form-field>
            </div>


            <div class="inputParent">
                <mat-label class="inputLabel">Religion<span class="red">*</span></mat-label>
                <mat-form-field appearance="outline" class="input">
                    <input type="text" formControlName="Religion" matInput placeholder="Enter Religion">
                    <mat-error
                        *ngIf="sisForm.controls['Religion'].touched && sisForm.controls['Religion'].errors?.['required'] ">
                        Religion is required
                    </mat-error>
                </mat-form-field>
                <mat-checkbox id="isNotSharingReligion" (click)="toggleReligion($event)"
                    formControlName="isNotSharingReligion" color="primary">Do not want to share Religion</mat-checkbox>
            </div>
        </form>
    </div>
</div>