<app-sis-header *ngIf="!isLgScreen" class="sis-header"></app-sis-header>
<div id="personalDetailsPageOne" class="container" [ngClass]="!isLgScreen? 'mt-6':''">

    <div class="text-left mb-2" *ngIf="isLgScreen">
        <form [formGroup]="sisQuestionnaire">
            <h3 style="font-weight: bold;">Do you have disability?<span class="error"> *</span></h3>

            <mat-radio-group aria-label="Select an option" formControlName="DisabilityAnswered">
                <mat-radio-button [value]="true" color="primary"><span
                        style="font-weight: bold;">Yes</span></mat-radio-button>
                <mat-radio-button [value]="false" color="primary"><span
                        style="font-weight: bold;">No</span></mat-radio-button>
            </mat-radio-group>
            <br>
            <div class="mt-1" [ngClass]="isLgScreen? 'textFields':''" *ngIf="sisQuestionnaire.value.DisabilityAnswered">
                <div class="mb-1 mr-1">If YES, please state the name: </div>
                <mat-form-field appearance="outline" class="input">
                    <input matInput placeholder="Name" formControlName="DisabilityState">
                </mat-form-field>
            </div>



            <h3 style="font-weight: bold;">Have you ever been committed, been arrested for or been charged with any
                criminal offence?<span class="error"> *</span></h3>


            <mat-radio-group aria-label="Select an option" formControlName="CriminalConvictions">
                <mat-radio-button [value]="true" color="primary"><span
                        style="font-weight: bold;">Yes</span></mat-radio-button>
                <mat-radio-button [value]="false" color="primary"><span
                        style="font-weight: bold;">No</span></mat-radio-button>
            </mat-radio-group>
            <br>
            <div class="mt-1" [ngClass]="isLgScreen? 'textFields':''"
                *ngIf="sisQuestionnaire.value.CriminalConvictions">
                <div class="mb-1 mr-1">If YES, please explain: </div>
                <mat-form-field appearance="outline" class="input">
                    <textarea matInput placeholder="Explain Here..." formControlName="CrimeDetails"></textarea>
                </mat-form-field>
            </div>
        </form>
    </div>

    <fieldset *ngIf="!isLgScreen" fxLayoutGap="12px" class="mb-2">
        <form [formGroup]="sisQuestionnaire">
            <h3 style="font-weight: bold;">Do you have disability?<span class="error"> *</span></h3>

            <mat-radio-group aria-label="Select an option" formControlName="DisabilityAnswered">
                <mat-radio-button [value]="true" color="primary"><span
                        style="font-weight: bold;">Yes</span></mat-radio-button>
                <mat-radio-button [value]="false" color="primary"><span
                        style="font-weight: bold;">No</span></mat-radio-button>
            </mat-radio-group>
            <br>
            <div class="mt-1" [ngClass]="isLgScreen? 'textFields':''" *ngIf="sisQuestionnaire.value.DisabilityAnswered">
                <div class="mb-1 mr-1">If YES, please state the name: </div>
                <mat-form-field appearance="outline" class="input" *ngIf="sisQuestionnaire.value.DisabilityAnswered">
                    <input matInput placeholder="Name" formControlName="DisabilityState">
                </mat-form-field>
            </div>



            <h3 style="font-weight: bold;">Have you ever been committed, been arrested for or been charged with any
                criminal offence?<span class="error"> *</span></h3>


            <mat-radio-group aria-label="Select an option" formControlName="CriminalConvictions">
                <mat-radio-button [value]="true" color="primary"><span
                        style="font-weight: bold;">Yes</span></mat-radio-button>
                <mat-radio-button [value]="false" color="primary"><span
                        style="font-weight: bold;">No</span></mat-radio-button>
            </mat-radio-group>
            <br>
            <div class="mt-1" [ngClass]="isLgScreen? 'textFields':''"
                *ngIf="sisQuestionnaire.value.CriminalConvictions">
                <div class="mb-1 mr-1">If YES, please explain: </div>
                <mat-form-field appearance="outline" class="input">
                    <textarea matInput placeholder="Explain Here..." formControlName="CrimeDetails"></textarea>
                </mat-form-field>
            </div>
        </form>
    </fieldset>

    <fieldset fxLayoutGap="12px" [ngClass]="!isLgScreen? 'text-center':'text-left'">
        <legend>Declaration<span class="error"> *</span></legend>
        <mat-checkbox color="primary" id="declaration" [(ngModel)]="declarationCheckbox"
            (click)="toggleDeclaration($event)"></mat-checkbox> <span style="font-weight: 400;">I hereby declare that
            the
            information given here is correct to the best of my knowledge, and am responsible if any information given
            in
            this application proves to be incorrect.
            I consent to receive digital and telecommunications from SI-UK regarding my university application services.
            I
            understand I may change my preferences or opt out at any time using the unsubscribe link on all SI-UK
            emails.
            View Privacy Policy.</span>
    </fieldset>

    <div class="mb-1 mt-1" [ngClass]="!isLgScreen? 'text-center':'text-right'">
        <button mat-raised-button color="primary" [ngClass]="!isLgScreen? 'width-100':''" [disabled]="!isSubmitBtnValid"
            (click)="saveData(true)" type="button">Submit</button>
        <p><strong>Note:</strong>&nbsp;Please ensure that you have filled all the mandatory details <span
                class="error">(* marked)</span></p>
    </div>
</div>