import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { SharedService } from '../../shared/shared.service';
import { SisService } from '../sis.service';
import { debounceTime, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { PassportValidator } from '../../shared/custom validator/passportValidator';
import { SisPersonalDetailsDataSet } from '../sis-models/sis-personal-details-data-set';

@Component({
  selector: 'app-sis-personal-details-one',
  templateUrl: './sis-personal-details-one.component.html',
  styleUrls: ['./sis-personal-details-one.component.scss']
})
export class SisPersonalDetailsOneComponent implements OnInit {
  public sisForm: FormGroup;
  countriesList: any = [];
  religionsList: any = [];
  nationalitiesList: any = [];
  motherTongueList: any = [];
  passportLocationsList: any = [];
  isLgScreen: boolean = false;
  studentId: number;
  userId: number;
  religionOldValue: string;
  sectionIndex: number = 1;
  public dobMaxDate = new Date();
  protected _onDestroy = new Subject<void>();

  public countryOfBirthFilterCtrl: FormControl = new FormControl();
  countriesOfBithFilteredList: Array<any> = [];

  public motherTongueFilterCtrl: FormControl = new FormControl();
  motherTonguesFilteredList: Array<any> = [];

  public nationalityFilterCtrl: FormControl = new FormControl();
  nationalitiesFilteredList: Array<any> = [];

  public passportIssueLocationFilterCtrl: FormControl = new FormControl();
  passportIssueLocationFilteredList: Array<any> = [];

  constructor(private router: Router, private fb: FormBuilder, private toastr: ToastrService,
    private activate: ActivatedRoute, private sharedService: SharedService, private sisService: SisService) {
    this.initializeForm();
  }

  ngOnInit(): void {
    this.studentId = this.sharedService.getStudentId();
    this.userId = this.sharedService.getUserId();
    this.isLgScreen = this.sharedService.isLgScreen();
    const bodyElement = document.body;
    bodyElement.classList.remove('gradientBG');
    this.sharedService.scrollToTop();
    this.getCountriesList();
    this.getNationalitiesList();
    this.getMotherTongueList();
    this.getPassportLocations();
    this.getPersonalDetails();

    ///////// Auto Save Form //////////
    this.sisForm.valueChanges.pipe(debounceTime(1_000)).subscribe(x => {
      console.log('save personal 1 list');
      this.saveData();
    })
    ///////// Auto Save Form //////////

    /////////// To search Country of Birth /////////
    this.countryOfBirthFilterCtrl.valueChanges
      .pipe(takeUntil(this._onDestroy))
      .subscribe(() => {
        this.filterCountryOfBirth();
      });
    /////////// To search Country of Birth //////////

    /////////// To search Mother Tongue ////////////
    this.motherTongueFilterCtrl.valueChanges
      .pipe(takeUntil(this._onDestroy))
      .subscribe(() => {
        this.filterMotherTongue();
      });
    /////////// To search Mother Tongue ///////////

    /////////// To search Nationality ////////////
    this.nationalityFilterCtrl.valueChanges
      .pipe(takeUntil(this._onDestroy))
      .subscribe(() => {
        this.filterNationality();
      });
    /////////// To search Nationality /////////////

    /////////// To search Passport Issue Location /////////
    this.passportIssueLocationFilterCtrl.valueChanges
      .pipe(takeUntil(this._onDestroy))
      .subscribe(() => {
        this.filterPassportIssueLocation();
      });
    /////////// To search Passport Issue Location /////////
  }

  ngOnDestroy() {
    this._onDestroy.next();
    this._onDestroy.complete();
  }

  initializeForm() {
    this.sisForm = this.fb.group({
      Title: ['', [Validators.required]],
      firstName: ['', [Validators.required, Validators.maxLength(50), Validators.pattern("^[a-zA-Z ]*$")]],
      familyName: ['', [Validators.required, Validators.maxLength(50), Validators.pattern('^[a-zA-Z ]*$')]],
      gender: ['', [Validators.required]],
      MaritalStatus: ['', [Validators.required]],
      Religion: ['', [Validators.required, Validators.maxLength(50), Validators.pattern('^[a-zA-Z ]*$')]],
      phoneNum: ['', [Validators.required, Validators.maxLength(15)]],
      dob: ['', [Validators.required]],
      email: ['', [Validators.required, Validators.maxLength(50)]],
      nationalityId: ['', [Validators.required]],
      CountryOfBirth: ['', [Validators.required]],
      nameInPassport: ['', [Validators.maxLength(50), Validators.pattern('^[a-zA-Z ]*$')]],
      PassportNumber: [''],
      isNotSharingReligion: [false],
      passportIssueCountryId: [''],
      PassportIssuedDate: [''],
      PassportExpirationDate: [''],
      NativeLanguageId: [''],
      PlaceofBirth: ['', [Validators.required, Validators.maxLength(50), Validators.pattern('^[a-zA-Z ]*$')]],
      AlternateEmailID: ['', [Validators.maxLength(50), Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$")]],
      AlternatePhoneNo: ['', [Validators.maxLength(15)]]
    }, {
      validator: PassportValidator("PassportIssuedDate", "PassportExpirationDate")
    });

  }

  submitData() {
    this.toastr.success("Record Successfully Saved");
  }

  preventSpace(event: KeyboardEvent) {
    if (event.code === "Space") {
      event.preventDefault();
    }
  }

  saveData() {
    const obj: any = JSON.parse(this.sisService.getDefaultValuesPersonalDetailsFromLocal());
    let dataObj: SisPersonalDetailsDataSet = { ...obj };

    dataObj.dob = this.sisForm.value.dob ? this.sharedService.formatDate(this.sisForm.value.dob) : '';
    dataObj.PassportExpirationDate = this.sisForm.value.PassportExpirationDate ? this.sharedService.formatDate(this.sisForm.value.PassportExpirationDate) : '';
    dataObj.PassportIssuedDate = this.sisForm.value.PassportIssuedDate ? this.sharedService.formatDate(this.sisForm.value.PassportIssuedDate) : '';
    dataObj.Title = this.sisForm.value.Title ? this.sisForm.value.Title : '';
    dataObj.firstName = this.sisForm.value.firstName ? this.sisForm.value.firstName : '';
    dataObj.familyName = this.sisForm.value.familyName ? this.sisForm.value.familyName : '';
    dataObj.Religion = this.sisForm.value.Religion ? this.sisForm.value.Religion : '';
    dataObj.isNotSharingReligion = this.sisForm.value.isNotSharingReligion ? this.sisForm.value.isNotSharingReligion : false;
    dataObj.CountryOfBirth = this.sisForm.value.CountryOfBirth ? this.sisForm.value.CountryOfBirth : '';
    dataObj.gender = this.sisForm.value.gender ? this.sisForm.value.gender : '';
    dataObj.MaritalStatus = this.sisForm.value.MaritalStatus ? this.sisForm.value.MaritalStatus : '';
    dataObj.email = this.sisForm.value.email ? this.sisForm.value.email : '';
    dataObj.nationalityId = this.sisForm.value.nationalityId ? this.sisForm.value.nationalityId : '';
    dataObj.nameInPassport = this.sisForm.value.nameInPassport ? this.sisForm.value.nameInPassport : '';
    dataObj.PassportNumber = this.sisForm.value.PassportNumber ? this.sisForm.value.PassportNumber : '';
    dataObj.passportIssueCountryId = this.sisForm.value.passportIssueCountryId ? this.sisForm.value.passportIssueCountryId : '';
    dataObj.NativeLanguageId = this.sisForm.value.NativeLanguageId ? this.sisForm.value.NativeLanguageId : '';
    dataObj.PlaceofBirth = this.sisForm.value.PlaceofBirth ? this.sisForm.value.PlaceofBirth : '';
    dataObj.AlternateEmailID = this.sisForm.value.AlternateEmailID ? this.sisForm.value.AlternateEmailID : '';
    dataObj.phoneNum = this.sisForm.value.phoneNum ? this.sisForm.value.phoneNum : '';
    dataObj.AlternatePhoneNo = this.sisForm.value.AlternatePhoneNo ? this.sisForm.value.AlternatePhoneNo : '';

    this.sisService.setDefaultValuesPersonalDetailsAtLocal(dataObj);

    this.sisService.saveSISData(this.sectionIndex).subscribe(() => {
      this.setPermission();
      console.log('data saved');
    })
  }

  saveAndNext() {
    this.sisService.submitSIS(this.studentId, this.sectionIndex).subscribe((res: any) => {
      if (res) {
        this.router.navigate(['/sis-personal-details-two']);
      }
    }, error => {
      this.toastr.error("Something went wrong", error);
    });
  }

  //Dropdown APIs start
  getCountriesList() {
    if (this.sisService.getCountriesListFromLocalStorage()) {
      this.countriesList = this.sisService.getCountriesListFromLocalStorage();
      this.filterCountryOfBirth();
    } else {
      this.sisService.getCountriesList().subscribe((res) => {
        let response: any = res;
        if (response && response.data) {
          this.countriesList = response.data.items;
          this.sisService.setCountriesListInLocalStorage(this.countriesList);
          this.filterCountryOfBirth();
        }
      })
    }
  }

  getReligionsList() {
    this.sisService.getReligionList().subscribe(res => {
      let response: any = res;
      if (response && response.data) {
        this.religionsList = response.data.items;
      }
    })
  }

  getNationalitiesList() {
    this.sisService.getNationalitiesList().subscribe(res => {
      let response: any = res;
      if (response && response.data) {
        this.nationalitiesList = response.data.items;
        this.filterNationality();
      }
    })
  }

  getMotherTongueList() {
    this.sisService.getMotherTongueList().subscribe(res => {
      let response: any = res;
      if (response && response.data) {
        this.motherTongueList = response.data.items;
        this.filterMotherTongue();
      }
    })
  }

  getPassportLocations() {
    this.sisService.getPassportLocationsList().subscribe(res => {
      let response: any = res;
      if (response && response.data) {
        this.passportLocationsList = response.data.items;
        this.filterPassportIssueLocation();
      }
    })
  }

  getPersonalDetails() {
    this.sisService.getSISPersonalDetails(this.studentId, this.userId).subscribe((res: any) => {
      if (res) {
        let response: any = JSON.parse(res.data);

        this.sisForm.controls['firstName'].setValue(response.firstName);
        this.sisForm.controls['familyName'].setValue(response.familyName);
        this.sisForm.controls['gender'].setValue(response.gender ? response.gender.toLowerCase() : '');
        this.sisForm.controls['email'].setValue(response.email);
        this.sisForm.controls['dob'].setValue(response.dateOfBirth ? response.dateOfBirth : response.dob);
        this.sisForm.controls['phoneNum'].setValue(response.phoneNum);
        this.sisForm.controls['CountryOfBirth'].setValue(parseInt(response.CountryOfBirth));
        this.sisForm.controls['nationalityId'].setValue(parseInt(response.nationalityId));
        this.sisForm.controls['PassportNumber'].setValue(response.PassportNumber);
        this.sisForm.controls['nameInPassport'].setValue(response.nameInPassport);
        this.sisForm.controls['isNotSharingReligion'].setValue(response.isNotSharingReligion);
        this.sisForm.controls['passportIssueCountryId'].setValue(response.passportIssueCountryId);
        this.sisForm.controls['PassportIssuedDate'].setValue(response.PassportIssuedDate);
        this.sisForm.controls['PassportExpirationDate'].setValue(response.PassportExpirationDate);
        this.sisForm.controls['NativeLanguageId'].setValue(response.NativeLanguageId);
        this.sisForm.controls['MaritalStatus'].setValue(response.MaritalStatus ? response.MaritalStatus : '');
        this.sisForm.controls['Title'].setValue(response.Title ? response.Title : '');
        this.sisForm.controls['PlaceofBirth'].setValue(response.PlaceofBirth);
        this.sisForm.controls['AlternateEmailID'].setValue(response.AlternateEmailID);
        this.sisForm.controls['AlternatePhoneNo'].setValue(response.AlternatePhoneNo);
        this.religionOldValue = response.Religion;
        if (response.isNotSharingReligion) {
          this.sisForm.controls['Religion'].setValue('');
          this.sisForm.get('Religion').disable();
        } else {
          this.sisForm.get('Religion').enable();
          this.sisForm.controls['Religion'].setValue(response.Religion);
        }
      }
    })
  }

  toggleReligion(event: any) {
    if (event.target.checked) {
      this.religionOldValue = this.sisForm.value.Religion;
      this.sisForm.controls['Religion'].setValue('');
      this.sisForm.get('Religion').disable();
    } else {
      this.sisForm.controls['Religion'].setValue(this.religionOldValue);
      this.sisForm.get('Religion').enable();
    }
  }

  setPermission() {
    if (this.sisForm.valid) {
      this.sisService.removePendingItems(this.sectionIndex);
      this.sisService.setSectionWisePermission('personalDetails1Perm', true);
    } else {
      this.sisService.setPendingItems('Personal Details', this.sectionIndex, this.sisService.returnInvalidControls(this.sisForm))
      this.sisService.setSectionWisePermission('personalDetails1Perm', false);
    }
    this.sisService.emitSubmitPermissionChangeAlert('true');
  }

  filterCountryOfBirth() {
    let search = this.countryOfBirthFilterCtrl.value;
    search = search ? search : '';
    this.countriesOfBithFilteredList = this.countriesList.filter((country: any) => country?.name?.toLowerCase().startsWith(search.toLowerCase()));
  }

  filterMotherTongue() {
    let search = this.motherTongueFilterCtrl.value;
    search = search ? search : '';
    this.motherTonguesFilteredList = this.motherTongueList.filter((country: any) => country?.name?.toLowerCase().startsWith(search.toLowerCase()));
  }

  filterNationality() {
    let search = this.nationalityFilterCtrl.value;
    search = search ? search : '';
    this.nationalitiesFilteredList = this.nationalitiesList.filter((country: any) => country?.name?.toLowerCase().startsWith(search.toLowerCase()));
  }

  filterPassportIssueLocation() {
    let search = this.passportIssueLocationFilterCtrl.value;
    search = search ? search : '';
    this.passportIssueLocationFilteredList = this.passportLocationsList.filter((country: any) => country?.name?.toLowerCase().startsWith(search.toLowerCase()));
  }
}