import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpResponse,
  HttpErrorResponse
} from '@angular/common/http';
import { SharedService } from '../modules/shared/shared.service';
import { Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';

@Injectable()
export class TokenAndErrorInterceptor implements HttpInterceptor {
  userToken: string;

  constructor(private sharedService: SharedService, private toastr: ToastrService) { }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    this.userToken = this.sharedService.getToken();
    let updatedRequest: any;
    const urlArray = request.url.split("/");
    if (urlArray[3] == 'SISForm') {
      updatedRequest = request.clone({
        setHeaders: {
          'Content-Type': 'application/json',
          Authorization: this.userToken
        }
      });
    } else {
      updatedRequest = request.clone({
        setHeaders: {
          'Content-Type': 'application/json'
        }
      });
    }
    return next.handle(updatedRequest).pipe(
      map((event: HttpEvent<any>) => {
        if (event instanceof HttpResponse) {
          //  console.log('event--->>>', event);
        }
        return event;
      }),
      catchError((error: HttpErrorResponse) => {
        if (error.status == 401) {
          // this.toastr.error("Session Timeout !!!");
          this.sharedService.logout();
        }
        return throwError(error);
      })
    );
  }
}
