<app-sis-header *ngIf="!isLgScreen" class="sis-header"></app-sis-header>
<div id="personalDetailsPageOne" class="container" [ngClass]="!isLgScreen?' mt-6':''" fxLayout="row wrap"
  fxLayout.lt-md="column">

  <div *ngIf="!isLgScreen">
    <fieldset fxLayoutGap="12px" class="mb-2">
      <legend>Personal Details</legend>

      <form [formGroup]="sisForm">
        <h3 class="personal-details-head">Permanent Address<span class="error"> *</span></h3>

        <div class="inputParent">
          <mat-label class="inputLabel">Country <span class="red">*</span></mat-label>
          <mat-form-field appearance="outline" class="input">
            <mat-select formControlName="PermanentCountryId" (ngModelChange)="getStatesListCountryWise($event, true)"
              placeholder="Please Select">
              <mat-option>
                <ngx-mat-select-search [formControl]="permanentCountryFilterCtrl" placeholderLabel="Search Country..."
                  noEntriesFoundLabel="No results found"></ngx-mat-select-search>
              </mat-option>
              <mat-option *ngFor="let country of permanentCountryFilteredList" [value]="country.id">
                {{country.name}}
              </mat-option>
            </mat-select>
            <mat-error
              *ngIf="sisForm.controls['PermanentCountryId'].touched && sisForm.controls['PermanentCountryId'].errors?.['required']">
              Select Country
            </mat-error>
          </mat-form-field>
        </div>

        <div class="inputParent">
          <mat-label class="inputLabel">Post Code <span class="red">*</span></mat-label>
          <mat-form-field appearance="outline" class="input">
            <input matInput type="text" formControlName="PermanentpostCode" placeholder="Enter Post Code">
            <mat-error
              *ngIf="sisForm.controls['PermanentpostCode'].touched && sisForm.controls['PermanentpostCode'].errors?.['required']">
              Post code is required
            </mat-error>
            <mat-error *ngIf="sisForm.controls['PermanentpostCode'].errors?.['maxlength'] ">
              Maximum limit reached
            </mat-error>
          </mat-form-field>
        </div>

        <div class="inputParent">
          <mat-label class="inputLabel">Address 1 <span class="red">*</span></mat-label>
          <mat-form-field appearance="outline" class="input">
            <input matInput formControlName="PermanentaddressLine1" placeholder="Enter Address 1">
            <mat-error
              *ngIf="sisForm.controls['PermanentaddressLine1'].touched && sisForm.controls['PermanentaddressLine1'].errors?.['required']">
              Address 1 is required
            </mat-error>
            <mat-error *ngIf="sisForm.controls['PermanentaddressLine1'].errors?.['maxlength'] ">
              Maximum limit reached
            </mat-error>
          </mat-form-field>
        </div>


        <div class="inputParent">
          <mat-label class="inputLabel">Address 2</mat-label>
          <mat-form-field appearance="outline" class="input">
            <input matInput placeholder="Enter Address 2" formControlName="PermanentaddressLine2">
            <mat-error *ngIf="sisForm.controls['PermanentaddressLine2'].errors?.['maxlength'] ">
              Maximum limit reached
            </mat-error>
          </mat-form-field>
        </div>

        <div class="inputParent">
          <mat-label class="inputLabel">State/Territory <span *ngIf="statesListForCurrent.length>0" class="red">*</span></mat-label>
          <mat-form-field appearance="outline" class="input">
            <mat-select formControlName="Permanentstate" (ngModelChange)="getCitiesListStateWise($event, true)"
              placeholder="Please Select">
              <mat-option>
                <ngx-mat-select-search [formControl]="permanentStateFilterCtrl" placeholderLabel="Search State..."
                  noEntriesFoundLabel="No results found"></ngx-mat-select-search>
              </mat-option>
              <mat-option *ngFor="let state of permanentStateFilteredList" [value]="state.id">
                {{state.name}}
              </mat-option>
            </mat-select>
            <mat-error
              *ngIf="sisForm.controls['Permanentstate'].touched && sisForm.controls['Permanentstate'].errors?.['required']">
              Select State
            </mat-error>
          </mat-form-field>
        </div>

        <div class="inputParent">
          <mat-label class="inputLabel">City <span *ngIf="citiesListForPermanent.length>0" class="red">*</span></mat-label>
          <mat-form-field appearance="outline" class="input">
            <mat-select formControlName="Permanentcity" placeholder="Please Select">
              <mat-option>
                <ngx-mat-select-search [formControl]="permanentCityFilterCtrl" placeholderLabel="Search City..."
                  noEntriesFoundLabel="No results found"></ngx-mat-select-search>
              </mat-option>
              <mat-option *ngFor="let city of permanentCityFilteredList" [value]="city.id">
                {{city.name}}
              </mat-option>
            </mat-select>
            <mat-error
              *ngIf="sisForm.controls['Permanentcity'].touched && sisForm.controls['Permanentcity'].errors?.['required']">
              Select City
            </mat-error>
          </mat-form-field>
        </div>

        <h3 style="font-weight: 500;">Current Address<span class="error"> *</span></h3>
        <div>
          <mat-checkbox (click)="setCurrentAddressAsPermanent($event)" formControlName="SameAsPermanentAddress"
            color="primary">Same As Permanent address</mat-checkbox>
        </div>

        <div class="inputParent">
          <mat-label class="inputLabel">Country <span class="red">*</span></mat-label>
          <mat-form-field appearance="outline" class="input"
            [ngClass]="sisForm.get('SameAsPermanentAddress').value?' disable-pointer-events':''">
            <mat-select formControlName="CurrentCountryId" (ngModelChange)="getStatesListCountryWise($event, false)"
              placeholder="Please Select">
              <mat-option>
                <ngx-mat-select-search [formControl]="currentCountryFilterCtrl" placeholderLabel="Search Country..."
                  noEntriesFoundLabel="No results found"></ngx-mat-select-search>
              </mat-option>
              <mat-option *ngFor="let country of currentCountryFilteredList" [value]="country.id">
                {{country.name}}
              </mat-option>
            </mat-select>
            <mat-error
              *ngIf="sisForm.controls['CurrentCountryId'].touched && sisForm.controls['CurrentCountryId'].errors?.['required']">
              Select Country
            </mat-error>
          </mat-form-field>
        </div>

        <div class="inputParent">
          <mat-label class="inputLabel">Post Code <span class="red">*</span></mat-label>
          <mat-form-field appearance="outline" class="input"
            [ngClass]="sisForm.get('SameAsPermanentAddress').value?' disable-pointer-events':''">
            <input matInput type="text" formControlName="PostCode" placeholder="Enter Post Code">
            <mat-error
              *ngIf="sisForm.controls['PostCode'].touched && sisForm.controls['PostCode'].errors?.['required']">
              Post code is required
            </mat-error>
            <mat-error *ngIf="sisForm.controls['PostCode'].errors?.['maxlength'] ">
              Maximum limit reached
            </mat-error>
          </mat-form-field>
        </div>

        <div class="inputParent">
          <mat-label class="inputLabel">Address 1 <span class="red">*</span></mat-label>
          <mat-form-field appearance="outline" class="input"
            [ngClass]="sisForm.get('SameAsPermanentAddress').value?' disable-pointer-events':''">
            <input matInput formControlName="AddressLine1" placeholder="Enter Address 1">
            <mat-error
              *ngIf="sisForm.controls['AddressLine1'].touched && sisForm.controls['AddressLine1'].errors?.['required']">
              Address 1 is required
            </mat-error>
            <mat-error *ngIf="sisForm.controls['AddressLine1'].errors?.['maxlength'] ">
              Maximum limit reached
            </mat-error>
          </mat-form-field>
        </div>


        <div class="inputParent">
          <mat-label class="inputLabel">Address 2</mat-label>
          <mat-form-field appearance="outline" class="input"
            [ngClass]="sisForm.get('SameAsPermanentAddress').value?' disable-pointer-events':''">
            <input matInput placeholder="Enter Address 2" formControlName="AddressLine2">
            <mat-error *ngIf="sisForm.controls['AddressLine2'].errors?.['maxlength'] ">
              Maximum limit reached
            </mat-error>
          </mat-form-field>
        </div>


        <div class="inputParent">
          <mat-label class="inputLabel">State/Territory <span *ngIf="statesListForCurrent.length>0" class="red">*</span></mat-label>
          <mat-form-field appearance="outline" class="input"
            [ngClass]="sisForm.get('SameAsPermanentAddress').value?' disable-pointer-events':''">
            <mat-select formControlName="StateOfResidenceId" (ngModelChange)="getCitiesListStateWise($event, false)"
              placeholder="Please Select">
              <mat-option>
                <ngx-mat-select-search [formControl]="currentStateFilterCtrl" placeholderLabel="Search State..."
                  noEntriesFoundLabel="No results found"></ngx-mat-select-search>
              </mat-option>
              <mat-option *ngFor="let state of currentStateFilteredList" [value]="state.id">
                {{state.name}}
              </mat-option>
            </mat-select>
            <mat-error
              *ngIf="sisForm.controls['StateOfResidenceId'].touched && sisForm.controls['StateOfResidenceId'].errors?.['required']">
              Select State
            </mat-error>
          </mat-form-field>
        </div>

        <div class="inputParent">
          <mat-label class="inputLabel">City <span *ngIf="citiesListForCurrent.length>0" class="red">*</span></mat-label>
          <mat-form-field appearance="outline" class="input"
            [ngClass]="sisForm.get('SameAsPermanentAddress').value?' disable-pointer-events':''">
            <mat-select formControlName="CityOfResidenceId" placeholder="Please Select">
              <mat-option>
                <ngx-mat-select-search [formControl]="currentCityFilterCtrl" placeholderLabel="Search City..."
                  noEntriesFoundLabel="No results found"></ngx-mat-select-search>
              </mat-option>
              <mat-option *ngFor="let city of currentCityFilteredList" [value]="city.id">
                {{city.name}}
              </mat-option>
            </mat-select>
            <mat-error
              *ngIf="sisForm.controls['CityOfResidenceId'].touched && sisForm.controls['CityOfResidenceId'].errors?.['required']">
              Select City
            </mat-error>
          </mat-form-field>
        </div>
      </form>
    </fieldset>

    <div class="mb-1">
      <button mat-raised-button color="primary" [ngClass]="sisForm.invalid?'disabledBtn':'enabledBtn'"
        [disabled]="sisForm.invalid" class="saveNextBtn" (click)="saveAndNext()" type="button">Save &
        Next</button>
    </div>
  </div>

  <div *ngIf="isLgScreen">
    <form [formGroup]="sisForm" class="personalForm">
      <h3 class="text-left personal-details-head">Permanent Address<span class="error"> *</span></h3>

      <div class="inputParent">
        <mat-label class="inputLabel">Country <span class="red">*</span></mat-label>
        <mat-form-field appearance="outline" class="input">
          <mat-select formControlName="PermanentCountryId" (ngModelChange)="getStatesListCountryWise($event, true)"
            placeholder="Please Select">
            <mat-option>
              <ngx-mat-select-search [formControl]="permanentCountryFilterCtrl" placeholderLabel="Search Country..."
                noEntriesFoundLabel="No results found"></ngx-mat-select-search>
            </mat-option>
            <mat-option *ngFor="let country of permanentCountryFilteredList" [value]="country.id">
              {{country.name}}
            </mat-option>
          </mat-select>
          <mat-error
            *ngIf="sisForm.controls['PermanentCountryId'].touched && sisForm.controls['PermanentCountryId'].errors?.['required']">
            Select Country
          </mat-error>
        </mat-form-field>
      </div>

      <div class="inputParent">
        <mat-label class="inputLabel">Post Code <span class="red">*</span></mat-label>
        <mat-form-field appearance="outline" class="input">
          <input matInput type="text" formControlName="PermanentpostCode" placeholder="Enter Post Code">
          <mat-error
            *ngIf="sisForm.controls['PermanentpostCode'].touched && sisForm.controls['PermanentpostCode'].errors?.['required'] ">
            Post code is required
          </mat-error>
          <mat-error *ngIf="sisForm.controls['PermanentpostCode'].errors?.['maxlength'] ">
            Maximum limit reached
          </mat-error>
        </mat-form-field>
      </div>

      <div class="inputParent">
        <mat-label class="inputLabel">Address 1 <span class="red">*</span></mat-label>
        <mat-form-field appearance="outline" class="input">
          <input matInput formControlName="PermanentaddressLine1" placeholder="Enter Address 1">
          <mat-error
            *ngIf="sisForm.controls['PermanentaddressLine1'].touched && sisForm.controls['PermanentaddressLine1'].errors?.['required'] ">
            Address 1 is required
          </mat-error>
          <mat-error *ngIf="sisForm.controls['PermanentaddressLine1'].errors?.['maxlength'] ">
            Maximum limit reached
          </mat-error>
        </mat-form-field>
      </div>

      <div class="inputParent">
        <mat-label class="inputLabel">Address 2</mat-label>
        <mat-form-field appearance="outline" class="input">
          <input matInput placeholder="Enter Address 2" formControlName="PermanentaddressLine2">
          <mat-error *ngIf="sisForm.controls['PermanentaddressLine2'].errors?.['maxlength'] ">
            Maximum limit reached
          </mat-error>
        </mat-form-field>
      </div>

      <div class="inputParent">
        <mat-label class="inputLabel">State/Territory <span *ngIf="statesListForPermanent.length>0" class="red">*</span></mat-label>
        <mat-form-field *ngIf="parmanentStateNotAvail==false" appearance="outline" class="input">
          <mat-select formControlName="Permanentstate" (ngModelChange)="getCitiesListStateWise($event, true)"
            placeholder="Please Select">
            <mat-option>
              <ngx-mat-select-search [formControl]="permanentStateFilterCtrl" placeholderLabel="Search State..."
                noEntriesFoundLabel="No results found"></ngx-mat-select-search>
            </mat-option>
            <mat-option *ngFor="let state of permanentStateFilteredList" [value]="state.id">
              {{state.name}}
            </mat-option>
          </mat-select>
          
          <mat-error
            *ngIf="sisForm.controls['Permanentstate'].touched && sisForm.controls['Permanentstate'].errors?.['required']">
            Select State
          </mat-error>
        </mat-form-field>
        
          
          <mat-form-field appearance="outline" *ngIf="parmanentStateNotAvail" class="input" class="input disable-pointer-events">
            <mat-select formControlName="PermanentCountryId" (ngModelChange)="getStatesListCountryWise($event, true)"
              placeholder="Please Select">
              <mat-option>
                <ngx-mat-select-search [formControl]="permanentCountryFilterCtrl" placeholderLabel="Search Country..."
                  noEntriesFoundLabel="No results found"></ngx-mat-select-search>
              </mat-option>
              <mat-option *ngFor="let country of permanentCountryFilteredList" [value]="country.id">
                {{country.name}}
              </mat-option>
            </mat-select>
            <mat-error
              *ngIf="sisForm.controls['PermanentCountryId'].touched && sisForm.controls['PermanentCountryId'].errors?.['required']">
              Select Country
            </mat-error>
          </mat-form-field>

        
      </div>

      <div class="inputParent">
        <mat-label class="inputLabel">City <span *ngIf="citiesListForPermanent.length>0" class="red">*</span></mat-label>
        <mat-form-field *ngIf="parmanentStateNotAvail==false" appearance="outline" class="input">
          <mat-select formControlName="Permanentcity" placeholder="Please Select">
            <mat-option>
              <ngx-mat-select-search [formControl]="permanentCityFilterCtrl" placeholderLabel="Search City..."
                noEntriesFoundLabel="No results found"></ngx-mat-select-search>
            </mat-option>
            <mat-option *ngFor="let city of permanentCityFilteredList" [value]="city.id">
              {{city.name}}
            </mat-option>
          </mat-select>
          
          <mat-error
            *ngIf="sisForm.controls['Permanentcity'].touched && sisForm.controls['Permanentcity'].errors?.['required']">
            Select City
          </mat-error>
        </mat-form-field>
        <mat-form-field appearance="outline" *ngIf="parmanentStateNotAvail" class="input" class="input disable-pointer-events">
          <mat-select formControlName="PermanentCountryId" (ngModelChange)="getStatesListCountryWise($event, true)"
            placeholder="Please Select">
            <mat-option>
              <ngx-mat-select-search [formControl]="permanentCountryFilterCtrl" placeholderLabel="Search Country..."
                noEntriesFoundLabel="No results found"></ngx-mat-select-search>
            </mat-option>
            <mat-option *ngFor="let country of permanentCountryFilteredList" [value]="country.id">
              {{country.name}}
            </mat-option>
          </mat-select>
          <mat-error
            *ngIf="sisForm.controls['PermanentCountryId'].touched && sisForm.controls['PermanentCountryId'].errors?.['required']">
            Select Country
          </mat-error>
        </mat-form-field>
      </div>

      <h3 class="text-left personal-details-head">Current Address<span class="error"> *</span></h3>
      <div class="text-left">
        <mat-checkbox (click)="setCurrentAddressAsPermanent($event)" formControlName="SameAsPermanentAddress"
          color="primary">Same As Permanent address</mat-checkbox>
      </div>

      <div class="inputParent">
        <mat-label class="inputLabel">Country <span class="red">*</span></mat-label>
        <mat-form-field appearance="outline" class="input"
          [ngClass]="sisForm.get('SameAsPermanentAddress').value?' disable-pointer-events':''">
          <mat-select formControlName="CurrentCountryId" (ngModelChange)="getStatesListCountryWise($event, false)"
            placeholder="Please Select">
            <mat-option>
              <ngx-mat-select-search [formControl]="currentCountryFilterCtrl" placeholderLabel="Search Country..."
                noEntriesFoundLabel="No results found"></ngx-mat-select-search>
            </mat-option>
            <mat-option *ngFor="let country of currentCountryFilteredList" [value]="country.id">
              {{country.name}}
            </mat-option>
          </mat-select>
          <mat-error
            *ngIf="sisForm.controls['CurrentCountryId'].touched && sisForm.controls['CurrentCountryId'].errors?.['required']">
            Select Country
          </mat-error>
        </mat-form-field>
      </div>

      <div class="inputParent">
        <mat-label class="inputLabel">Post Code <span class="red">*</span></mat-label>
        <mat-form-field appearance="outline" class="input"
          [ngClass]="sisForm.get('SameAsPermanentAddress').value?' disable-pointer-events':''">
          <input matInput type="text" formControlName="PostCode" placeholder="Enter Post Code">
          <mat-error *ngIf="sisForm.controls['PostCode'].touched && sisForm.controls['PostCode'].errors?.['required'] ">
            Post code is required
          </mat-error>
          <mat-error *ngIf="sisForm.controls['PostCode'].errors?.['maxlength'] ">
            Maximum limit reached
          </mat-error>
        </mat-form-field>
      </div>

      <div class="inputParent">
        <mat-label class="inputLabel">Address 1 <span class="red">*</span></mat-label>
        <mat-form-field appearance="outline" class="input"
          [ngClass]="sisForm.get('SameAsPermanentAddress').value?' disable-pointer-events':''">
          <input matInput formControlName="AddressLine1" placeholder="Enter Address 1">
          <mat-error
            *ngIf="sisForm.controls['AddressLine1'].touched && sisForm.controls['AddressLine1'].errors?.['required'] ">
            Address 1 is required
          </mat-error>
          <mat-error *ngIf="sisForm.controls['AddressLine1'].errors?.['maxlength'] ">
            Maximum limit reached
          </mat-error>
        </mat-form-field>
      </div>


      <div class="inputParent">
        <mat-label class="inputLabel">Address 2</mat-label>
        <mat-form-field appearance="outline" class="input"
          [ngClass]="sisForm.get('SameAsPermanentAddress').value?' disable-pointer-events':''">
          <input matInput placeholder="Enter Address 2" formControlName="AddressLine2">
          <mat-error *ngIf="sisForm.controls['AddressLine2'].errors?.['maxlength'] ">
            Maximum limit reached
          </mat-error>
        </mat-form-field>
      </div>

      <div class="inputParent">
        <mat-label class="inputLabel">State/Territory <span *ngIf="statesListForCurrent.length>0" class="red">*</span></mat-label>
        <mat-form-field *ngIf="currentStateNotAvail==false" appearance="outline" class="input"
          [ngClass]="sisForm.get('SameAsPermanentAddress').value?' disable-pointer-events':''">
          <mat-select formControlName="StateOfResidenceId" (ngModelChange)="getCitiesListStateWise($event, false)"
            placeholder="Please Select">
            <mat-option>
              <ngx-mat-select-search [formControl]="currentStateFilterCtrl" placeholderLabel="Search State..."
                noEntriesFoundLabel="No results found"></ngx-mat-select-search>
            </mat-option>
            <mat-option *ngFor="let state of currentStateFilteredList" [value]="state.id">
              {{state.name}}
            </mat-option>
          </mat-select>
          <mat-error
            *ngIf="sisForm.controls['StateOfResidenceId'].touched && sisForm.controls['StateOfResidenceId'].errors?.['required']">
            Select State
          </mat-error>
        </mat-form-field>
        <mat-form-field appearance="outline" *ngIf="currentStateNotAvail" class="input disable-pointer-events"
          [ngClass]="sisForm.get('SameAsPermanentAddress').value?' disable-pointer-events':''">
          
          <mat-select formControlName="CurrentCountryId" (ngModelChange)="getStatesListCountryWise($event, false)"
            placeholder="Please Select">
            <mat-option>
              <ngx-mat-select-search [formControl]="currentCountryFilterCtrl" placeholderLabel="Search Country..."
                noEntriesFoundLabel="No results found"></ngx-mat-select-search>
            </mat-option>
            <mat-option *ngFor="let country of currentCountryFilteredList" [value]="country.id">
              {{country.name}}
            </mat-option>
          </mat-select>
          <mat-error
            *ngIf="sisForm.controls['CurrentCountryId'].touched && sisForm.controls['CurrentCountryId'].errors?.['required']">
            Select Country
          </mat-error>
        </mat-form-field>
      </div>

      <div class="inputParent">
        <mat-label class="inputLabel">City <span *ngIf="citiesListForCurrent.length>0" class="red">*</span></mat-label>
        <mat-form-field *ngIf="currentStateNotAvail==false" appearance="outline" class="input"
          [ngClass]="sisForm.get('SameAsPermanentAddress').value?' disable-pointer-events':''">
          <mat-select formControlName="CityOfResidenceId" placeholder="Please Select">
            <mat-option>
              <ngx-mat-select-search [formControl]="currentCityFilterCtrl" placeholderLabel="Search City..."
                noEntriesFoundLabel="No results found"></ngx-mat-select-search>
            </mat-option>
            <mat-option *ngFor="let city of currentCityFilteredList" [value]="city.id">
              {{city.name}}
            </mat-option>
          </mat-select>
          <mat-error
            *ngIf="sisForm.controls['CityOfResidenceId'].touched && sisForm.controls['CityOfResidenceId'].errors?.['required']">
            Select City
          </mat-error>
        </mat-form-field>
        <mat-form-field appearance="outline" *ngIf="currentStateNotAvail" class="input disable-pointer-events"
          [ngClass]="sisForm.get('SameAsPermanentAddress').value?' disable-pointer-events':''">
          
          <mat-select formControlName="CurrentCountryId" (ngModelChange)="getStatesListCountryWise($event, false)"
            placeholder="Please Select">
            <mat-option>
              <ngx-mat-select-search [formControl]="currentCountryFilterCtrl" placeholderLabel="Search Country..."
                noEntriesFoundLabel="No results found"></ngx-mat-select-search>
            </mat-option>
            <mat-option *ngFor="let country of currentCountryFilteredList" [value]="country.id">
              {{country.name}}
            </mat-option>
          </mat-select>
          <mat-error
            *ngIf="sisForm.controls['CurrentCountryId'].touched && sisForm.controls['CurrentCountryId'].errors?.['required']">
            Select Country
          </mat-error>
        </mat-form-field>
      </div>
    </form>
  </div>
</div>