import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { SisPendingFields } from '../../post-auth/sis-models/sis-pending-fields';

@Component({
  selector: 'app-pending-fields-dialog',
  templateUrl: './pending-fields-dialog.component.html',
  styleUrls: ['./pending-fields-dialog.component.scss']
})
export class PendingFieldsDialogComponent {

  message: string = 'Please submit below mentioned fields / sections first :-';
  pendingItems: Array<SisPendingFields>;
  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<PendingFieldsDialogComponent>) {
    this.pendingItems = data.pendingItems;
  }

  onConfirmClick(): void {
    this.dialogRef.close(true);
  }

  ngOnInit(): void { }
}
