import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SharedService } from '../shared/shared.service';
import { SisFinalDataSet } from './sis-models/sis-final-data-set';
import { Router } from '@angular/router';
import { Observable, Subject } from 'rxjs';
import { FormArray, FormGroup } from '@angular/forms';
import { SisPendingFields } from './sis-models/sis-pending-fields';
import { SisPersonalDetailsDataSet } from './sis-models/sis-personal-details-data-set';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SisService {
  private checkPermSubject = new Subject<any>();

  headers: any = {};
  options: any = {};
  
  constructor(private http: HttpClient, private sharedService: SharedService, private router: Router) { }

  getCountriesList() {
    return this.http.get(environment.baseUrl + 'SISForm/GetCountriesAsync');
  }

  getReligionList() {
    return this.http.get(environment.baseUrl + 'SISForm/GetReligionsAsync');
  }

  getNationalitiesList() {
    return this.http.get(environment.baseUrl + 'SISForm/GetNationalitiesAsync');
  }

  getStatesCountryWise(countryId: number) {
    return this.http.get(environment.baseUrl + 'SISForm/GetStatesByCountryAsync?countryId=' + countryId);
  }

  getCitiesStateWise(stateId: number) {
    return this.http.get(environment.baseUrl + 'SISForm/GetCityByStateAsync?stateId=' + stateId);
  }

  getRefusalTypesList() {
    return this.http.get(environment.baseUrl + 'SISForm/GetRefusalTypesAsync');
  }

  getVisaTypesList() {
    return this.http.get(environment.baseUrl + 'SISForm/GetVisaTypeAsync');
  }

  getLanguageTestsList(isEnglish: number) {
    return this.http.get(environment.baseUrl + 'SISForm/GetLanguageTestsAsync?isEnglishTest=' + isEnglish);
  }

  getDocumentTypesList(studentId: number) {
    return this.http.get(environment.baseUrl + 'SISForm/GetDocumentTypesAsync?studentId=' + studentId);
  }

  getMotherTongueList() {
    return this.http.get(environment.baseUrl + 'SISForm/GetMotherTangueAsync');
  }

  getPassportLocationsList() {
    return this.http.get(environment.baseUrl + 'SISForm/GetPassportLocationsAsync');
  }

  getLevelOfStudyList() {
    return this.http.get(environment.baseUrl + 'SISForm/GetLevelOfStudyAsync');
  }

  setCountriesListInLocalStorage(list: any) {
    localStorage.setItem('countriesList', JSON.stringify(list));
  }

  getCountriesListFromLocalStorage() {
    let list: any = localStorage.getItem('countriesList');
    return JSON.parse(list);
  }

  setVisaTypesListInLocalStorage(list: any) {
    localStorage.setItem('visaTypesList', JSON.stringify(list));
  }

  getVisaTypesListFromLocalStorage() {
    let list: any = localStorage.getItem('visaTypesList');
    return JSON.parse(list);
  }

  clearLocalStorage() {
    localStorage.clear();
  }

  getSISPersonalDetails(studentId: number, userId: number) {
    return this.http.get(environment.baseUrl + 'SISForm/GetSISPersonalDetails?studentId=' + studentId + '&userId=' + userId)
  }

  getSISTravelHistory(studentId: number) {
    return this.http.get(environment.baseUrl + 'SISForm/GetSISTravelHistory?studentId=' + studentId)
  }

  getImmigrationCountriesList(studentId: number) {
    return this.http.get(environment.baseUrl + 'SISForm/GetVisitedCountryAsync?studentId=' + studentId)
  }

  getSISImmigrationHistory(studentId: number) {
    return this.http.get(environment.baseUrl + 'SISForm/GetSISImmigrationHistory?studentId=' + studentId)
  }

  getSISVisaRejectionData(studentId: number) {
    return this.http.get(environment.baseUrl + 'SISForm/GetSISVisaRejectionHistory?studentId=' + studentId)
  }

  getSISEducationData(studentId: number, userId: number) {
    return this.http.get(environment.baseUrl + 'SISForm/GetSISFormEducationDetails?studentId=' + studentId + '&userId=' + userId)
  }

  getSISEnglishExamsData(studentId: number, userId: number) {
    return this.http.get(environment.baseUrl + 'SISForm/GetSIStEnglishLanguageExams?studentId=' + studentId + '&userId=' + userId)
  }

  getSISOtherExamsData(studentId: number) {
    return this.http.get(environment.baseUrl + 'SISForm/GetSISOtherExams?studentId=' + studentId)
  }

  getSISWorkExperienceData(studentId: number, userId: number) {
    return this.http.get(environment.baseUrl + 'SISForm/GetSISWorkExperience?studentId=' + studentId)
  }

  getSISDocumentsData(studentId: number) {
    return this.http.get(environment.baseUrl + 'SISForm/GetSISFormDocuments?studentId=' + studentId)
  }

  getSISReferencenData(studentId: number, userId: number) {
    return this.http.get(environment.baseUrl + 'SISForm/GetSISReferences?studentId=' + studentId)
  }

  getSISDisabilityData(studentId: number) {
    return this.http.get(environment.baseUrl + 'SISForm/GetDisability?studentId=' + studentId)
  }

  getSISCriminalConvictionsData(studentId: number) {
    return this.http.get(environment.baseUrl + 'SISForm/GetCriminalConvictions?studentId=' + studentId)
  }

  setSISDataAtLocal(dataObj: SisFinalDataSet) {
    localStorage.setItem('sis-data', JSON.stringify(dataObj));
  }

  getSISDataFromLocal() {
    let data: any = localStorage.getItem('sis-data');
    return JSON.parse(data);
  }

  setDefaultValuesPersonalDetails() {
    const dataSet: SisPersonalDetailsDataSet = {
      CountryOfBirth: "",
      MaritalStatus: "",
      AlternateEmailID: "",
      AlternatePhoneNo: "",
      NativeLanguageId: "",
      PassportExpirationDate: "",
      PassportIssuedDate: "",
      PassportNumber: "",
      PlaceofBirth: "",
      Religion: "",
      Title: "",
      dob: "",
      email: "",
      familyName: "",
      firstName: "",
      gender: "",
      isNotSharingReligion: false,
      nameInPassport: "",
      nationalityId: "",
      passportIssueCountryId: "",
      phoneNum: "",

      PermanentCountryId: "",
      PermanentaddressLine1: "",
      PermanentaddressLine2: "",
      Permanentcity: "",
      PermanentpostCode: "",
      Permanentstate: "",
      CurrentCountryId: "",
      CityOfResidenceId: "",
      PostCode: "",
      StateOfResidenceId: "",
      AddressLine1: "",
      AddressLine2: "",
      SameAsPermanentAddress: false,

      overseasAddressLine1: "",
      overseasAddressLine2: "",
      overseasCity: "",
      overseasCountryId: "",
      overseasPostCode: "",
      overseasState: "",
    }

    this.setDefaultValuesPersonalDetailsAtLocal(dataSet);
  }

  setDefaultValuesPersonalDetailsAtLocal(dataObj: SisPersonalDetailsDataSet) {
    localStorage.setItem('personalInformation', JSON.stringify(dataObj));
  }

  getDefaultValuesPersonalDetailsFromLocal() {
    return localStorage.getItem('personalInformation');
  }

  setSISTravelHistoryAtLocal(dataObj: any) {
    localStorage.setItem('travelHistory', JSON.stringify(dataObj));
  }

  getSISTravelHistoryFromLocal() {
    return localStorage.getItem('travelHistory');
  }

  setSISImmigrationHistoryAtLocal(dataObj: any) {
    localStorage.setItem('imegrationHistory', JSON.stringify(dataObj));
  }

  getSISImmigrationHistoryFromLocal() {
    return localStorage.getItem('imegrationHistory');
  }

  setSISVisaRejectionHistoryAtLocal(dataObj: any) {
    localStorage.setItem('visaRejections', JSON.stringify(dataObj));
  }

  getSISVisaRejectionHistoryFromLocal() {
    return localStorage.getItem('visaRejections');
  }

  setSISEducationDetailsAtLocal(dataObj: any) {
    localStorage.setItem('educationDetails', JSON.stringify(dataObj));
  }

  getSISEducationDetailsFromLocal() {
    return localStorage.getItem('educationDetails');
  }

  setSISEnglishExamDetailsAtLocal(dataObj: any) {
    localStorage.setItem('englishLanguageExam', JSON.stringify(dataObj));
  }

  getSISEnglishExamDetailsFromLocal() {
    return localStorage.getItem('englishLanguageExam');
  }

  setSISOtherExamsDetailsAtLocal(dataObj: any) {
    localStorage.setItem('otherExam', JSON.stringify(dataObj));
  }

  getSISOtherExamsDetailsFromLocal() {
    return localStorage.getItem('otherExam');
  }

  setSISWorkExperienceDetailsAtLocal(dataObj: any) {
    localStorage.setItem('workExprience', JSON.stringify(dataObj));
  }

  getSISWorkExperienceDetailsFromLocal() {
    return localStorage.getItem('workExprience');
  }

  setSISReferencesAtLocal(dataObj: any) {
    localStorage.setItem('referencesIfAny', JSON.stringify(dataObj));
  }

  getSISReferencesFromLocal() {
    return localStorage.getItem('referencesIfAny');
  }

  setSISDocumentsAtLocal(dataObj: any) {
    localStorage.setItem('documents', JSON.stringify(dataObj));
  }

  getSISDocumentsFromLocal() {
    return localStorage.getItem('documents');
  }

  setSISDisablityDataAtLocal(dataObj: any) {
    localStorage.setItem('disablityDetails', JSON.stringify(dataObj));
  }

  getSISDisablityDataFromLocal() {
    return localStorage.getItem('disablityDetails');
  }

  setSISCriminalOffenceDataAtLocal(dataObj: any) {
    localStorage.setItem('criminalOffences', JSON.stringify(dataObj));
  }

  getSISCriminalOffenceDataFromLocal() {
    return localStorage.getItem('criminalOffences');
  }

  setSISDeclarationDataAtLocal(data: any) {
    localStorage.setItem('declaration', data.toString());
  }

  getSISDeclarationDataFromLocal() {
    return localStorage.getItem('declaration');
  }

  uploadSISDocument(dataObj: any) {
    return this.http.post(environment.baseUrl + 'SISForm/uploadSISFormDocument', dataObj);
  }

  removeSISDocument(dataObj: any) {
    return this.http.post(environment.baseUrl + 'SISForm/UpdateDocumentIgnoreStatus', dataObj);
  }

  saveSISData(sectionIndex: number) {
    let dataObj: SisFinalDataSet = {
      studentId: this.sharedService.getStudentId(),
      staffId: this.sharedService.getUserId(),
      personalInformation: this.getDefaultValuesPersonalDetailsFromLocal(),
      travelHistory: this.getSISTravelHistoryFromLocal() ? this.getSISTravelHistoryFromLocal() : '{"travelHistoryExist":2,"List":[]}',
      imegrationHistory: this.getSISImmigrationHistoryFromLocal() ? this.getSISImmigrationHistoryFromLocal() : '{"immigrationCountryIds":""}',
      visaRejections: this.getSISVisaRejectionHistoryFromLocal() ? this.getSISVisaRejectionHistoryFromLocal() : '{"IsVisaRejection":2,"visarejectionhistory":[]}',
      educationDetails: this.getSISEducationDetailsFromLocal() ? this.getSISEducationDetailsFromLocal() : '[]',
      englishLanguageExam: this.getSISEnglishExamDetailsFromLocal() ? this.getSISEnglishExamDetailsFromLocal() : '{"IsEnglishExamNotRequired":null,"List":[]}',
      otherExam: this.getSISOtherExamsDetailsFromLocal() ? this.getSISOtherExamsDetailsFromLocal() : '{"IsOtherExamExist":null,"List":[]}',
      workExprience: this.getSISWorkExperienceDetailsFromLocal() ? this.getSISWorkExperienceDetailsFromLocal() : '{"noExperience":"false","List":[]}',
      referencesIfAny: this.getSISReferencesFromLocal() ? this.getSISReferencesFromLocal() : '[]',
      documents: this.getSISDocumentsFromLocal() ? this.getSISDocumentsFromLocal() : '[]',
      disablityDetails: this.getSISDisablityDataFromLocal() ? this.getSISDisablityDataFromLocal() : '{"DisabilityAnswered":false,"DisabilityState":""}',
      criminalOffences: this.getSISCriminalOffenceDataFromLocal() ? this.getSISCriminalOffenceDataFromLocal() : '{"CriminalConvictions":false,"CrimeDetails":""}',
      declaration: this.getSISDeclarationDataFromLocal() ? this.getSISDeclarationDataFromLocal() : 'false',
      isSubmitted: false,
    };
    return this.http.post(environment.baseUrl + 'SISForm/SaveSISFormDataAsync', dataObj);
  }

  submitSIS(studentId: number, sectionIndex: number) {
    return this.http.post(environment.baseUrl + 'SISForm/CaptureSISFormToCRMAsync?studentId=' + studentId + '&sectionIndex=' + sectionIndex, null);
  }

  setSectionWisePermission(section: string, perm: boolean) {
    localStorage.setItem(section, perm.toString());
  }

  getSectionWisePermission(section: string) {
    let perm = localStorage.getItem(section);
    if (perm !== null && perm == 'true') {
      return true;
    } else {
      return false;
    }
  }

  getSubmitBtnPermissions() {
    if (this.getSectionWisePermission('personalDetails1Perm') && this.getSectionWisePermission('personalDetails2Perm') && this.getSectionWisePermission('travelHistoryPerm') && this.getSectionWisePermission('immigrationHistoryPerm') && this.getSectionWisePermission('visaRejectionsPerm') && this.getSectionWisePermission('educationDetailsPerm') && this.getSectionWisePermission('englishLanguageExamPerm') && this.getSectionWisePermission('otherExamPerm') && this.getSectionWisePermission('disablityDetailsPerm')) {
      return true;
    } else {
      return false;
    }
  }

  // function to broadcast perm change to update final submit button permission
  emitSubmitPermissionChangeAlert(changeFlag: string) {
    this.checkPermSubject.next(changeFlag);
  }

  // function to get perm change broadcast observable value
  getSubmitPermissionChangeAlert(): Observable<any> {
    return this.checkPermSubject.asObservable();
  }

  getPendingFieldsList() {
    let list = [];
    if (localStorage.getItem('pendingItems') && JSON.parse(localStorage.getItem('pendingItems')).length > 0) {
      list = JSON.parse(localStorage.getItem('pendingItems'));
    }
    return list;
  }

  setPendingFieldsList(list: Array<any>) {
    localStorage.setItem('pendingItems', JSON.stringify(list));
  }

  /* 
   Returns an array of invalid control/group names, or a zero-length array if 
   no invalid controls/groups where found 
*/
  returnInvalidControls(formToInvestigate: FormGroup | FormArray): string[] {
    var invalidControls: string[] = [];
    let recursiveFunc = (form: FormGroup | FormArray) => {
      Object.keys(form.controls).forEach(field => {
        const control = form.get(field);
        if (control.invalid) invalidControls.push(field);
        if (control instanceof FormGroup) {
          recursiveFunc(control);
        } else if (control instanceof FormArray) {
          recursiveFunc(control);
        }
      });
    }
    recursiveFunc(formToInvestigate);
    return invalidControls;
  }

  setPendingItems(name: string, index: number, subItems: Array<string> = []) {
    let obj: SisPendingFields = {
      sectionName: name,
      items: subItems,
      sectionIndex: index
    }
    let list = this.getPendingFieldsList() ? this.getPendingFieldsList() : [];
    const itemIndex = list.findIndex(
      (item: SisPendingFields) => item.sectionIndex == index
    )
    if (itemIndex !== -1) {
      list[itemIndex] = obj;
    } else {
      list.push(obj);
    }
    this.setPendingFieldsList(list);
  }

  removePendingItems(index: number) {
    let list = [];
    list = this.getPendingFieldsList();
    if (list && list.length > 0) {
      const itemIndex = list.findIndex(
        (item: SisPendingFields) => item.sectionIndex == index
      )
      if (itemIndex !== -1) {
        list.splice(itemIndex, 1);
      }
    }
    this.setPendingFieldsList(list);
  }
}
