<app-sis-header *ngIf="!isLgScreen" class="sis-header"></app-sis-header>
<div id="personalDetailsPageOne" class="container" [ngClass]="!isLgScreen? 'mt-6':''">


    <div fxLayoutGap="12px" class="mb-2 text-left" *ngIf="isLgScreen">
        <h3>Travel History<span class="error"> *</span></h3>

        <p>Have you applied for leave to remain in any country in the past 10 years? If so, please submit details for
            each.</p>

        <div>

            <mat-radio-group aria-label="Select an option" [(ngModel)]="travelHistoryData.travelHistoryExist">
                <mat-radio-button (click)="setPermission()" [value]="1" color="primary"><span
                        style="font-weight: bold;">Yes</span></mat-radio-button>
                <mat-radio-button (click)="checkDataList()" [value]="0" color="primary"><span
                        style="font-weight: bold;">No</span></mat-radio-button>
            </mat-radio-group>
        </div>
    </div>

    <fieldset fxLayoutGap="12px" class="mb-2" *ngIf="!isLgScreen">
        <legend>Travel History<span class="error"> *</span></legend>

        <p>Have you applied for leave to remain in any country in the past 10 years? If so, please submit details for
            each.</p>

        <div>

            <mat-radio-group aria-label="Select an option" [(ngModel)]="travelHistoryData.travelHistoryExist">
                <mat-radio-button (click)="setPermission()" [value]="1" color="primary"><span
                        style="font-weight: bold;">Yes</span></mat-radio-button>
                <mat-radio-button (click)="checkDataList()" [value]="0" color="primary"><span
                        style="font-weight: bold;">No</span></mat-radio-button>
            </mat-radio-group>
        </div>
    </fieldset>

    <div *ngIf="travelHistoryList.length ">
        <form [formGroup]="travelTblForm">
            <ng-container formArrayName="travelHistoryFormArray">
                <div *ngFor="let cardFormGroup of formsList | async;let $index = index" class="custom-card my-5 p-4">
                    <fieldset class="fieldsetArray" [ngClass]="!isLgScreen? 'text-center':'text-left'">
                        <legend *ngIf="$index==0">IF YES, Please fill the fields</legend>
                        <div fxLayout="column"
                            *ngIf="$index==0 && isLgScreen && travelHistoryData.travelHistoryExist!==0 && addMoreTH && travelHistoryList.length>0">
                            <button fxFlexAlign="end" mat-raised-button color="primary" (click)="addMoreTH = false;">Add
                                more</button>
                        </div>
                        <ng-container [formGroup]="cardFormGroup">
                            <div fxLayout="row wrap" [ngClass]="isLgScreen? 'ml-2':''" fxLayout.lt-sm="column"
                                fxLayoutGap.xs="5%" class="mb-2">

                                <div class="inputParent">
                                    <mat-label class="inputLabel" [ngClass]="checkDisableClass(cardFormGroup)">Date of
                                        arrival <span class="red">*</span></mat-label>
                                    <mat-form-field appearance="outline" class="input">
                                        <input matInput [matDatepicker]="dateOfArrivalTemp"
                                            formControlName="arrivalDate"
                                            [max]="cardFormGroup.get('departureDate').value">
                                        <mat-datepicker-toggle matSuffix [for]="dateOfArrivalTemp">
                                        </mat-datepicker-toggle>
                                        <mat-datepicker #dateOfArrivalTemp></mat-datepicker>
                                        <mat-error
                                            *ngIf="travelTblForm.get('travelHistoryFormArray').get($index.toString()).get('arrivalDate').hasError('required')">
                                            Date of arrival is <strong>required</strong>
                                        </mat-error>
                                    </mat-form-field>
                                </div>

                                <div class="inputParent">
                                    <mat-label class="inputLabel" [ngClass]="checkDisableClass(cardFormGroup)">Date of
                                        Departure <span class="red">*</span></mat-label>
                                    <mat-form-field appearance="outline" class="input">
                                        <input matInput [matDatepicker]="departureDateTemp"
                                            formControlName="departureDate"
                                            [max]="today" [min]="cardFormGroup.get('arrivalDate').value">
                                        <mat-datepicker-toggle matSuffix [for]="departureDateTemp">
                                        </mat-datepicker-toggle>
                                        <mat-datepicker #departureDateTemp></mat-datepicker>
                                        <mat-error
                                            *ngIf="travelTblForm.get('travelHistoryFormArray').get($index.toString()).get('departureDate').hasError('required')">
                                            Date of departure is <strong>required</strong>
                                        </mat-error>
                                        <mat-error
                                            *ngIf="travelTblForm.get('travelHistoryFormArray').get($index.toString()).get('departureDate').hasError('matDatepickerMin') ">
                                            Date of arrival must be earlier than date of <br /> departure
                                        </mat-error>
                                    </mat-form-field>
                                </div>

                                <div class="inputParent">
                                    <mat-label class="inputLabel" [ngClass]="checkDisableClass(cardFormGroup)">Visa
                                        start date <span class="red">*</span></mat-label>
                                    <mat-form-field appearance="outline" class="input">
                                        <input matInput [matDatepicker]="visaStartDateTemp"
                                            formControlName="visaStartDate"
                                            [max]="cardFormGroup.get('visaEndDate').value">
                                        <mat-datepicker-toggle matSuffix [for]="visaStartDateTemp">
                                        </mat-datepicker-toggle>
                                        <mat-datepicker #visaStartDateTemp></mat-datepicker>
                                        <mat-error
                                            *ngIf="travelTblForm.get('travelHistoryFormArray').get($index.toString()).get('visaStartDate').hasError('required')">
                                            Visa start date is <strong>required</strong>
                                        </mat-error>
                                    </mat-form-field>
                                </div>

                                <div class="inputParent">
                                    <mat-label class="inputLabel" [ngClass]="checkDisableClass(cardFormGroup)">Visa
                                        expiry date <span class="red">*</span></mat-label>
                                    <mat-form-field appearance="outline" class="input">
                                        <input matInput [matDatepicker]="visaEndDateTemp" formControlName="visaEndDate"
                                        [min]="cardFormGroup.get('visaStartDate').value">
                                        <mat-datepicker-toggle matSuffix [for]="visaEndDateTemp">
                                        </mat-datepicker-toggle>
                                        <mat-datepicker #visaEndDateTemp></mat-datepicker>
                                        <mat-error
                                            *ngIf="travelTblForm.get('travelHistoryFormArray').get($index.toString()).get('visaEndDate').hasError('required')">
                                            Visa expiry date is <strong>required</strong>
                                        </mat-error>
                                        <mat-error
                                            *ngIf="travelTblForm.get('travelHistoryFormArray').get($index.toString()).get('visaEndDate').hasError('matDatepickerMin')">
                                            Visa expiry date must be earlier than visa <br />start date
                                        </mat-error>
                                    </mat-form-field>
                                </div>

                                <div class="inputParent">
                                    <mat-label class="inputLabel" [ngClass]="checkDisableClass(cardFormGroup)">Purpose
                                        of visit</mat-label>
                                    <mat-form-field appearance="outline" class="input">
                                        <input type="text" matInput formControlName="purposeOfVisit">
                                    </mat-form-field>
                                </div>

                                <div class="inputParent">
                                    <mat-label class="inputLabel" [ngClass]="checkDisableClass(cardFormGroup)">Country
                                        <span class="red">*</span></mat-label>
                                    <mat-form-field appearance="outline" class="input">
                                        <mat-select formControlName="countryId"
                                            [(ngModel)]="cardFormGroup.get('countryId').value"
                                            placeholder="Please Select">
                                            <mat-option>
                                                <ngx-mat-select-search [formControl]="editCountryFilterCtrl"
                                                    placeholderLabel="Search Country..."
                                                    noEntriesFoundLabel="No results found"></ngx-mat-select-search>
                                            </mat-option>
                                            <mat-option [value]="country.id"
                                                *ngFor="let country of editCountryFilteredList">
                                                {{country.name}}</mat-option>
                                        </mat-select>
                                        <mat-error
                                            *ngIf="travelTblForm.get('travelHistoryFormArray').get($index.toString()).get('countryId').hasError('required')">
                                            Country is <strong>required</strong>
                                        </mat-error>
                                    </mat-form-field>
                                </div>

                                <div class="inputParent">
                                    <mat-label class="inputLabel" [ngClass]="checkDisableClass(cardFormGroup)">Visa
                                        type <span class="red">*</span></mat-label>
                                    <mat-form-field appearance="outline" class="input">
                                        <mat-select formControlName="visaTypeId"
                                            [(ngModel)]="cardFormGroup.get('visaTypeId').value">
                                            <mat-option value=""> Please select </mat-option>
                                            <mat-option [value]="visa.id" *ngFor="let visa of visaTypesList">
                                                {{visa.name}}</mat-option>
                                        </mat-select>
                                        <mat-error
                                            *ngIf="travelTblForm.get('travelHistoryFormArray').get($index.toString()).get('visaTypeId').hasError('required')">
                                            Visa type is <strong>required</strong>
                                        </mat-error>
                                    </mat-form-field>
                                </div>
                            </div>
                            <div fxLayout="row" fxLayoutAlign="end center" class="my-2 md:my-0">
                                <ng-container *ngIf="!cardFormGroup.disabled">
                                    <button mat-flat-button color="primary" [disabled]="cardFormGroup.invalid"
                                        class="mr-1" (click)="editTravelHistory(cardFormGroup, $index)">
                                        Save
                                    </button>
                                    <button mat-flat-button color="warn" (click)="cardFormGroup.disable();">
                                        Cancel
                                    </button>
                                </ng-container>
                                <ng-container *ngIf="cardFormGroup.disabled">
                                    <button mat-flat-button color="primary" class="mr-1"
                                        (click)="cardFormGroup.enable()">
                                        Edit
                                    </button>
                                    <button mat-flat-button color="warn" (click)="removeTravelHistory($index)">
                                        Delete
                                    </button>
                                </ng-container>
                            </div>
                        </ng-container>
                    </fieldset>
                </div>
            </ng-container>
        </form>
    </div>


    <fieldset *ngIf="travelHistoryData.travelHistoryExist==1 && (!addMoreTH || travelHistoryList.length===0)"
        [ngClass]="!isLgScreen? 'text-center':'text-left'" class="fieldsetArray">
        <legend *ngIf="dataSource.data.length==0">IF YES, Please fill the fields</legend>
        <form [formGroup]="addTravelForm" #formDirective="ngForm">

            <div style="margin-left: 6px;">
                <div fxLayoutGap="10px" style="margin-top: 8px;" [ngClass]="isLgScreen? 'ml-2':''">

                    <div class="inputParent">
                        <mat-label class="inputLabel">Date of Arrival <span class="red">*</span></mat-label>
                        <mat-form-field appearance="outline" class="input">
                            <input formControlName="arrivalDate" placeholder="Date of Arrival" matInput
                            [max]="today" [matDatepicker]="arrival">
                            <mat-hint style="color:red;"
                                *ngIf="addTravelForm.controls['arrivalDate'].touched && addTravelForm.controls['arrivalDate'].errors ">
                                Date of arrival is required
                            </mat-hint>
                            <mat-datepicker-toggle matIconSuffix [for]="arrival"></mat-datepicker-toggle>
                            <mat-datepicker #arrival></mat-datepicker>
                        </mat-form-field>
                    </div>

                    <div class="inputParent">
                        <mat-label class="inputLabel">Date of Departure <span class="red">*</span></mat-label>
                        <mat-form-field class="departure" appearance="outline" class="input">
                            <input formControlName="departureDate" placeholder="Date of Departure" matInput
                            [max]="today" [matDatepicker]="departure" [min]="addTravelForm.get('arrivalDate').value">
                            <mat-hint style="color:red;"
                                *ngIf="addTravelForm.controls['departureDate'].touched && addTravelForm.controls['departureDate'].errors ">
                                Date of departure is required
                            </mat-hint>
                            <mat-datepicker-toggle matIconSuffix [for]="departure"></mat-datepicker-toggle>
                            <mat-datepicker #departure></mat-datepicker>
                        </mat-form-field>
                    </div>

                    <div class="inputParent">
                        <mat-label class="inputLabel">Visa Start Date <span class="red">*</span></mat-label>
                        <mat-form-field class="departure" appearance="outline" class="input">
                            <input formControlName="visaStartDate" placeholder="Visa Start Date" matInput
                            [max]="today" [matDatepicker]="vstartDate">
                            <mat-hint style="color:red;"
                                *ngIf="addTravelForm.controls['visaStartDate'].touched && addTravelForm.controls['visaStartDate'].errors ">
                                Visa Start Date is required
                            </mat-hint>
                            <mat-datepicker-toggle matIconSuffix [for]="vstartDate"></mat-datepicker-toggle>
                            <mat-datepicker #vstartDate></mat-datepicker>
                        </mat-form-field>
                    </div>

                    <div class="inputParent">
                        <mat-label class="inputLabel">Visa End Date <span class="red">*</span></mat-label>
                        <mat-form-field class="departure" appearance="outline" class="input">
                            <input formControlName="visaEndDate" placeholder="Visa End Date" matInput
                            [matDatepicker]="vendDate" [min]="addTravelForm.get('visaStartDate').value">
                            <mat-hint style="color:red;"
                                *ngIf="addTravelForm.controls['visaEndDate'].touched && addTravelForm.controls['visaEndDate'].errors ">
                                Visa End Date is required
                            </mat-hint>
                            <mat-datepicker-toggle matIconSuffix [for]="vendDate"></mat-datepicker-toggle>
                            <mat-datepicker #vendDate></mat-datepicker>
                        </mat-form-field>
                    </div>

                    <div class="inputParent">
                        <mat-label class="inputLabel">Purpose of visit</mat-label>
                        <mat-form-field appearance="outline" class="input">
                            <input matInput formControlName="purposeOfVisit" placeholder="Enter Purpose Of Visit">
                        </mat-form-field>
                    </div>

                    <div class="inputParent">
                        <mat-label class="inputLabel">Country <span class="red">*</span></mat-label>
                        <mat-form-field appearance="outline" class="input">
                            <mat-select formControlName="countryId" placeholder="Please Select">
                                <mat-option>
                                    <ngx-mat-select-search [formControl]="countryFilterCtrl"
                                        placeholderLabel="Search Country..."
                                        noEntriesFoundLabel="No results found"></ngx-mat-select-search>
                                </mat-option>
                                <mat-option *ngFor="let country of countryFilteredList" [value]="country.id">
                                    {{country.name}}
                                </mat-option>
                            </mat-select>
                            <mat-hint style="color:red;"
                                *ngIf="addTravelForm.controls['countryId'].touched && addTravelForm.controls['countryId'].errors ">
                                Select Country
                            </mat-hint>
                        </mat-form-field>
                    </div>

                    <div class="inputParent">
                        <mat-label class="inputLabel">Visa Type <span class="red">*</span></mat-label>
                        <mat-form-field appearance="outline" class="input">
                            <mat-select formControlName="visaTypeId">
                                <mat-option value="">Please Select</mat-option>
                                <mat-option *ngFor="let visaType of visaTypesList" [value]="visaType.id">
                                    {{visaType.name}}
                                </mat-option>
                            </mat-select>
                            <mat-hint style="color:red;"
                                *ngIf="addTravelForm.controls['visaTypeId'].touched && addTravelForm.controls['visaTypeId'].errors ">
                                Select Visa Type
                            </mat-hint>
                        </mat-form-field>
                    </div>
                </div>
                <section fxLayoutAlign="end">
                    <button mat-raised-button color="primary" (click)="addNewTravelRecord(formDirective);" class="mr-1"
                        [disabled]="addTravelForm.invalid" type="button">Add</button>
                    <button mat-flat-button color="warn" (click)="cancelAddNewTravelRecord()">
                        Cancel
                    </button>
                </section>
            </div>
        </form>
    </fieldset>

    <div class="addMoreBtnSection" *ngIf="!isLgScreen && travelHistoryData.travelHistoryExist && addMoreTH && travelHistoryList.length"
        (click)="addMoreTH = false;">
        <mat-icon class="icon-display">add_circle_outline</mat-icon>
        <h4>Add More</h4>
    </div>

    <div class="mb-1 mt-1" *ngIf="!isLgScreen">
        <button mat-raised-button color="primary" [ngClass]="disableSaveNextBtn?'disabledBtn':'enabledBtn'"
            [disabled]="disableSaveNextBtn" class="saveNextBtn" (click)="saveAndNext()" type="button">Save &
            Next</button>
    </div>
</div>