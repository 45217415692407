import { Component } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, FormGroupDirective, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { SharedService } from '../../shared/shared.service';
import { SisService } from '../sis.service';
import { Observable, Subscription } from 'rxjs';
import { MatTableDataSource } from '@angular/material/table';

@Component({
  selector: 'app-sis-reference',
  templateUrl: './sis-reference.component.html',
  styleUrls: ['./sis-reference.component.scss']
})
export class SisReferenceComponent {
  public sisReference: FormGroup;
  isLgScreen: boolean = false;
  studentId: number;
  referenceData: any = {};

  refereeDetailsTable: any;
  refereeDetailsForm: FormGroup;
  refereeDetailsData: any;
  refereeDetailsDataList: any = [];
  _refereeDetailsDataList: any = [];
  refereeDetailsSubscription: Subscription;
  // TABLE DATA
  dataSource = new MatTableDataSource<any>();
  formsList: Observable<any>;
  showAddForm: boolean = false;
  hideSaveBut: boolean = false;
  clickedSave: boolean = false;
  userId: number;
  constructor(private router: Router, private formBuilder: FormBuilder, private toastr: ToastrService,
    private activate: ActivatedRoute, private sharedService: SharedService, private sisService: SisService) {
    this.refereeDetailsTable = this.formBuilder.group({
      refereeDetails: this.formBuilder.array([]),
    });
  }

  ngOnInit(): void {
    this.studentId = this.sharedService.getStudentId();
    this.userId = this.sharedService.getUserId();
    this.isLgScreen = this.sharedService.isLgScreen();
    this.getRefereeDetails();
    this.createRefereeDetailsForm();
    this.sharedService.scrollToTop();
  }

  submitData() {
    this.toastr.success("Record Successfully Saved")

  }
  preventSpace(event: KeyboardEvent) {
    if (event.code === "Space") {
      event.preventDefault();
    }
  }

  saveData() {
    this.sisService.setSISReferencesAtLocal(this._refereeDetailsDataList);
    this.sisService.saveSISData(11).subscribe(() => {
      console.log('data saved reference');
    })
  }

  saveAndNext() {
    this.sisService.submitSIS(this.studentId, 11).subscribe((res: any) => {
      if (res) {
        this.router.navigate(['/sis-documents'])
      }
    }, error => {
      this.toastr.error("Something went wrong", error);
    });
  }

  getRefereeDetails() {
    this.refereeDetailsSubscription = this.sisService.getSISReferencenData(this.studentId, this.userId)
      .subscribe((response: any) => {
        this.refereeDetailsData = JSON.parse(response.data);
        this.refereeDetailsData.forEach(
          (item: any) => (item.isEditable = false)
        );
        this.refereeDetailsDataList = this.refereeDetailsData;
        this._refereeDetailsDataList = [...this.refereeDetailsDataList];
        this._refereeDetailsDataList.length
          ? (this.showAddForm = false)
          : (this.showAddForm = true);
        this.dataSource.data = this._refereeDetailsDataList;
        setTimeout(() => {
          this.saveData();
        }, 5000);
        this.createFormGroups();
      });
  }

  createRefereeDetailsForm() {
    this.refereeDetailsForm = new FormGroup({
      name: new FormControl('', [Validators.required]),
      position: new FormControl('', [Validators.required]),
      title: new FormControl('', [Validators.required]),
      email: new FormControl('', [
        Validators.required,
        Validators.pattern(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,})+$/),
      ]),
      howlongHasPersonknown: new FormControl(''),
      phoneNum: new FormControl('', [
        Validators.required,
        Validators.pattern(/^[0-9-+]+$/),
      ]),
      relationship: new FormControl('', [Validators.required]),
      institution: new FormControl('', [Validators.required]),
      address: new FormControl('', [Validators.required]),
      rowId: new FormControl(''),
      userId: new FormControl(this.userId, [Validators.required]),
      studentId: new FormControl(this.studentId, [Validators.required]),
    });
  }

  // CREATE FORMGROUP FOR EVERY ROW
  createFormGroups() {
    this.refereeDetailsTable = this.formBuilder.group({
      refereeDetails: this.formBuilder.array(
        this._refereeDetailsDataList.map((formControl: any) =>
          this.formBuilder.group({
            name: [formControl.name, [Validators.required]],
            position: [formControl.position, [Validators.required]],
            title: [formControl.title],
            email: [
              formControl.email,
              [
                Validators.required,
                Validators.pattern(
                  /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,})+$/
                ),
              ],
            ],
            howlongHasPersonknown: [formControl.howlongHasPersonknown],
            phoneNum: [
              formControl.phoneNum,
              [Validators.required, Validators.pattern(/^[0-9-+]+$/)],
            ],
            relationship: [formControl.relationship, [Validators.required]],
            institution: [formControl.institution, [Validators.required]],
            address: [formControl.address, [Validators.required]],
            rowId: [formControl.rowId, [Validators.required]],
            userId: [this.userId, [Validators.required]],
            studentId: [this.studentId, [Validators.required]],
          })
        )
      ),
    });
    this.refereeDetailsTable.controls.refereeDetails.disable();
    this.dataSource = new MatTableDataSource<any>(this.refereeDetailsTable.controls.refereeDetails.controls);
    this.formsList = this.dataSource.connect();
    this.refereeDetailsTable.markAllAsTouched();
  }

  // ADD NEW RECORD FOR REFEREE DETAILS
  addRefereeDetails(
    refereeDetailsForm: FormGroup,
    formDirective: FormGroupDirective,
    clickedSave: boolean
  ) {
    if (!refereeDetailsForm.valid) {
      return;
    }
    this.showAddForm = clickedSave;
    const getRefereeDetails = refereeDetailsForm.value;
    const newObj = getRefereeDetails;

    if (newObj.rowId) {
      this._refereeDetailsDataList = this._refereeDetailsDataList.filter(
        (row: any) => row.rowId != newObj.rowId
      );
      this._refereeDetailsDataList.push(newObj);
    } else {
      newObj.rowId = 0;
      this._refereeDetailsDataList.push(newObj);
    }
    refereeDetailsForm.reset();
    formDirective.resetForm();
    refereeDetailsForm.patchValue({
      userId: this.userId,
      studentId: this.studentId
    })
    this.hideSaveBut = true;
    this.showAddForm = false;
    this.createFormGroups();
    setTimeout(() => {
      this.saveData();
    }, 500);
  }

  removeRefereeDetails(index: number) {
    this._refereeDetailsDataList.splice(index, 1);
    this.createFormGroups();
    setTimeout(() => {
      this.saveData();
    }, 500);
  }

  saveEditRefereeDetails(cardFormGroup: any, $index: number) {
    this._refereeDetailsDataList[this._refereeDetailsDataList.map((x: any, i: any) => [i, x]).filter(
      (x: any) => x[1]['rowId'] == this._refereeDetailsDataList[$index].rowId)[0][0]] = cardFormGroup.value
    cardFormGroup.disable();
    setTimeout(() => {
      this.saveData();
    }, 500);
  }

  checkDisableClass(cardFormGroup: any) {
    return this.sharedService.setDisableClass(cardFormGroup);
  }
}
