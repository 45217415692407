import { Component } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, FormGroupDirective, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { SharedService } from '../../shared/shared.service';
import { SisService } from '../sis.service';
import { MatTableDataSource } from '@angular/material/table';
import { Observable, Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'app-sis-education-details',
  templateUrl: './sis-education-details.component.html',
  styleUrls: ['./sis-education-details.component.scss']
})
export class SisEducationDetailsComponent {
  _onDestroy = new Subject<void>();
  academicHistoryForm: FormGroup;
  countries: any = [];
  levelOfStudyList: any = [];
  isLgScreen: boolean = false;

  dataSource: MatTableDataSource<any>;
  academicHistories: any;
  tableForms: any;
  addAnother: boolean;
  hideSaveBtn: boolean = false;
  resultVal: number;
  studentInfo: Object;
  studentDetails: string;
  formsList: Observable<any>;
  filteredCountries: Array<any> = [];
  filteredCountriesForEdit: Array<any> = [];
  isCountriesReturned: Subject<any> = new Subject();
  _countriesFiltered: Subject<any> = new Subject();
  isFirstTime = false;
  check: boolean = true;
  studentId: number;
  userId: number;
  validateUpdateResult: boolean;
  startDate: any;
  endDateEdit: any;
  startDateEdit: any;
  endDate: any;
  currentDate: Date;
  upcomingDate: Date;
  currentYear: number;
  sectionIndex: number = 7;

  public countryFilterCtrl: FormControl = new FormControl();
  countryFilteredList: Array<any> = [];

  public editCountryFilterCtrl: FormControl = new FormControl();
  editCountryFilteredList: Array<any> = [];

  manualSave: boolean;
  constructor(private router: Router, private fb: FormBuilder, private toastr: ToastrService,
    private activate: ActivatedRoute, private sharedService: SharedService, private sisService: SisService) { }

  ngOnInit(): void {
    this.currentDate = new Date();
    this.currentYear = this.currentDate.getFullYear();
    this.upcomingDate = new Date(this.currentYear + 3, 11, 31);
    this.studentId = this.sharedService.getStudentId();
    this.userId = this.sharedService.getUserId();
    this.isLgScreen = this.sharedService.isLgScreen();
    this.manualSave = false;
    this.getLevelOfStudyList();
    this.sharedService.scrollToTop();

    this.academicHistoryForm = new FormGroup({
      levelOfStudy: new FormControl('', [Validators.required, Validators.pattern(/(\s*[^\s]+\s*)+/)]),
      institution: new FormControl('', [Validators.required, Validators.pattern(/(\s*[^\s]+\s*)+/)]),
      countryId: new FormControl('', [Validators.required]),
      course: new FormControl('', [Validators.required, Validators.pattern(/(\s*[^\s]+\s*)+/)]),
      startDate: new FormControl('', [Validators.required, Validators.pattern(/(\s*[^\s]+\s*)+/)]),
      endDate: new FormControl('', [Validators.required, Validators.pattern(/(\s*[^\s]+\s*)+/)]),
      resultPercentage: new FormControl(null, [Validators.pattern(/^\d+(\.\d{1,2})?$/), Validators.min(0), Validators.max(100)]),
      result: new FormControl(null, [Validators.pattern(/^\d+(\.\d{1,2})?$/), Validators.min(0), Validators.max(10)]),
      outOf: new FormControl(null, [Validators.pattern(/^\d+(\.\d{1,2})?$/), Validators.min(1), Validators.max(10)]),
      rowId: new FormControl(null),
      countriesFilter: new FormControl(null),
      dummyCountries: new FormControl(null),
      fulltimeorParttime: new FormControl('', [Validators.required]),
      gradingScore: new FormControl(''),
      resultOther: new FormControl(null, [Validators.pattern(/^\d+(\.\d{1,2})?$/), Validators.min(0), Validators.max(100)]),
    });
    this.academicHistoryForm.valueChanges.subscribe(x => {
      this.check = !((x.result && x.outOf) || x.resultPercentage || x.resultOther);
      if ((x.result && !x.outOf) || (!x.result && x.outOf))
        this.check = true;
      if (this.academicHistoryForm.controls['resultPercentage'].value === '') {
        this.academicHistoryForm.controls['resultPercentage'].setValue(null);
      }

    })

    this.academicHistoryForm.controls['gradingScore'].valueChanges.subscribe(data => {
      this.academicHistoryForm.controls['resultPercentage'].setValue(null);
      this.academicHistoryForm.controls['resultOther'].setValue('');
      this.academicHistoryForm.controls['outOf'].setValue(null);
      this.academicHistoryForm.controls['result'].setValue(null);
    })
    this.getAcademicHistory();
    this.initiateForm();
    this.getCountries();
    this.academicHistoryForm.controls['countriesFilter'].valueChanges
      .pipe(takeUntil(this._onDestroy))
      .subscribe(() => {
        this.filterCountries();
      });

    /////////// To search Country /////////
    this.countryFilterCtrl.valueChanges
      .pipe(takeUntil(this._onDestroy))
      .subscribe(() => {
        this.filterCountry();
      });
    /////////// To search Country //////////

    /////////// To search Edit Country /////////
    this.editCountryFilterCtrl.valueChanges
      .pipe(takeUntil(this._onDestroy))
      .subscribe(() => {
        this.filterEditCountry();
      });
    /////////// To search Edit Country //////////

  }

  submitData() {
    this.toastr.success("Record Successfully Saved")

  }
  preventSpace(event: KeyboardEvent) {
    if (event.code === "Space") {
      event.preventDefault();
    }
  }

  saveData() {
    this.sisService.setSISEducationDetailsAtLocal(this.academicHistories);
    this.setPermission();
    if (this.router.url === '/sis-education-details' || this.manualSave) {
      this.sisService.saveSISData(this.sectionIndex).subscribe(() => {
        console.log('data saved education');
      })
    }
  }

  saveAndNext() {
    this.sisService.submitSIS(this.studentId, this.sectionIndex).subscribe((res: any) => {
      if (res) {
        this.router.navigate(['/sis-english-language-exam'])
      }
    }, error => {
      this.toastr.error("Something went wrong", error);
    });
  }

  getCountries() {
    if (this.sisService.getCountriesListFromLocalStorage()) {
      this.countries = this.sisService.getCountriesListFromLocalStorage();
      this.filterCountries();
      this.filteredCountriesForEditFun('', 0);
      this.filterCountry();
      this.filterEditCountry();
    } else {
      this.sisService.getCountriesList().subscribe((res) => {
        let response: any = res;
        if (response && response.data) {
          this.countries = response.data.items;
          this.sisService.setCountriesListInLocalStorage(this.countries);
          this.filterCountries();
          this.filteredCountriesForEditFun('', 0);
          this.filterCountry();
          this.filterEditCountry();
        }
      })
    }
  }

  getLevelOfStudyList() {
    this.sisService.getLevelOfStudyList().subscribe(res => {
      let response: any = res;
      if (response && response.data) {
        this.levelOfStudyList = response.data.items;
      }
    })
  }



  eventEmitterForCountriesInEditMode(items: object[]) {
    this.isCountriesReturned.subscribe(res => {
      if (res || this.isFirstTime) {
        this.filteredCountriesForEdit = [];
        for (let i = 0; i < items.length; i++) {
          if (this.countries?.length) {
            let currentItem = this.tableForms.get('academicHistoryTbl').get(`${i}`).get('countriesFilter');
            this.filteredCountriesForEdit.push([...this.countries]);
            currentItem.valueChanges
              .pipe(takeUntil(this._countriesFiltered))
              .subscribe(() => {
                this.filteredCountriesForEditFun(currentItem.value, i);
              });
          }
        }
        this.isFirstTime = true;
      }
    })
  }

  getAcademicHistory() {
    this.sisService.getSISEducationData(this.studentId, this.userId).subscribe((res: any) => {
      this.academicHistories = JSON.parse(res.data);
      this.saveData();
      this.patchValues();
      this.eventEmitterForCountriesInEditMode(this.academicHistories);
    })
  }

  patchValues() {
    this.tableForms = this.fb.group({
      academicHistoryTbl: this.fb.array(
        this.academicHistories.map((formControl: any) => this.fb.group({
          levelOfStudy: [formControl.levelOfStudy, [Validators.required, Validators.pattern(/(\s*[^\s]+\s*)+/)]],
          institution: [formControl.institution, [Validators.required, Validators.pattern(/(\s*[^\s]+\s*)+/)]],
          countryId: [formControl.countryId, [Validators.required]],
          course: [formControl.course, [Validators.required, Validators.pattern(/(\s*[^\s]+\s*)+/)]],
          resultPercentage: [formControl.resultPercentage, [Validators.pattern(/^\d+(\.\d{1,2})?$/), Validators.min(0), Validators.max(100)]],
          startDate: [formControl.startDate, [Validators.required, Validators.pattern(/(\s*[^\s]+\s*)+/)]],
          endDate: [formControl.endDate, [Validators.required, Validators.pattern(/(\s*[^\s]+\s*)+/)]],
          result: [formControl.result, [Validators.pattern(/^\d+(\.\d{1,2})?$/), Validators.min(0), Validators.max(10)]],
          outOf: [formControl.outOf, [Validators.pattern(/^\d+(\.\d{1,2})?$/), Validators.min(1), Validators.max(10)]],
          rowId: [formControl.rowId],
          countriesFilter: new FormControl(null),
          dummyCountries: this.academicHistoryForm.get('countryId').value,
          fulltimeorParttime: [formControl.fulltimeorParttime, [Validators.required]],
          gradingScore: [formControl.gradingScore, [Validators.required]],
          resultOther: [formControl.resultOther],
        }))
      )
    });
    this.tableForms.controls.academicHistoryTbl.disable();
    this.dataSource = new MatTableDataSource<any>(this.tableForms.controls.academicHistoryTbl.controls);
    this.formsList = this.dataSource.connect();
  }

  initiateForm(): FormGroup {
    return this.tableForms = this.fb.group({
      academicHistoryTbl: this.fb.array([])
    });
  }

  addAcademicHistory(formDirectiveGroup: FormGroupDirective) {
    const params = {
      "levelOfStudy": this.academicHistoryForm.get('levelOfStudy').value,
      "institution": this.academicHistoryForm.get('institution').value,
      "countryId": this.academicHistoryForm.get('countryId').value,
      "course": this.academicHistoryForm.get('course').value,
      "resultPercentage": this.academicHistoryForm.get('resultPercentage').value,
      "startDate": this.sharedService.formatDate(this.academicHistoryForm.get('startDate').value),
      "endDate": this.sharedService.formatDate(this.academicHistoryForm.get('endDate').value),
      "result": this.academicHistoryForm.get('result').value,
      "outOf": this.academicHistoryForm.get('outOf').value,
      "fulltimeorParttime": this.academicHistoryForm.get('fulltimeorParttime').value,
      "gradingScore": this.academicHistoryForm.get('gradingScore').value,
      "studentId": this.studentId,
      "rowId": 0,
      "resultOther": this.academicHistoryForm.get('resultOther').value
    }
    this.academicHistories.push(params);
    this.hideSaveBtn = false;
    this.patchValues();
    this.eventEmitterForCountriesInEditMode(this.academicHistories);
    this.academicHistoryForm.reset();
    setTimeout(() => {
      this.manualSave = true;
      this.saveData();
    }, 500);
  }


  editAcademicHistory(cardFormGroup: FormGroup, $index: number) {
    let params = {
      "levelOfStudy": cardFormGroup.get('levelOfStudy').value,
      "institution": cardFormGroup.get('institution').value,
      "countryId": cardFormGroup.get('countryId').value,
      "course": cardFormGroup.get('course').value,
      "resultPercentage": cardFormGroup.get('resultPercentage').value,
      "startDate": this.sharedService.formatDate(cardFormGroup.get('startDate').value),
      "endDate": this.sharedService.formatDate(cardFormGroup.get('endDate').value),
      "result": cardFormGroup.get('result').value,
      "outOf": cardFormGroup.get('outOf').value,
      "fulltimeorParttime": cardFormGroup.get('fulltimeorParttime').value,
      "gradingScore": cardFormGroup.get('gradingScore').value,
      "studentId": this.academicHistories[$index].studentId,
      "rowId": this.academicHistories[$index].rowId,
      "resultOther": cardFormGroup.get('resultOther').value,
      "dummyCountries": this.academicHistoryForm.get('countryId').value
    }
    this.academicHistories[this.academicHistories.map((x: any, i: any) => [i, x]).filter(
      (x: any) => x[1]['rowId'] == this.academicHistories[$index].rowId)[0][0]] = params;

    cardFormGroup.disable();
    this.hideSaveBtn = false;
    this.patchValues();
    this.eventEmitterForCountriesInEditMode(this.academicHistories);
    this.academicHistoryForm.reset();
    setTimeout(() => {
      this.manualSave = true;
      this.saveData();
    }, 500);
  }

  onChangeGradingScore(index: number) {
    this.resetFormControls(index, 'resultPercentage');
    this.resetFormControls(index, 'outOf');
    this.resetFormControls(index, 'result');
    this.resetFormControls(index, 'resultOther');
    this.validateUpdateResult = true;

  }

  getLayout() {
    if (this.isLgScreen) {
      return 'row'
    } else {
      return 'column'
    }
  }

  deleteRow($index: number) {
    this.academicHistories.splice($index, 1);
    this.patchValues();
    this.eventEmitterForCountriesInEditMode(this.academicHistories);
    setTimeout(() => {
      this.manualSave = true;
      this.saveData();
    }, 500);
  }

  addValidationToResult() {
    if (this.academicHistoryForm.controls['outOf'].value === '' && this.academicHistoryForm.controls['result'].value === '') {
      this.academicHistoryForm.controls['outOf'].clearValidators();
      this.academicHistoryForm.controls['outOf'].updateValueAndValidity();
      this.academicHistoryForm.controls['result'].clearValidators();
      this.academicHistoryForm.controls['result'].updateValueAndValidity();
    } else {
      this.academicHistoryForm.controls['result'].markAllAsTouched();
      this.academicHistoryForm.controls['result'].setValidators([Validators.pattern(/^\d+(\.\d{1,2})?$/), Validators.min(0), Validators.max(this.academicHistoryForm.value.outOf)])
      this.academicHistoryForm.controls['outOf'].setValidators([Validators.pattern(/^\d+(\.\d{1,2})?$/), Validators.min(1), Validators.max(10)])
      this.academicHistoryForm.controls['result'].updateValueAndValidity();
    }
  }
  ValidationToResult() {
    if (this.academicHistoryForm.controls['result'].value === '' && this.academicHistoryForm.controls['outOf'].value === '') {
      this.academicHistoryForm.controls['result'].setValue(null);
      this.academicHistoryForm.controls['outOf'].clearValidators();
      this.academicHistoryForm.controls['outOf'].updateValueAndValidity();
      this.academicHistoryForm.controls['result'].clearValidators();
      this.academicHistoryForm.controls['result'].updateValueAndValidity();
    } else {
      this.academicHistoryForm.controls['outOf'].markAllAsTouched();
      this.academicHistoryForm.controls['outOf'].setValidators([Validators.pattern(/^\d+(\.\d{1,2})?$/), Validators.min(1), Validators.max(10)])
      this.academicHistoryForm.controls['outOf'].updateValueAndValidity();
    }
  }


  validateResult(index: number) {
    let result, outOf, resPercentage ,resultOther;
    result = this.tableForms.get('academicHistoryTbl').get(index.toString()).get('result')?.value;
    outOf = this.tableForms.get('academicHistoryTbl').get(index.toString()).get('outOf')?.value;
    resPercentage = this.tableForms.get('academicHistoryTbl').get(index.toString()).get('resultPercentage')?.value;
    resultOther = this.tableForms.get('academicHistoryTbl').get(index.toString()).get('resultOther')?.value;

    if (outOf === '' || outOf === null) {
      this.resetFormControls(index, 'outOf');
      this.resetFormControls(index, 'result');
      } else {
      this.tableForms.get('academicHistoryTbl').get(index.toString()).get('result').markAsTouched();
      this.tableForms.get('academicHistoryTbl').get(index.toString()).get('outOf').markAsTouched();
      this.tableForms.get('academicHistoryTbl').get(index.toString()).get('result').setValidators([Validators.required, Validators.pattern(/^\d+(\.\d{1,2})?$/), Validators.min(0), Validators.max(outOf)]);
      this.tableForms.get('academicHistoryTbl').get(index.toString()).get('outOf').setValidators([Validators.required, Validators.pattern(/^\d+(\.\d{1,2})?$/), Validators.min(1), Validators.max(10)]);
      this.tableForms.get('academicHistoryTbl').get(index.toString()).get('outOf').updateValueAndValidity();
      this.tableForms.get('academicHistoryTbl').get(index.toString()).get('result').updateValueAndValidity();
    }
    this.validateUpdateResult = !((result && outOf));
    if ((result && !outOf) || (!result && outOf)) {
      this.validateUpdateResult = true;
    }
  }
  checkValidateResult(index: number) {
    let result, outOf, resPercentage;
    result = this.tableForms.get('academicHistoryTbl').get(index.toString()).get('result')?.value;
    outOf = this.tableForms.get('academicHistoryTbl').get(index.toString()).get('outOf')?.value;
    resPercentage = this.tableForms.get('academicHistoryTbl').get(index.toString()).get('resultPercentage')?.value;
    if (result === '' || result === null) {
      this.resetFormControls(index, 'outOf');
      this.resetFormControls(index, 'result');
       } else {
      this.tableForms.get('academicHistoryTbl').get(index.toString()).get('result').markAsTouched();
      this.tableForms.get('academicHistoryTbl').get(index.toString()).get('outOf').markAsTouched();
      this.tableForms.get('academicHistoryTbl').get(index.toString()).get('outOf').setValidators([Validators.required, Validators.pattern(/^\d+(\.\d{1,2})?$/), Validators.min(1), Validators.max(10)]);
      this.tableForms.get('academicHistoryTbl').get(index.toString()).get('result').setValidators([Validators.pattern(/^\d+(\.\d{1,2})?$/), Validators.min(0), Validators.max(outOf)]);
      this.tableForms.get('academicHistoryTbl').get(index.toString()).get('result').updateValueAndValidity();
      this.tableForms.get('academicHistoryTbl').get(index.toString()).get('outOf').updateValueAndValidity();
    }
    this.validateUpdateResult = !((result && outOf) || resPercentage);
    if ((result && !outOf) || (!result && outOf)) {
      this.validateUpdateResult = true;
    }
  }


  validatResultPercentage(index: number) {
    let result, outOf ,resPercentage;
    result = this.tableForms.get('academicHistoryTbl').get(index.toString()).get('result')?.value;
    outOf = this.tableForms.get('academicHistoryTbl').get(index.toString()).get('outOf')?.value;
    resPercentage = this.tableForms.get('academicHistoryTbl').get(index.toString()).get('resultPercentage')?.value;
    resPercentage = resPercentage ? resPercentage.trim() : null;
    if (resPercentage === '' || resPercentage === null) {
      this.tableForms.get('academicHistoryTbl').get(index.toString()).get('resultPercentage')?.setValue(null);
    }
    this.tableForms.get('academicHistoryTbl').get(index.toString()).get('resultPercentage').setValidators([Validators.pattern(/^\d+(\.\d{1,2})?$/), Validators.min(0), Validators.max(100)]);
    this.tableForms.get('academicHistoryTbl').get(index.toString()).get('resultPercentage').updateValueAndValidity();
    this.resetFormControls(index, 'outOf');
    this.resetFormControls(index, 'result');
    this.validateUpdateResult = !((result && outOf) || resPercentage);
    if ((result && !outOf) || (!result && outOf)) {
      this.validateUpdateResult = true;
    }
  }
    resetFormControls(index: number, controlName: string) {
    const control = this.tableForms.get('academicHistoryTbl').get(index.toString()).get(controlName);
    control?.setValue(null);
    control.clearValidators();
    control.updateValueAndValidity();
  }
  validateResultOther(index: number) {
    let  resultOther, result ,outOf;
    result = this.tableForms.get('academicHistoryTbl').get(index.toString()).get('result')?.value;
    outOf = this.tableForms.get('academicHistoryTbl').get(index.toString()).get('outOf')?.value;
    resultOther = this.tableForms.get('academicHistoryTbl').get(index.toString()).get('resultOther')?.value;
    resultOther = resultOther !== null ? resultOther.trim() : null;
    if (resultOther === '' || resultOther === null) {
      this.resetFormControls(index, 'resultOther');
      this.validateUpdateResult = true;
      }else{
        this.resetFormControls(index, 'outOf');
        this.resetFormControls(index, 'result');
           this.validateUpdateResult = false;
        }
   }

  private filterCountries() {
    let search = this.academicHistoryForm?.controls?.['countriesFilter']?.value;
    search = search ? search : '';
    this.filteredCountries = this.countries.filter((country: any) => country?.name?.toLowerCase().startsWith(search.toLowerCase()));
  }

  private filteredCountriesForEditFun(value: string, index: number) {
    value = value ? value : '';
    this.filteredCountriesForEdit[index] = this.countries.filter((country: any) => country?.name?.toLowerCase().startsWith(value.toLowerCase()));
  }

  getSelectedValue(countryId: number) {
    if (this.countries?.length) {
      const filteredCountries: object[] = this.countries.filter((country: any) => country['id'] === countryId);
      return filteredCountries[0];
    }
  }

  ngOnDestroy() {
    this._onDestroy.next();
    this._onDestroy.complete();
    this.isCountriesReturned.complete();
    this._countriesFiltered.complete();
  }

  checkDisableClass(cardFormGroup: any) {
    return this.sharedService.setDisableClass(cardFormGroup);
  }

  setPermission() {
    setTimeout(() => {
      if (this.academicHistories.length > 0) {
        this.sisService.removePendingItems(this.sectionIndex);
        this.sisService.setSectionWisePermission('educationDetailsPerm', true);
      } else {
        this.sisService.setPendingItems('Education Details', this.sectionIndex);
        this.sisService.setSectionWisePermission('educationDetailsPerm', false);
      }
      this.sisService.emitSubmitPermissionChangeAlert('true');
    }, 100);
  }

  filterCountry() {
    let search = this.countryFilterCtrl.value;
    search = search ? search : '';
    this.countryFilteredList = this.countries.filter((x: any) => x?.name?.toLowerCase().startsWith(search.toLowerCase()));
  }

  filterEditCountry() {
    let search = this.editCountryFilterCtrl.value;
    search = search ? search : '';
    this.editCountryFilteredList = this.countries.filter((x: any) => x?.name?.toLowerCase().startsWith(search.toLowerCase()));
  }
}
