<app-sis-header *ngIf="!isLgScreen" class="sis-header"></app-sis-header>
<div id="personalDetailsPageOne" class="container" [ngClass]="!isLgScreen? 'mt-6':''">

    <div fxLayoutGap="12px" class="mb-2 text-left" *ngIf="isLgScreen">
        <h3>English Language Exam<span class="error"> *</span></h3>

        <p>Have you appeared for any English language test in the last two years? *</p>
        <div>
            <mat-radio-group aria-label="Select an option" [(ngModel)]="englishExamNotRequired">
                <mat-radio-button [value]="true" color="primary" (click)="setPermission()"><span
                        style="font-weight: bold;">Yes</span></mat-radio-button>
                <mat-radio-button [value]="false" color="primary" (click)="checkDataList()"><span
                        style="font-weight: bold;">No</span></mat-radio-button>
            </mat-radio-group>
        </div>
    </div>

    <fieldset fxLayoutGap="12px" class="mb-2" *ngIf="!isLgScreen">
        <legend>English Language Exam<span class="error"> *</span></legend>

        <p>Have you appeared for any English language test in the last two years? *</p>
        <div>
            <mat-radio-group aria-label="Select an option" [(ngModel)]="englishExamNotRequired">
                <mat-radio-button [value]="true" color="primary" (click)="setPermission()"><span
                        style="font-weight: bold;">Yes</span></mat-radio-button>
                <mat-radio-button [value]="false" color="primary" (click)="checkDataList()"><span
                        style="font-weight: bold;">No</span></mat-radio-button>
            </mat-radio-group>
        </div>
    </fieldset>

    <div *ngIf="languageExamList.length" [ngClass]="!isLgScreen? 'text-center':'text-left'">
        <form [formGroup]="tableForm">
            <ng-container formArrayName="languageExamFormTbl">
                <div *ngFor="let cardFormGroup of formsList | async;let $index = index" class="custom-card my-5 p-4">
                    <fieldset class="fieldsetArray">
                        <legend *ngIf="$index==0">IF YES, Please fill the fields</legend>
                        <div fxLayout="column"
                            *ngIf="$index==0 && !addAnother && isLgScreen && englishExamNotRequired && languageExamList.length>0">
                            <button fxFlexAlign="end" mat-raised-button color="primary" (click)="addAnother = true;">Add
                                more</button>
                        </div>
                        <ng-container [formGroup]="cardFormGroup">
                            <div fxLayout="row wrap" [ngClass]="isLgScreen? 'ml-2':''" fxLayout.lt-sm="column"
                                fxLayoutGap.xs="5%" class="mb-2">

                                <div class="inputParent">
                                    <mat-label class="inputLabel" [ngClass]="checkDisableClass(cardFormGroup)">Test
                                        taken<span class="red"> *</span></mat-label>
                                    <mat-form-field appearance="outline" class="input">
                                        <mat-select formControlName="TestTakenId"
                                            [(ngModel)]="cardFormGroup.get('TestTakenId').value">
                                            <mat-option value="">Please Select</mat-option>
                                            <mat-option *ngFor="let row of testTakenList" [value]="row.id">
                                                <span [ngStyle]="{'font-weight': row.mostTakenTest ? '700' : '400' }">
                                                    {{row.name}}
                                                </span>
                                            </mat-option>
                                        </mat-select>
                                        <mat-error
                                            *ngIf="tableForm.get('languageExamFormTbl').get($index.toString()).get('TestTakenId').hasError('required')">
                                            Test Taken is required.
                                        </mat-error>
                                    </mat-form-field>
                                </div>

                                <div class="overallScoreSection" [ngClass]="checkDisableClass(cardFormGroup)">
                                    <div class="inputParent">
                                        <mat-label class="inputLabel" [ngClass]="checkDisableClass(cardFormGroup)"
                                            [ngClass]="tableForm.get('languageExamFormTbl').get($index.toString()).get('Score').value && checkMinMax(tableForm.get('languageExamFormTbl').get($index.toString()).get('TestTakenId').value,tableForm.get('languageExamFormTbl').get($index.toString()).get('Score').value, false)? 'error':''">Overall
                                            Score<span class="red"> *</span></mat-label>
                                        <mat-form-field appearance="outline" class="input">
                                            <input formControlName="Score" type="text" matInput
                                                [(ngModel)]="cardFormGroup.get('Score').value" placeholder="Score">
                                            <mat-error
                                                *ngIf="tableForm.get('languageExamFormTbl').get($index.toString()).get('Score').hasError('required')">
                                                Score is required.
                                            </mat-error>
                                        </mat-form-field>
                                        <span class="mat-error"
                                            *ngIf="tableForm.get('languageExamFormTbl').get($index.toString()).get('Score').value && checkMinMax(tableForm.get('languageExamFormTbl').get($index.toString()).get('TestTakenId').value,tableForm.get('languageExamFormTbl').get($index.toString()).get('Score').value, false)">
                                            {{checkMinMax(tableForm.get('languageExamFormTbl').get($index.toString()).get('TestTakenId').value,tableForm.get('languageExamFormTbl').get($index.toString()).get('Score').value,
                                            true)}}
                                        </span>
                                    </div>
                                </div>

                                <div class="inputParent">
                                    <mat-label class="inputLabel" [ngClass]="checkDisableClass(cardFormGroup)">Date of
                                        test<span class="red"> *</span></mat-label>
                                    <mat-form-field appearance="outline" class="input">
                                        <input formControlName="DateOfTest" [matDatepicker]="datePicker" matInput
                                            [(ngModel)]="cardFormGroup.get('DateOfTest').value" [min]="startDate"
                                            placeholder="Date of test" [max]="currentDate">
                                        <mat-datepicker-toggle [for]="datePicker" matSuffix></mat-datepicker-toggle>
                                        <mat-datepicker #datePicker></mat-datepicker>
                                        <mat-error
                                            *ngIf="tableForm.get('languageExamFormTbl').get($index.toString()).get('DateOfTest').hasError('required')">
                                            Date of Test is required.
                                        </mat-error>
                                    </mat-form-field>
                                </div>

                                <div class="inputParent">
                                    <mat-label class="inputLabel" [ngClass]="checkDisableClass(cardFormGroup)">Test
                                        Reference ID (If Available)</mat-label>
                                    <mat-form-field appearance="outline" class="input">
                                        <input formControlName="TestReferenceID" type="text" matInput
                                            [(ngModel)]="cardFormGroup.get('TestReferenceID').value"
                                            placeholder="Reference Id">
                                    </mat-form-field>
                                </div>

                                <div class="inputParent">
                                    <mat-label class="inputLabel"
                                        [ngClass]="checkDisableClass(cardFormGroup)">Remarks</mat-label>
                                    <mat-form-field appearance="outline" class="input">
                                        <input formControlName="Remarks" type="text" matInput
                                            [(ngModel)]="cardFormGroup.get('Remarks').value" placeholder="Remarks">
                                    </mat-form-field>
                                </div>
                            </div>
                            <div fxLayout="row" fxLayoutAlign="end center" class="my-2 md:my-0">
                                <ng-container *ngIf="!cardFormGroup.disabled">
                                    <button mat-flat-button color="primary" [disabled]="cardFormGroup.invalid || checkMinMax(tableForm.get('languageExamFormTbl').get($index.toString()).get('TestTakenId').value,tableForm.get('languageExamFormTbl').get($index.toString()).get('Score').value, false)"
                                        class="mr-1" (click)="updateLanguageExam(cardFormGroup, $index)">Save</button>
                                    <button mat-flat-button color="warn"
                                        (click)="cardFormGroup.disable();">Cancel</button>
                                </ng-container>
                                <ng-container *ngIf="cardFormGroup.disabled">
                                    <button mat-flat-button color="primary" class="mr-1"
                                        (click)="cardFormGroup.enable();">Edit</button>
                                    <button mat-flat-button color="warn" (click)="deleteLanguageExam($index)">
                                        Delete
                                    </button>
                                </ng-container>
                            </div>
                        </ng-container>
                    </fieldset>
                </div>
            </ng-container>
        </form>
    </div>

    <fieldset [ngClass]="!isLgScreen? 'text-center':'text-left'"
        *ngIf="englishExamNotRequired==1 && (addAnother || languageExamList.length===0)" class="fieldsetArray">
        <legend *ngIf="languageExamList.length==0">IF YES, Please fill the fields</legend>
        <form #formDirectiveGroup="ngForm" [formGroup]="languageExamForm"
            (ngSubmit)="addLanguageExam(formDirectiveGroup)">
            <div fxLayoutGap="10px" style="margin-top: 8px;" [ngClass]="isLgScreen? 'ml-2':''">

                <div class="inputParent">
                    <mat-label class="inputLabel">Test taken<span class="red"> *</span></mat-label>
                    <mat-form-field appearance="outline" class="input">
                        <mat-select formControlName="TestTakenId" (ngModelChange)="onChange($event)">
                            <mat-option value="">Please Select</mat-option>
                            <mat-option *ngFor="let item of testTakenList" [value]="item.id">
                                <span [ngStyle]="{'font-weight': item.mostTakenTest ? '700' : '400' }">
                                    {{item.name}}
                                </span>
                            </mat-option>
                        </mat-select>
                        <mat-error *ngIf="languageExamForm.get('TestTakenId').hasError('required')">
                            Test taken is required
                        </mat-error>
                    </mat-form-field>
                </div>

                <div class="inputParent overallScoreSection">
                    <mat-label class="inputLabel"
                        [ngClass]="languageExamForm.get('Score').value && checkMinMax(languageExamForm.get('TestTakenId').value,languageExamForm.get('Score').value, false)? 'error':''">Overall
                        Score<span class="red"> *</span></mat-label>
                    <mat-form-field appearance="outline" class="input">
                        <input type="text" formControlName="Score" matInput placeholder="Overall Score" />
                        <mat-error *ngIf="languageExamForm.get('Score').hasError('required')">
                            Score is required
                        </mat-error>
                    </mat-form-field>
                    <span class="mat-error"
                        *ngIf="languageExamForm.get('Score').value && checkMinMax(languageExamForm.get('TestTakenId').value,languageExamForm.get('Score').value, false)">
                        {{checkMinMax(languageExamForm.get('TestTakenId').value,languageExamForm.get('Score').value,
                        true)}}
                    </span>
                </div>

                <div class="inputParent">
                    <mat-label class="inputLabel">Date of test<span class="red"> *</span></mat-label>
                    <mat-form-field appearance="outline" class="input">
                        <input formControlName="DateOfTest" placeholder="Date of test" [matDatepicker]="DateOfTest"
                            matInput [min]="startDate" [max]="currentDate">
                        <mat-datepicker-toggle [for]="DateOfTest" matSuffix></mat-datepicker-toggle>
                        <mat-datepicker #DateOfTest></mat-datepicker>
                        <mat-error *ngIf="languageExamForm.get('DateOfTest').hasError('required')">
                            Date of test is required
                        </mat-error>
                    </mat-form-field>
                </div>

                <div class="inputParent">
                    <mat-label class="inputLabel">Test Reference ID (If Available)</mat-label>
                    <mat-form-field appearance="outline" class="input">
                        <input type="text" placeholder="Test Reference Id" formControlName="TestReferenceID" matInput />
                    </mat-form-field>
                </div>

                <div class="inputParent">
                    <mat-label class="inputLabel">Remarks</mat-label>
                    <mat-form-field appearance="outline" class="input">
                        <input type="text" formControlName="Remarks" matInput placeholder="Remarks" />
                    </mat-form-field>
                </div>
            </div>
            <div fxLayout="row" fxLayoutAlign="end">
                <button *ngIf="addAnother || dataSource?.data.length == 0" mat-flat-button color="primary"
                    (click)="addAnother = true;" class="mr-1" [disabled]="languageExamForm.invalid || checkMinMax(languageExamForm.get('TestTakenId').value,languageExamForm.get('Score').value, false) ">Add</button>
                <button *ngIf="languageExamList.length>0" mat-flat-button color="warn" (click)="addAnother = false;">
                    Cancel
                </button>
            </div>
        </form>
    </fieldset>

    <div class="addMoreBtnSection" *ngIf="!isLgScreen" (click)="addAnother = true;">
        <mat-icon class="icon-display">add_circle_outline</mat-icon>
        <h4>Add More</h4>
    </div>

    <div class="mb-1 mt-1" *ngIf="!isLgScreen">
        <button mat-raised-button color="primary" [ngClass]="disableSaveNextBtn?'disabledBtn':'enabledBtn'"
            [disabled]="disableSaveNextBtn" class="saveNextBtn" (click)="saveAndNext()" type="button">Save &
            Next</button>
    </div>
</div>