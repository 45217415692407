<mat-dialog-content>
    <div>
        <div id="head">
            <h4>{{message}}</h4>
        </div>
        <div id="list">
            <div *ngFor="let item of pendingItems; let index=index;">
                {{index+1}}. {{item.sectionName}} <span *ngIf="item.items.length>0"> Fields :-</span>
                <div *ngIf="item.items.length>0">
                    <div style="margin-left: 2rem" *ngFor="let subItem of item.items; let subIndex=index;">
                        {{subIndex+1}}. {{subItem | formatPendingFieldsDisplay}}
                    </div>
                </div>
            </div>
        </div>
    </div>
</mat-dialog-content>
<mat-dialog-actions align="center">
    <button mat-raised-button color="primary" class="close" mat-dialog-close tabindex="-1">Close</button>
</mat-dialog-actions>