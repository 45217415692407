import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { PostAuthRoutingModule } from './post-auth-routing.module';
import { SisFormComponent } from './sis-form/sis-form.component';
import { SisHeaderComponent } from './sis-header/sis-header.component';
import { SisPersonalDetailsOneComponent } from './sis-personal-details-one/sis-personal-details-one.component';
import { SisPersonalDetailsTwoComponent } from './sis-personal-details-two/sis-personal-details-two.component';
import { SisPersonalDetailsThreeComponent } from './sis-personal-details-three/sis-personal-details-three.component';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatCardModule } from '@angular/material/card';
import { MatSelectModule } from '@angular/material/select';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatNativeDateModule } from '@angular/material/core';
import { MatRadioModule } from '@angular/material/radio';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FlexLayoutModule } from '@angular/flex-layout';
import { SisTravelHistoryComponent } from './sis-travel-history/sis-travel-history.component';
import { SisImmigrationHistoryComponent } from './sis-immigration-history/sis-immigration-history.component';
import { SisVisaRejectionComponent } from './sis-visa-rejection/sis-visa-rejection.component';
import { SisEducationDetailsComponent } from './sis-education-details/sis-education-details.component';
import { SisEnglishLanguageExamComponent } from './sis-english-language-exam/sis-english-language-exam.component';
import { SisWorkExperienceComponent } from './sis-work-experience/sis-work-experience.component';
import { SisReferenceComponent } from './sis-reference/sis-reference.component';
import { SisDocumentsComponent } from './sis-documents/sis-documents.component';
import { SisDeclarationComponent } from './sis-declaration/sis-declaration.component';
import { SisOtherExamsComponent } from './sis-other-exams/sis-other-exams.component';
import { HttpClientModule } from '@angular/common/http';
import { SharedModule } from '../shared/shared.module';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { DatePipe } from '@angular/common';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { NgxMatIntlTelInputComponent } from 'ngx-mat-intl-tel-input';

export const MY_FORMATS = {
  display: {
    dateInput: 'DD MMM YYYY', // this is how your date will get displayed on the Input
    monthYearLabel: 'MMMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  }
};

@NgModule({
  declarations: [
    SisFormComponent,
    SisHeaderComponent,
    SisPersonalDetailsOneComponent,
    SisPersonalDetailsTwoComponent,
    SisPersonalDetailsThreeComponent,
    SisTravelHistoryComponent,
    SisImmigrationHistoryComponent,
    SisVisaRejectionComponent,
    SisEducationDetailsComponent,
    SisEnglishLanguageExamComponent,
    SisWorkExperienceComponent,
    SisReferenceComponent,
    SisDocumentsComponent,
    SisDeclarationComponent,
    SisOtherExamsComponent
  ],
  imports: [
    CommonModule,
    PostAuthRoutingModule,
    BrowserModule,
    BrowserAnimationsModule,
    MatInputModule,
    MatFormFieldModule,
    MatButtonModule,
    MatIconModule,
    MatCardModule,
    MatSelectModule,
    MatDatepickerModule,
    MatCheckboxModule,
    MatNativeDateModule,
    MatRadioModule,
    FormsModule,
    ReactiveFormsModule,
    FlexLayoutModule,
    HttpClientModule,
    SharedModule,
    NgxMatSelectSearchModule,
    NgxMatIntlTelInputComponent
  ],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE],
    },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
    DatePipe,
  ],
})
export class PostAuthModule { }
