<app-sis-header *ngIf="!isLgScreen" class="sis-header"></app-sis-header>
<div id="personalDetailsPageOne" class="container" [ngClass]="!isLgScreen? 'mt-6':''">
    <div *ngIf="workDetailsList.length>0">
        <form [formGroup]="cardWorkDetialsForm">
            <ng-container formArrayName="cardsForm">
                <div *ngFor="let cardFormGroup of formsList | async;let $index = index" class="custom-card my-5 p-4">
                    <fieldset fxLayoutGap="12px" class="fieldsetArray"
                        [ngClass]="!isLgScreen? 'text-center':'text-left'">
                        <legend *ngIf="$index==0">Work Experience / Internships <span *ngIf="isLgScreen"
                                style="color:red;font-size: 14px;">(if available)</span></legend>
                        <span *ngIf="$index==0 && !isLgScreen">
                            <p style="margin-top: 0;color: red;">(if available)</p>
                        </span>
                        <div fxLayout="column"
                            *ngIf="$index==0 && !addAnother && isLgScreen && workDetailsList.length>0">
                            <button fxFlexAlign="end" mat-raised-button color="primary" (click)="showAddForm()">Add
                                more</button>
                        </div>
                        <ng-container [formGroup]="cardFormGroup">
                            <div fxLayout="row wrap" [ngClass]="isLgScreen? 'ml-2':''" fxLayout.lt-sm="column"
                                fxLayoutGap.xs="5%" class="mb-2">

                                <div class="inputParent">
                                    <mat-label class="inputLabel" [ngClass]="checkDisableClass(cardFormGroup)">Job
                                        title<span class="red">*</span></mat-label>
                                    <mat-form-field appearance="outline" class="input">
                                        <input type="text" matInput formControlName="jobTitle" placeholder="Job title">
                                        <mat-error *ngIf="cardFormGroup.get('jobTitle')?.errors?.required">
                                            Job title is <strong>required</strong>
                                        </mat-error>
                                    </mat-form-field>
                                </div>

                                <div class="inputParent">
                                    <mat-label class="inputLabel" [ngClass]="checkDisableClass(cardFormGroup)">Name of
                                        organisation<span class="red">*</span></mat-label>
                                    <mat-form-field appearance="outline" class="input">
                                        <input type="text" matInput formControlName="organisationName"
                                            placeholder="Name of organisation">
                                        <mat-error *ngIf="cardFormGroup.get('organisationName')?.errors?.required">
                                            Name is <strong>required</strong>
                                        </mat-error>
                                    </mat-form-field>
                                </div>

                                <div class="inputParent">
                                    <mat-label class="inputLabel" [ngClass]="checkDisableClass(cardFormGroup)">Address
                                        of organisation<span class="red">*</span></mat-label>
                                    <mat-form-field appearance="outline" class="input">
                                        <input type="text" matInput formControlName="organisationAddress"
                                            placeholder="Address of organisation">
                                        <mat-error *ngIf="cardFormGroup.get('organisationAddress')?.errors?.required">
                                            Address is <strong>required</strong>
                                        </mat-error>
                                    </mat-form-field>
                                </div>

                                <div class="inputParent ngx-mat-section">
                                    <mat-label class="inputLabel" [ngClass]="checkDisableClass(cardFormGroup)">Phone
                                        Number<span class="red">*</span></mat-label>
                                    <mat-form-field appearance="outline" class="input">
                                        <ngx-mat-intl-tel-input [preferredCountries]="['in']" name="companyNumber"
                                            formControlName="companyNumber"></ngx-mat-intl-tel-input>
                                        <mat-error
                                            *ngIf="cardFormGroup.get('companyNumber')?.hasError('validatePhoneNumber')">
                                            Phone number is <strong>invalid</strong>
                                        </mat-error>
                                    </mat-form-field>
                                </div>

                                <div class="inputParent">
                                    <mat-label class="inputLabel" [ngClass]="checkDisableClass(cardFormGroup)">From
                                        date<span class="red">*</span></mat-label>
                                    <mat-form-field appearance="outline" class="input">
                                        <input matInput [matDatepicker]="fromDateInput" formControlName="fromDate"
                                            [max]="today" readonly placeholder="From date">
                                        <mat-datepicker-toggle matSuffix [for]="fromDateInput"></mat-datepicker-toggle>
                                        <mat-datepicker #fromDateInput></mat-datepicker>
                                        <mat-error *ngIf="cardFormGroup.get('fromDate')?.errors?.required">
                                            From date is <strong>required</strong>
                                        </mat-error>
                                    </mat-form-field>
                                </div>

                                <div class="inputParent" *ngIf="!cardFormGroup.get('stillWork').value">
                                    <mat-label class="inputLabel" [ngClass]="checkDisableClass(cardFormGroup)">To
                                        date<span class="red">*</span></mat-label>
                                    <mat-form-field appearance="outline" class="input">
                                        <input matInput [matDatepicker]="toDateInput" placeholder="To date"
                                            formControlName="toDate" [min]="cardFormGroup.get('fromDate').value"
                                            [max]="today" readonly>
                                        <mat-datepicker-toggle matSuffix [for]="toDateInput"></mat-datepicker-toggle>
                                        <mat-datepicker #toDateInput></mat-datepicker>
                                        <mat-error *ngIf="cardFormGroup.get('toDate')?.errors?.required">
                                            To date is <strong>required</strong>
                                        </mat-error>
                                        <mat-error
                                            *ngIf="!cardFormGroup.get('toDate')?.hasError('matDatepickerMax') && cardFormGroup.get('toDate')?.touched">
                                            From date must be earlier than to date
                                        </mat-error>
                                    </mat-form-field>
                                </div>

                                <div class="inputParent">
                                    <mat-label class="inputLabel"
                                        [ngClass]="checkDisableClass(cardFormGroup)">Duties</mat-label>
                                    <mat-form-field appearance="outline" class="input">
                                        <input matInput type="text" placeholder="Enter Duties" formControlName="duties">
                                    </mat-form-field>
                                </div>

                                <div class="inputParent">
                                    <label class="inputLabel" [ngClass]="checkDisableClass(cardFormGroup)">
                                        <mat-checkbox formControlName="stillWork" color="primary"
                                            (change)="isStillWorkForCard(cardFormGroup)">
                                        </mat-checkbox>
                                        I currently work here
                                    </label>
                                </div>
                            </div>
                            <div fxLayout="row" fxLayoutAlign="end">
                                <ng-container *ngIf="!cardFormGroup.disabled">
                                    <button mat-flat-button color="primary" [disabled]="cardFormGroup.invalid"
                                        class="mr-1" (click)="saveEditWorkDetail(cardFormGroup,$index);">Save</button>
                                    <button mat-flat-button color="warn"
                                        (click)="cardFormGroup.disable();">Cancel</button>
                                </ng-container>

                                <ng-container *ngIf="cardFormGroup.disabled">
                                    <button mat-flat-button color="primary" class="mr-1"
                                        (click)="cardFormGroup.enable();">Edit</button>
                                    <button mat-flat-button color="warn" (click)="deleteWorkDetails($index)">
                                        Delete
                                    </button>
                                </ng-container>
                            </div>
                        </ng-container>
                    </fieldset>
                </div>
            </ng-container>
        </form>
    </div>

    <fieldset *ngIf="addAnother || workDetailsList.length==0" fxLayoutGap="12px" class="mb-2"
        [ngClass]="!isLgScreen? 'text-center':'text-left'" class="fieldsetArray">
        <legend *ngIf="workDetailsList.length==0">Work Experience / Internships <span *ngIf="isLgScreen"
                style="color:red;font-size: 14px;">(if
                available)</span></legend>
        <span *ngIf="!isLgScreen && workDetailsList.length==0">
            <p style="margin-top: 0;color: red;">(if available)</p>
        </span>
        <form [formGroup]="addWorkDetialsForm" (submit)="addWorkDetails(validationDirective);"
            #validationDirective="ngForm">
            <div fxLayoutGap="10px" style="margin-top: 8px;" [ngClass]="isLgScreen? 'ml-2':''">

                <div class="inputParent">
                    <mat-label class="inputLabel">Job title<span class="red">*</span></mat-label>
                    <mat-form-field appearance="outline" class="input">
                        <input type="text" matInput formControlName="jobTitle" placeholder="Job title">
                        <mat-error *ngIf="addWorkDetialsForm.get('jobTitle').hasError('required')">
                            Job title is <strong>required</strong>
                        </mat-error>
                    </mat-form-field>
                </div>

                <div class="inputParent">
                    <mat-label class="inputLabel">Name of organisation<span class="red">*</span></mat-label>
                    <mat-form-field appearance="outline" class="input">
                        <input type="text" matInput formControlName="organisationName"
                            placeholder="Name of organisation">
                        <mat-error *ngIf="addWorkDetialsForm.get('organisationName').hasError('required')">
                            Name is <strong>required</strong>
                        </mat-error>
                    </mat-form-field>
                </div>

                <div class="inputParent">
                    <mat-label class="inputLabel">Address of organisation<span class="red">*</span></mat-label>
                    <mat-form-field appearance="outline" class="input">
                        <input type="text" matInput formControlName="organisationAddress"
                            placeholder="Address of organisation">
                        <mat-error *ngIf="addWorkDetialsForm.get('organisationAddress').hasError('required')">
                            Address is <strong>required</strong>
                        </mat-error>
                    </mat-form-field>
                </div>

                <div class="inputParent ngx-mat-section">
                    <mat-label class="inputLabel">Phone Number<span class="red">*</span></mat-label>
                    <mat-form-field appearance="outline" class="input">
                        <ngx-mat-intl-tel-input [preferredCountries]="['in']" name="companyNumber"
                            formControlName="companyNumber"></ngx-mat-intl-tel-input>
                    </mat-form-field>
                </div>

                <div class="inputParent">
                    <mat-label class="inputLabel">From date<span class="red">*</span></mat-label>
                    <mat-form-field appearance="outline" class="input">
                        <input matInput [matDatepicker]="fromDateInput" formControlName="fromDate"
                            placeholder="From date" [max]="today">
                        <mat-datepicker-toggle matSuffix [for]="fromDateInput"></mat-datepicker-toggle>
                        <mat-datepicker #fromDateInput disabled="false"></mat-datepicker>
                        <mat-error *ngIf="addWorkDetialsForm.get('fromDate').hasError('required')">
                            From date is <strong>required</strong>
                        </mat-error>
                    </mat-form-field>
                </div>

                <div class="inputParent" *ngIf="!stillWork.value">
                    <mat-label class="inputLabel">To date<span class="red">*</span></mat-label>
                    <mat-form-field appearance="outline" class="input">
                        <input matInput placeholder="To date" [matDatepicker]="toDateInput" formControlName="toDate"
                            [min]="fromDate.value" [min]="addWorkDetialsForm.get('fromDate').value" [max]="today">
                        <mat-datepicker-toggle matSuffix [for]="toDateInput"></mat-datepicker-toggle>
                        <mat-datepicker #toDateInput disabled="false"></mat-datepicker>
                        <mat-error *ngIf="addWorkDetialsForm.get('toDate').hasError('required')">
                            To date is <strong>required</strong>
                        </mat-error>
                        <mat-error *ngIf="!toDate?.hasError('matDatepickerMax') && toDate?.touched">
                            From date must be earlier than to date
                        </mat-error>
                    </mat-form-field>
                </div>

                <div class="inputParent">
                    <mat-label class="inputLabel">Duties</mat-label>
                    <mat-form-field appearance="outline" class="input">
                        <input matInput type="text" placeholder="Enter Duties" formControlName="duties">
                    </mat-form-field>
                </div>

                <div class="inputParent">
                    <label class="inputLabel">
                        <mat-checkbox formControlName="stillWork" color="primary"
                            (change)="isStillwork()"></mat-checkbox>
                        I currently work here
                    </label>
                </div>
            </div>
            <div fxLayout="row" fxLayoutAlign="end">
                <button mat-flat-button color="primary" type="submit" class="mr-1"
                    [disabled]="addWorkDetialsForm.invalid">Add</button>
                <button type="button" mat-flat-button color="warn" (click)="cancelAddWorkDetails()">
                    Cancel
                </button>
            </div>
        </form>
    </fieldset>

    <div class="addMoreBtnSection" *ngIf="!isLgScreen" (click)="showAddForm()">
        <mat-icon class="icon-display">add_circle_outline</mat-icon>
        <h4>Add More</h4>
    </div>

    <div class="mb-1 mt-1" *ngIf="!isLgScreen">
        <button mat-raised-button color="primary" class="saveNextBtn" (click)="saveAndNext()" type="button">Save &
            Next</button>
    </div>
</div>